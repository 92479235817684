export const formatPhone = (value: string) => {
  const cleanedValue = value.replace(/\D/g, '');
  const match = cleanedValue.match(/^(\d{2})(\d{5})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return value;
};

export const formatCPF = (value: string) => {
  const cleanedCPF = value.replace(/\D/g, '');
  const formattedCPF = cleanedCPF.replace(
    /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
    '$1.$2.$3-$4'
  );
  return formattedCPF;
};

export const formatCNPJ = (value: string) => {
  const cleanedCNPJ = value.replace(/\D/g, '');
  const formattedCNPJ = cleanedCNPJ.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
    '$1.$2.$3/$4-$5'
  );
  return formattedCNPJ;
};

export const formatCEP = (value: string) => {
  const cleanedCEP = value.replace(/\D/g, '');
  const formattedCEP = cleanedCEP.replace(/^(\d{5})(\d{3})$/, '$1-$2');
  return formattedCEP;
};

export const formatCurrency = (value: number): string => {
  const formattedValue = value.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
  return formattedValue;
};

export const convertHourToMinutes = (time: string) => {
  const [hours, minutes] = time.split(':').map(Number);
  const totalMinutes = hours * 60 + minutes;
  return totalMinutes;
};

export const formatTime = (value: string) => {
  let formattedValue = value.replace(/[^0-9]/g, '');
  if (formattedValue.length >= 3) {
    if (formattedValue.length === 4) {
      formattedValue =
        formattedValue.slice(0, 2) + ':' + formattedValue.slice(2);
    }
  }
  return formattedValue;
};

export const formatDate = (date?: string) => {
  if (!date) {
    return;
  }

  const parts = date.split('-');
  const formattedDate = `${parts[2]}/${parts[1]}/${parts[0]}`;

  return formattedDate;
};

export const formatDDMMYYYYToDate = (value: string) => {
  const parts = value.split('/');
  const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
  return formattedDate;
};

export const convertMinutesToHHMM = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(remainingMinutes).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;
};

export const formatDateTime = (dateTimeString: string) => {
  if (!dateTimeString) {
    return dateTimeString;
  }

  const [datePart, timePart] = dateTimeString.split(' ');

  const [year, month, day] = datePart.split('-');
  const [hours, minutes] = timePart.split(':');

  return `${day}/${month}/${year} ${hours}:${minutes}`;
};

export const formatHHMMToMinutesInLiteral = (time: string) => {
  if (!time) return '';

  const [hours, minutes] = time.split(':');
  const totalMinutes = parseInt(hours) * 60 + parseInt(minutes);

  if (totalMinutes >= 60) {
    const hourUnit = totalMinutes >= 120 ? 'horas' : 'hora';
    const minuteUnit = totalMinutes % 60 === 0 ? '' : 'minutos';
    const hourValue = Math.floor(totalMinutes / 60);
    const minuteValue = totalMinutes % 60;

    if (minuteValue === 0) {
      return `${hourValue} ${hourUnit}`;
    } else {
      return `${hourValue} ${hourUnit} e ${minuteValue} ${minuteUnit}`;
    }
  }

  if (!totalMinutes) return '';

  return `${totalMinutes} ${totalMinutes > 1 ? 'minutos' : 'minuto'}`;
};

export const selecDate = (datetime: string) => {
  if (!datetime) return;

  const [datePart] = datetime.split(' ');

  return datePart;
};

export const selectTime = (datetime: string) => {
  if (!datetime) return;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, timePart] = datetime.split(' ');

  return timePart;
};
