import { BrowserRouter } from 'react-router-dom';
import { HttpInterceptor } from './clients/http.client';

import { AppReouters } from './routes/Routes';
import { AppThemeProvider, DrawerProvider } from './contexts';
import { ApplicationProvider } from './contexts/application';
import { SnackbarProvider } from './contexts/alert-snackbar/AlertSnackbarProvider';
export default function App() {
  return (
    <ApplicationProvider>
      <AppThemeProvider>
        <SnackbarProvider>
          <HttpInterceptor>
            <BrowserRouter>
              <DrawerProvider>
                <AppReouters />
              </DrawerProvider>
            </BrowserRouter>
          </HttpInterceptor>
        </SnackbarProvider>
      </AppThemeProvider>
    </ApplicationProvider>
  );
}
