import { Box, Button, Link, Typography } from '@mui/material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { LayoutBaseAutenticacaoPagina } from '../../../../layouts';
import { InputEmail } from '../../components/InputEmail';
import { IPasswordReset } from '../../protocols/password-reset.protocol';
import { useSignInPageContext } from '../../SignInContext';
import { InfraExceptionError } from '../../../../helpers/exceptions';
import { useSnackbar } from '../../../../hooks/useSnackbar';
import { useEffect } from 'react';
import { useAnalytics } from '../../../../hooks/useAnalytics';
import { PageNameAnalytics } from '../../../../helpers/constants';

export type PasswordReset = {
  email: string;
};
const passwordRestValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email('O e-mail está inválido')
    .required('O e-mail é obrigatório'),
});

export const PasswordResetPage = () => {
  const { passwordReset } = useSignInPageContext();
  const navigate = useNavigate();
  const { openSnackbar } = useSnackbar();
  const { logPageView } = useAnalytics();

  useEffect(() => {
    logPageView(PageNameAnalytics.pagePasswordReset);
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<PasswordReset>({
    resolver: yupResolver(passwordRestValidationSchema),
  });

  const handlePasswordResetSuccess = (data: IPasswordReset.Output) => {
    if (data.status) {
      reset({ email: '' });
      openSnackbar(
        'Foi encaminhado um link no email para redefinição de uma nova senha',
        'info'
      );
    } else {
      openSnackbar(
        'Ocorreu um erro inesperado, por favor, tente novamente mais tarde.',
        'error'
      );
    }
  };

  const handlePasswordResetError = (error: any) => {
    if (error instanceof InfraExceptionError) {
      openSnackbar(error.message, 'error');
    } else {
      openSnackbar(
        'Ocorreu um erro inesperado, por favor, tente novamente.',
        'error'
      );
    }
  };

  const handleResetPassword: SubmitHandler<PasswordReset> = async (data) => {
    const { email } = data;
    passwordReset
      .exec({ email })
      .then(handlePasswordResetSuccess)
      .catch(handlePasswordResetError);
  };

  return (
    <>
      <LayoutBaseAutenticacaoPagina title="Redefiniação de senha">
        <Box>
          <Typography textAlign={'center'}>
            Insira seu endereço de e-mail que você usou para se registrar. Vamos
            enviar um e-mail um link para você redefinir sua senha
          </Typography>
        </Box>
        <Box display={'flex'} flexDirection={'column'}>
          <InputEmail
            register={register('email')}
            errorMessage={errors.email?.message}
          />
        </Box>
        <Box textAlign={'center'}>
          <Button
            variant={'contained'}
            onClick={handleSubmit(handleResetPassword)}
          >
            Enviar
          </Button>
        </Box>
        <Box textAlign={'center'}>
          <Typography>
            Já tem uma conta?{' '}
            <Link onClick={() => navigate(-1)}>Faça login</Link>.
          </Typography>
        </Box>
      </LayoutBaseAutenticacaoPagina>
    </>
  );
};
