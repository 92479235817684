import { Box, List } from '@mui/material';
import ServiceCompanyForm from './components/service-company-form/ServiceCompanyForm';
import {
  formatCurrency,
  formatHHMMToMinutesInLiteral,
} from '../../helpers/data-formatting';
import { RightSideModal } from '../right-side-modal/RightSideModal';
import { AddServiceButton } from './components/add-service-button/AddServiceButton';
import { ServiceListItem } from './components/service-list-item/ServiceListItem';
import CenteredCircularProgress from '../centered-circular-progress/CenteredCircularProgress';
import { useServiceCompany } from './hooks';

// Refatorar esse componente e forná-lo genérico pois está duplicado em 2 lugares diferentes.
export default function ServiceCompany() {
  const {
    loading,
    services,
    openModal,
    modalTitle,
    serviceSelected,
    handleAddButton,
    handleCloseServiceCompanyFormModal,
    handleListItemClick,
    saveOrUpdate,
    remove,
    removeFile,
  } = useServiceCompany();

  return (
    <>
      <CenteredCircularProgress show={loading} />

      <RightSideModal
        title={modalTitle}
        open={openModal}
        handleClose={handleCloseServiceCompanyFormModal}
      >
        <ServiceCompanyForm
          formData={serviceSelected}
          handleSubmitButton={saveOrUpdate}
          handleRemoveButton={remove}
          handleDeleteAvatar={(url) => {
            if (!serviceSelected) {
              removeFile(url);
            }
          }}
        />
      </RightSideModal>

      <Box display={'flex'} flexDirection={'column'} gap={2}>
        <AddServiceButton handleClick={handleAddButton} />
        <Box
          sx={{
            width: '100%',
            bgcolor: 'background.paper',
          }}
        >
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {services.map((service, index) => (
              <ServiceListItem
                key={index}
                name={service.name}
                price={formatCurrency(service.price)}
                durationTime={formatHHMMToMinutesInLiteral(
                  service.durationTime
                )}
                onClick={() => handleListItemClick(service)}
              />
            ))}
          </List>
        </Box>
      </Box>
    </>
  );
}
