import { Step, StepLabel, Stepper, Typography } from '@mui/material';
import { StepItemContent } from './components/StepItemContent';
import { GeneralStep } from './components/GeneralStep';
import { LocationStep } from './components/LocationStep';
import { ServiceStep } from './components/ServiceStep';
import { TimeStep } from './components/TimeStep';
import { ProfessionalStep } from './components/ProfessionalStep';
import { RegisterCompanyLayout } from './layout/RegisterCompanyLayout';
import { useRegisterCompanyPage, useStepper } from './hooks';
import CenteredCircularProgress from '../../components/centered-circular-progress/CenteredCircularProgress';
import { useEffect } from 'react';

export function RegisterCompanyPage() {
  const { loading, setRegistrationCompleted, validatePendingRegistration } =
    useRegisterCompanyPage();

  const { activeStep, steps, handleBack, handleNext } = useStepper({
    handleFinishStep: setRegistrationCompleted,
  });

  useEffect(() => {
    validatePendingRegistration();
  }, []);

  return (
    <>
      <CenteredCircularProgress show={loading} />

      {!loading && (
        <RegisterCompanyLayout title="Complete o Cadastro da Sua Empresa">
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={step.label}>
                <StepLabel
                  optional={
                    !step.valid ? (
                      <Typography variant="caption">Pendente</Typography>
                    ) : null
                  }
                >
                  {step.label}
                </StepLabel>
                <StepItemContent description="">
                  <>
                    {step.label === 'Geral' && (
                      <GeneralStep
                        currentIndex={index}
                        stepsLength={steps.length}
                        handleNext={handleNext}
                        handleBack={handleBack}
                      />
                    )}

                    {step.label === 'Localização' && (
                      <LocationStep
                        currentIndex={index}
                        stepsLength={steps.length}
                        handleNext={handleNext}
                        handleBack={handleBack}
                      />
                    )}

                    {step.label === 'Serviços' && (
                      <ServiceStep
                        currentIndex={index}
                        stepsLength={steps.length}
                        handleNext={handleNext}
                        handleBack={handleBack}
                      />
                    )}

                    {step.label === 'Horários' && (
                      <TimeStep
                        currentIndex={index}
                        stepsLength={steps.length}
                        handleNext={handleNext}
                        handleBack={handleBack}
                      />
                    )}

                    {step.label === 'Funcionários' && (
                      <ProfessionalStep
                        currentIndex={index}
                        stepsLength={steps.length}
                        handleNext={handleNext}
                        handleBack={handleBack}
                      />
                    )}
                  </>
                </StepItemContent>
              </Step>
            ))}
          </Stepper>
        </RegisterCompanyLayout>
      )}
    </>
  );
}
