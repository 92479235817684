import { api } from '../clients/http.client';

type Weekday = {
  start: string;
  end: string;
  closed: boolean;
  unavailableHours: {
    start: string;
    end: string;
    description: string;
  }[];
};

export type Company = {
  id: string;
  cpf: string;
  cnpj: string;
  phone: string;
  fantasyName: string;
  description: string;
  location: {
    city: string;
    neighborhood: string;
    number: string;
    state: string;
    zipCode: string;
    street: string;
  };
  openingHours: {
    monday: Weekday;
    tuesday: Weekday;
    wednesday: Weekday;
    thursday: Weekday;
    friday: Weekday;
    saturday: Weekday;
    sunday: Weekday;
  };
  blockedDates: string[];
  unavailabilityIntervals: {
    date: string;
    intervals: { start: string; end: string }[];
  }[];
  registrationCompleted: boolean;
  bannerEmailURL: string;
  landingPageCoverURL: string;
  noticeTimeBeforeAppointmentInHours: string;
  segment: string;
};

type UpdateCompanyRequest = {
  id: any;
  name: string;
  note: string;
  phoneNumber: string;
  cpf: string;
  cnpj: string;
  bannerEmailURL: string;
  landingPageCoverURL: string;
  noticeTimeBeforeAppointmentInHours: string;
  segment: string;
};

type UpdateLocationRequest = {
  id: any;
  zipCode: string;
  state: string;
  city: string;
  neighborhood: string;
  number: string;
  street: string;
};

type UpdateRegistrationCompletedRequest = {
  companyId: string;
  registerCompleted: boolean;
};

const handleError = () => {
  throw new Error();
};

export const companyService = {
  async findCompanyById(companyId: any): Promise<Company> {
    const { requestSuccess, data } = await api<Company>({
      url: `api/companies/${companyId}`,
      method: 'GET',
    });

    if (!requestSuccess) {
      handleError();
    }

    return data;
  },

  async updateCompany(request: UpdateCompanyRequest) {
    const { requestSuccess } = await api({
      url: `api/companies/${request.id}`,
      method: 'PUT',
      data: {
        name: request.name,
        note: request.note,
        phoneNumber: request.phoneNumber,
        cpf: request.cpf,
        cnpj: request.cnpj,
        bannerEmailURL: request.bannerEmailURL,
        landingPageCoverURL: request.landingPageCoverURL,
        noticeTimeBeforeAppointmentInHours:
          request.noticeTimeBeforeAppointmentInHours,
        segment: request.segment,
      },
    });

    if (!requestSuccess) {
      handleError();
    }
  },

  async updateLocation(request: UpdateLocationRequest) {
    const { requestSuccess } = await api({
      url: `api/companies/${request.id}/locations`,
      method: 'PATCH',
      data: {
        zipCode: request.zipCode,
        state: request.state,
        city: request.city,
        neighborhood: request.neighborhood,
        number: request.number,
        street: request.street,
      },
    });

    if (!requestSuccess) {
      handleError();
    }
  },

  async updateRegistrationCompleted(
    request: UpdateRegistrationCompletedRequest
  ) {
    const { companyId, registerCompleted } = request;

    const { requestSuccess } = await api({
      url: `api/companies/${companyId}/registration-complete`,
      method: 'PATCH',
      data: {
        registerCompleted,
      },
    });

    if (!requestSuccess) {
      handleError();
    }
  },

  async updateImages(request: {
    companyId: any;
    bannerEmailURL: string;
    landingPageCoverURL: string;
  }) {
    const { companyId, bannerEmailURL, landingPageCoverURL } = request;
    const company = await this.findCompanyById(request.companyId);

    await this.updateCompany({
      id: companyId,
      name: company.fantasyName,
      note: company.description,
      phoneNumber: company.phone,
      cpf: company.cpf,
      cnpj: company.cnpj,
      segment: company.segment,
      noticeTimeBeforeAppointmentInHours:
        company.noticeTimeBeforeAppointmentInHours,
      bannerEmailURL,
      landingPageCoverURL,
    });
  },
};
