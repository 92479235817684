import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import { Box } from '@mui/system';
import { ReactNode } from 'react';

export default function FilterWithAccordion(props: { children: ReactNode }) {
  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box display={'flex'} flexDirection={'row'} gap={2}>
            <SearchIcon fontSize="small" />
            <Typography>Filtrar</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>{props.children}</AccordionDetails>
      </Accordion>
    </>
  );
}
