import { RightSideModal } from '../../../right-side-modal/RightSideModal';
import ProfessionalCompanyForm, {
  FormProfessional,
  Service,
} from './professional-company-form/ProfessionalCompanyForm';

interface ProfessionalCompanyFormModalProps {
  open: boolean;
  title: string;
  services: Service[];
  formData?: FormProfessional;
  handleClose: () => void;
  handleSubmitForm: (data: FormProfessional) => void;
  handleDeleteProfessional: () => void;
}

export function ProfessionalCompanyFormModal(
  props: ProfessionalCompanyFormModalProps
) {
  return (
    <>
      <RightSideModal
        open={props.open}
        title={props.title}
        handleClose={props.handleClose}
      >
        <ProfessionalCompanyForm
          formData={props.formData}
          services={props.services}
          handleSubmit={props.handleSubmitForm}
          handleDelete={props.handleDeleteProfessional}
        />
      </RightSideModal>
    </>
  );
}
