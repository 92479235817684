import CenteredCircularProgress from '../centered-circular-progress/CenteredCircularProgress';
import GeneralCompanyForm from './general-company-form/GeneralCompanyForm';
import { useGeneralCompany } from './hooks';

export function GeneralCompany() {
  const { loading, formData, update } = useGeneralCompany();

  return (
    <>
      <CenteredCircularProgress show={loading} />

      {formData && (
        <GeneralCompanyForm handleSubmitButton={update} formData={formData} />
      )}
    </>
  );
}
