import { codeErrors } from './code-erros';

const firebaseAuthenticationErrors: any = {
  'network-request-failed': codeErrors.NETWORK_REQUEST_FAILED,
  'auth/internal-error': codeErrors.AUTH_INTERNAL_ERROR,
  'auth/user-not-found': codeErrors.AUTH_USER_NOT_FOUND,
  'auth/email-already-in-use': codeErrors.AUTH_EMAIL_ALREADY_IN_USE,
  'auth/wrong-password': codeErrors.AUTH_WRONG_PASSWORD,
};

export const getMessageErrorFirebase = (code: string) => {
  const errorMessage: string = firebaseAuthenticationErrors[code];
  return errorMessage || code;
};
