import { useEffect, useState } from 'react';
import { FormProfessional } from '../components/professional-company-form-modal/professional-company-form/ProfessionalCompanyForm';
import { UserContext } from '../../../contexts/UserContext';
import {
  convertHourToMinutes,
  convertMinutesToHHMM,
} from '../../../helpers/data-formatting';
import { useSnackbar } from '../../../hooks/useSnackbar';
import alertMessage from '../../../helpers/alert-message';
import { Professional } from '../ProfessionalCompany';
import {
  CreateProfessionalRequest,
  ProfessionalAlreadyExists,
  ProfessionalNotFound,
  Service,
  UpdateProfessionalRequest,
  professionalService,
  serviceService,
} from '../../../services';
import { useFileStorage } from '../../../hooks/useFileStorage';

export function useProfessionalCompany() {
  const [professionals, setProfessionals] = useState<Professional[]>([]);
  const companyId = UserContext.companyId();
  const [loading, setLoading] = useState(false);
  const { openSnackbar } = useSnackbar();
  const [services, setServices] = useState<Array<Service>>([]);
  const { removeFile } = useFileStorage();
  const [professionalSelected, setProfessionalSelected] = useState<
    { id: any } & FormProfessional
  >();

  const findAllServices = async () => {
    try {
      setLoading(true);
      const servicesResult = await serviceService.findAll(companyId);
      setServices(servicesResult);
    } catch (err) {
      openSnackbar(alertMessage.unknownErrorMessage, 'error');
    } finally {
      setLoading(false);
    }
  };

  const findProfessionals = async () => {
    try {
      setLoading(true);
      const professionalsResult = await professionalService.findAll(companyId);
      setProfessionals(
        professionalsResult.map((item) => ({
          id: item.id,
          name: item.name,
          email: item.email,
          phone: item.phoneNumber,
          serviceIntervalInMinutes: convertHourToMinutes(item.serviceInterval),
          servicesIDs: item.services.map((item) => item.id),
          avatarURL: item.avatar,
        }))
      );
    } catch (err) {
      openSnackbar(alertMessage.unknownErrorMessage, 'error');
    } finally {
      setLoading(false);
    }
  };

  const updateServicesFromProfessional = async (
    professionalId: any,
    services: string[]
  ) => {
    try {
      setLoading(true);
      await professionalService.updateServicesFromProfessional({
        companyID: companyId,
        professionalID: professionalId,
        servicesIDs: services,
      });
      findProfessionals();
    } catch (err) {
      openSnackbar(alertMessage.unknownErrorMessage, 'error');
    } finally {
      setLoading(false);
    }
  };

  const createProfessional = async (formData: FormProfessional) => {
    try {
      setLoading(true);
      const createProfessionalProps: CreateProfessionalRequest = {
        companyID: companyId,
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        avatar: formData.avatarURL,
      };

      if (formData.serviceIntervalInMinutes !== undefined) {
        createProfessionalProps.serviceInterval = convertMinutesToHHMM(
          formData.serviceIntervalInMinutes
        );
      }

      const newProfessional = await professionalService.create(
        createProfessionalProps
      );

      await updateServicesFromProfessional(
        newProfessional.id,
        formData.servicesIDs
      );

      findProfessionals();
    } catch (err) {
      openSnackbar(
        err instanceof ProfessionalAlreadyExists
          ? alertMessage.createdProfessionalDuplicateEmailMessage
          : alertMessage.unknownErrorMessage,
        'error'
      );
    } finally {
      setLoading(false);
    }
  };

  const updateProfessional = async (id: any, data: FormProfessional) => {
    try {
      setLoading(true);
      const updateProfessionalProps: UpdateProfessionalRequest = {
        id,
        name: data.name,
        email: data.email,
        phone: data.phone,
        avatar: data.avatarURL,
      };
      if (data.serviceIntervalInMinutes !== undefined) {
        updateProfessionalProps.serviceInterval = convertMinutesToHHMM(
          data.serviceIntervalInMinutes
        );
      }

      await professionalService.update(updateProfessionalProps);

      await professionalService.updateServicesFromProfessional({
        companyID: companyId,
        professionalID: id,
        servicesIDs: data.servicesIDs,
      });

      if (!data.avatarURL && professionalSelected?.avatarURL) {
        removeFile(professionalSelected.avatarURL);
      }

      openSnackbar(alertMessage.updatedProfessionalSuccessMessage);
      findProfessionals();
    } catch (err) {
      openSnackbar(
        err instanceof ProfessionalNotFound
          ? alertMessage.updatedProfessionalNotFoundMessage
          : alertMessage.unknownErrorMessage,
        'error'
      );
    } finally {
      setLoading(false);
    }
  };

  const deleteProfessional = async (id: any) => {
    try {
      setLoading(true);

      await professionalService.delete(id);

      if (professionalSelected?.avatarURL) {
        removeFile(professionalSelected.avatarURL);
      }

      setProfessionalSelected(undefined);

      openSnackbar(alertMessage.removedProfessionalNotFoundMessage);

      findProfessionals();
    } catch (err) {
      openSnackbar(
        err instanceof ProfessionalNotFound
          ? alertMessage.updatedProfessionalNotFoundMessage
          : alertMessage.unknownErrorMessage,
        'error'
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    findProfessionals();
    findAllServices();
  }, []);

  return {
    loading,
    professionals,
    services,
    professionalSelected,
    createProfessional,
    updateProfessional,
    deleteProfessional,
    setProfessionalSelected,
  };
}
