import { useState } from 'react';
import { storage } from '../infra/config/firebase';
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from 'firebase/storage';
import { v4 as uuid } from 'uuid';
import { useSnackbar } from './useSnackbar';
import alertMessage from '../helpers/alert-message';
import { appConfig } from '../infra/config';

export const useFileStorage = (
  handleUploadCompleted?: (downloadURL: string) => void
) => {
  const [progress, setProgress] = useState(0);
  const { openSnackbar } = useSnackbar();
  const minSizeInKb = 1024; // 1KB
  const maxSizeInKb = 1 * 1024 * 1024; // 1MB

  const handleUpload = async (file: File) => {
    const storageRef = ref(storage, `${appConfig.filesPath}/${uuid()}`);

    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progressUpdated =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progressUpdated);
      },
      (err) => {
        openSnackbar(alertMessage.unknownErrorMessage, 'error');
        throw err;
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

        if (handleUploadCompleted) handleUploadCompleted(downloadURL);
      }
    );
  };

  const removeFile = async (fileURL: string) => {
    const storageRef = ref(storage, fileURL);

    deleteObject(storageRef).catch((err) => {
      openSnackbar(alertMessage.unknownErrorMessage, 'error');
      throw err;
    });
  };

  const validateImage = (file: File) => {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        const image = new Image();
        if (e.target && typeof e.target.result === 'string') {
          image.src = e.target.result;
        } else {
          openSnackbar(alertMessage.loadImageFail);
          return resolve(false);
        }

        image.onload = () => {
          if (file.size < minSizeInKb || file.size > maxSizeInKb) {
            openSnackbar(
              alertMessage.fileWithUnrequiredDimension('1KB', '1MB')
            );
            return resolve(false);
          }

          resolve(true);
        };
      };

      reader.readAsDataURL(file);
    });
  };

  const convertToSizeLiteral = (value: number): string => {
    if (value < 1024) {
      return `${value}B`;
    } else if (value < 1024 * 1024) {
      return `${Math.round(value / 1024)}KB`;
    } else {
      return `${Math.round(value / (1024 * 1024))}MB`;
    }
  };

  return {
    progress,
    minSizeInKb,
    maxSizeInKb,
    handleUpload,
    removeFile,
    validateImage,
    convertToSizeLiteral,
  };
};
