import { Box, Button, TextField } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useBreakpoint } from '../../../../../../hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import 'dayjs/locale/pt-br';
import { formatTime } from '../../../../../../helpers/data-formatting';
import { useAnalytics } from '../../../../../../hooks/useAnalytics';
import { PageNameAnalytics } from '../../../../../../helpers/constants';

export type FormCloseIntervals = {
  id?: any;
  openTime: string;
  endTime: string;
  reason: string;
};

interface CloseRangesFormProps {
  formData?: FormCloseIntervals;
  isEditing?: boolean;
  disabledSubmitButton?: boolean;
  handleSubmit: (data: FormCloseIntervals) => void;
  handleDelete: () => void;
}

export default function CloseIntervalsForm(props: CloseRangesFormProps) {
  const maxReasonLength = 50;
  const schemaFormDailyOperatingHours = yup.object().shape({
    openDayToggle: yup.string(),
    openTime: yup
      .string()
      .matches(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, 'horário inválido')
      .required('campo obrigatório'),
    endTime: yup
      .string()
      .matches(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, 'horário inválido')
      .required('campo obrigatório'),
    reason: yup
      .string()
      .max(
        maxReasonLength,
        `O campo "motivo do fechamento" não pode exceder ${maxReasonLength} caracteres.`
      ),
  });
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormCloseIntervals>({
    resolver: yupResolver(schemaFormDailyOperatingHours),
  });
  const { desktop } = useBreakpoint();
  const [reason, setReason] = useState('');
  const [isEditingForm, setIsEditingForm] = useState(false);
  const { logPageView } = useAnalytics();

  const remainingCharsForReason = () => {
    if (reason.length <= maxReasonLength) {
      return maxReasonLength - reason.length;
    }
    return 0;
  };

  const handleOpenTimeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const formattedValue = formatTime(value);
    setValue('openTime', formattedValue);
  };

  const handleEndTimeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const formattedValue = formatTime(value);
    setValue('endTime', formattedValue);
  };

  const handleReasonChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setReason(value);
  };

  useEffect(() => {
    logPageView(PageNameAnalytics.pageFormCompanyCloseIntervalConfiguration);
  }, []);

  useEffect(() => {
    if (props.formData && props.isEditing) {
      setValue('openTime', props.formData.openTime);
      setValue('endTime', props.formData.endTime);
      setValue('reason', props.formData.reason);
      setReason(props.formData.reason);
      setIsEditingForm(true);
    } else {
      setIsEditingForm(false);
    }
  }, [props.formData]);

  return (
    <>
      <form
        onSubmit={handleSubmit((data) => {
          props.handleSubmit({
            ...data,
            id: props.formData?.id,
          });
        })}
      >
        <Box display={'flex'} flexDirection={'column'} gap={4} padding={2}>
          <Box display={'flex'} flexDirection={'column'} gap={1}>
            <TextField
              label="Hora Início"
              inputProps={{
                maxLength: 5,
              }}
              error={!!errors.openTime}
              helperText={errors.openTime?.message}
              {...register('openTime')}
              onChange={handleOpenTimeChange}
            />
            <TextField
              label="Hora Fim"
              inputProps={{
                maxLength: 5,
              }}
              error={!!errors.endTime}
              helperText={errors.endTime?.message}
              {...register('endTime')}
              onChange={handleEndTimeChange}
            />
            <Box display={'flex'} flexDirection={'column'} gap={1}>
              <TextField
                label="Motivo"
                multiline
                inputProps={{ maxLength: maxReasonLength }}
                rows={3}
                helperText={`Você ainda pode digitar mais ${remainingCharsForReason()} caracteres`}
                error={!!errors.reason}
                {...register('reason')}
                onChange={handleReasonChange}
              />
            </Box>
          </Box>
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'right'}
            gap={2}
          >
            <Button
              type="submit"
              variant="contained"
              fullWidth={desktop ? false : true}
              size="large"
              disabled={props.disabledSubmitButton}
            >
              {isEditingForm ? 'Atualizar' : 'Salvar'}
            </Button>
            {isEditingForm && (
              <Button
                variant="outlined"
                color="secondary"
                size="large"
                fullWidth={desktop ? false : true}
                onClick={props.handleDelete}
              >
                Excluir
              </Button>
            )}
          </Box>
        </Box>
      </form>
    </>
  );
}
