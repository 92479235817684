import { ReactNode, createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApplicationContext } from '../application';
import { LocalStorage } from '../../helpers/storage';
import { RouteConfig } from '../../infra/config/route';

export const AuthContext = createContext({});

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isUserAuthorized, setIsUserAuthorized] = useState(false);
  const navigate = useNavigate();
  const { getCompanyId, getToken } = useApplicationContext();
  const isAuthorized = () => getToken() !== null && getCompanyId() !== null;

  const signOut = () => {
    LocalStorage.clearAll();
    navigate(RouteConfig.signIn, { replace: true });
  };

  useEffect(() => {
    if (!isAuthorized()) {
      setIsUserAuthorized(false);
      signOut();
      return;
    }

    setIsUserAuthorized(true);
  }, []);

  return <>{isUserAuthorized && children}</>;
};
