import { useEffect, useState } from 'react';
import { useSnackbar } from '../../../hooks/useSnackbar';
import alertMessage from '../../../helpers/alert-message';
import { UserContext } from '../../../contexts/UserContext';
import { FormService } from '../components/service-company-form/ServiceCompanyForm';
import { convertHourToMinutes } from '../../../helpers/data-formatting';
import { serviceService, Service } from '../../../services';
import { useFileStorage } from '../../../hooks/useFileStorage';

export function useServiceCompany() {
  const [services, setServices] = useState<Array<Service>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { openSnackbar } = useSnackbar();
  const companyId = UserContext.companyId();
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [serviceSelected, setServiceSelected] = useState<
    (FormService & { id: any }) | undefined
  >();
  const { removeFile } = useFileStorage();

  const findAll = async () => {
    try {
      setLoading(true);

      const data = await serviceService.findAll(companyId);

      setServices(data);
    } catch (err) {
      openSnackbar(alertMessage.unknownErrorMessage, 'error');
    } finally {
      setLoading(false);
    }
  };

  const remove = async () => {
    try {
      setLoading(true);

      await serviceService.delete(serviceSelected?.id);

      setOpenModal(false);

      const index = services.findIndex(
        (service) => service.id === serviceSelected?.id
      );
      if (index !== -1) {
        services.splice(index, 1);
      }

      if (serviceSelected?.avatarURL) {
        removeFile(serviceSelected.avatarURL);
      }

      setServiceSelected(undefined);

      openSnackbar(alertMessage.removedServiceSuccessMessage);
      findAll();
    } catch (err) {
      openSnackbar(alertMessage.unknownErrorMessage, 'error');
    } finally {
      setLoading(false);
    }
  };

  const saveOrUpdate = async (data: FormService) => {
    try {
      setLoading(true);

      if (serviceSelected) {
        await serviceService.update({
          id: serviceSelected.id,
          name: data.name,
          durationTime: data.durationTimeInMinutes,
          price: parseFloat(data.price),
          avatar: data.avatarURL,
        });

        if (!data.avatarURL && serviceSelected?.avatarURL) {
          removeFile(serviceSelected.avatarURL);
        }

        openSnackbar(alertMessage.updatedServiceSuccessMessage);
        setOpenModal(false);
        findAll();
      } else {
        await serviceService.create({
          companyId,
          name: data.name,
          durationTime: data.durationTimeInMinutes,
          price: parseFloat(data.price),
          avatar: data.avatarURL,
        });

        openSnackbar(alertMessage.createdServiceSuccessMessage);
        setOpenModal(false);
        findAll();
      }
    } catch (err) {
      openSnackbar(alertMessage.unknownErrorMessage, 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleAddButton = () => {
    setServiceSelected(undefined);
    setModalTitle('Adicionar Novo Serviço');
    setOpenModal(true);
  };

  const handleCloseServiceCompanyFormModal = () => {
    setOpenModal(false);
  };

  const handleListItemClick = (item: Service) => {
    setServiceSelected({
      id: item.id,
      name: item.name,
      price: item.price.toString(),
      durationTimeInMinutes: convertHourToMinutes(item.durationTime),
      avatarURL: item.avatar,
    });
    setModalTitle('Atualizar Serviço');
    setOpenModal(true);
  };

  useEffect(() => {
    findAll();
  }, []);

  return {
    loading,
    services,
    openModal,
    modalTitle,
    serviceSelected,
    handleAddButton,
    handleCloseServiceCompanyFormModal,
    handleListItemClick,
    saveOrUpdate,
    remove,
    removeFile,
  };
}
