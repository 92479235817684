import {
  Box,
  Container,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import CenteredCircularProgress from '../../components/centered-circular-progress/CenteredCircularProgress';
import { useSnackbar } from '../../hooks/useSnackbar';
import alertMessage from '../../helpers/alert-message';
import { companyService } from '../../services';
import { UserContext } from '../../contexts/UserContext';
import {
  ContentCopy,
  DesignServices,
  People,
  Schedule,
  Share,
  Today,
} from '@mui/icons-material';
import { appConfig } from '../../infra/config';

export function WelcomePage() {
  const { openSnackbar } = useSnackbar();
  const companyId = UserContext.companyId();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [companyName, setCompanyName] = useState('');

  const loadData = async () => {
    try {
      const company = await companyService.findCompanyById(companyId);
      if (company) {
        setCompanyName(company.fantasyName);
      }
    } catch (err) {
      openSnackbar(alertMessage.unknownErrorMessage, 'error');
    } finally {
      setLoading(false);
    }
  };

  const buildLandingPageURL = () => {
    const landingPageURL = appConfig.landingPageURL;
    const companyKeyParam = appConfig.keyCompanyParamLandingPageURL;

    return `${landingPageURL}/empresa?${companyKeyParam}=${companyId}`;
  };

  const handleShareClick = async () => {
    try {
      const url = buildLandingPageURL();
      await navigator.share({
        title: companyName,
        text: 'Agende Agora e Otimize Seu Tempo!',
        url,
      });
    } catch (err) {
      openSnackbar(alertMessage.shareError, 'warning');
    }
  };

  const handleCopyLink = async () => {
    try {
      const url = buildLandingPageURL();
      await navigator.clipboard.writeText(url);
      openSnackbar(alertMessage.copySuccess);
    } catch (err) {
      openSnackbar(alertMessage.copyLinkError, 'warning');
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <CenteredCircularProgress show={loading} />

      <Container
        style={{
          textAlign: 'center',
          padding: theme.spacing(4),
        }}
      >
        <Typography
          variant="h5"
          style={{
            marginBottom: theme.spacing(2),
          }}
        >
          Bem-vindo à Gestão Excepcional da Empresa {companyName}!
        </Typography>
        <Typography variant="body2">
          Descubra a excelência em gestão eficiente do seu estabelecimento de
          beleza. Estamos dedicados a fornecer ferramentas e recursos para
          otimizar a administração do seu estabelecimento. Destaque para a
          funcionalidade principal:
        </Typography>
        <Typography variant="h6">📅 Agendamentos Simplificados</Typography>
        <Typography variant="body2">
          Oferecemos uma plataforma intuitiva para gerenciar e simplificar o
          processo de agendamentos. Além disso, aproveite outras funcionalidades
          essenciais, incluindo:
        </Typography>
        <ul
          style={{
            textAlign: 'left',
            margin: theme.spacing(2, 0),
          }}
        >
          <li>
            <DesignServices />
            Gerenciamento de serviços de alta qualidade
          </li>
          <li>
            <Schedule /> Configuração flexível de horários para atendimento
          </li>
          <li>
            <People /> Cadastro e gerenciamento de funcionários qualificados
          </li>
          <li>
            <Today />
            Acompanhamento detalhado da lista de agendamentos e seus status
          </li>
        </ul>
        <Typography variant="body2">
          Compartilhe o link da página da sua empresa com seus clientes e receba
          as agendas deles. Quanto mais você compartilha, mais fácil é para seus
          clientes agendarem com você!
        </Typography>

        <Box>
          <IconButton color="primary" onClick={handleShareClick}>
            <Share></Share>
            <Typography variant="caption">Compartilhar</Typography>
          </IconButton>

          <IconButton color="primary" onClick={handleCopyLink}>
            <ContentCopy></ContentCopy>
            <Typography variant="caption">Copiar Link</Typography>
          </IconButton>
        </Box>
        <Typography variant={'caption'}>
          <a href={buildLandingPageURL()} target="_blank" rel="noreferrer">
            {buildLandingPageURL()}
          </a>
        </Typography>
      </Container>
    </>
  );
}
