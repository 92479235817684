import { Button, ButtonBase, List, ListItem, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useBreakpoint } from '../../hooks';
import { ProfessionalCompanyFormModal } from './components/professional-company-form-modal/ProfessionalCompanyFormModal';
import { useProfessionalCompany } from './hooks';
import CenteredCircularProgress from '../centered-circular-progress/CenteredCircularProgress';

export interface Professional {
  id: any;
  name: string;
  phone: string;
  email: string;
  serviceIntervalInMinutes: number;
  servicesIDs: string[];
  avatarURL: string;
}

// Refatorar esse componente pois está sendo utilizado em 2 lugares diferentes.
export default function ProfessionalCompany() {
  const { desktop } = useBreakpoint();
  const [
    openProfessionalCompanyFormModal,
    setOpenProfessionalCompanyFormModal,
  ] = useState(false);
  const [
    titleProfessionalCompanyFormModal,
    setTitleProfessionalCompanyFormModal,
  ] = useState('');

  const {
    loading,
    professionals,
    services,
    professionalSelected,
    createProfessional,
    updateProfessional,
    deleteProfessional,
    setProfessionalSelected,
  } = useProfessionalCompany();

  return (
    <>
      <CenteredCircularProgress show={loading} />

      <ProfessionalCompanyFormModal
        open={openProfessionalCompanyFormModal}
        title={titleProfessionalCompanyFormModal}
        formData={professionalSelected}
        services={services}
        handleClose={() => setOpenProfessionalCompanyFormModal(false)}
        handleSubmitForm={(data) => {
          if (professionalSelected) {
            updateProfessional(professionalSelected.id, data);
          } else {
            createProfessional(data);
          }
          setOpenProfessionalCompanyFormModal(false);
        }}
        handleDeleteProfessional={() => {
          setOpenProfessionalCompanyFormModal(false);
          deleteProfessional(professionalSelected?.id);
        }}
      />

      <Box display={'flex'} flexDirection={'column'} gap={2}>
        <Button
          variant="outlined"
          fullWidth={desktop ? true : false}
          size="large"
          onClick={() => {
            setProfessionalSelected(undefined);
            setOpenProfessionalCompanyFormModal(true);
            setTitleProfessionalCompanyFormModal('Adicionar Novo Funcionário');
          }}
        >
          Adicionar
        </Button>
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
          {professionals.map((professional, index) => (
            <React.Fragment key={index}>
              <ButtonBase style={{ width: '100%' }}>
                <ListItem
                  alignItems="flex-start"
                  sx={{
                    width: '100%',
                    textAlign: 'left',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    },
                  }}
                  onClick={() => {
                    setProfessionalSelected({
                      id: professional.id,
                      name: professional.name,
                      email: professional.email,
                      phone: professional.phone,
                      serviceIntervalInMinutes:
                        professional.serviceIntervalInMinutes,
                      servicesIDs: professional.servicesIDs,
                      avatarURL: professional.avatarURL,
                    });
                    setOpenProfessionalCompanyFormModal(true);
                    setTitleProfessionalCompanyFormModal(
                      'Atualizar Funcionário'
                    );
                  }}
                >
                  <>
                    <Box display={'flex'} flexDirection={'column'}>
                      <Typography color="text.primary">
                        {professional.name}
                      </Typography>
                      <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        gap={1}
                      >
                        <span>email:</span>
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {professional.email}
                        </Typography>
                      </Box>
                      <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        gap={1}
                      >
                        <span>telefone:</span>
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {professional.phone}
                        </Typography>
                      </Box>
                    </Box>
                  </>
                </ListItem>
              </ButtonBase>
            </React.Fragment>
          ))}
        </List>
      </Box>
    </>
  );
}
