import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ChangeEvent, useEffect, useState } from 'react';
import { formatCurrency } from '../../../../helpers/data-formatting';
import { useBreakpoint } from '../../../../hooks';
import { CustomUpload } from '../../../custom-upload/CustomUpload';
import { BoxRowWithButton } from '../../../box-row-with-button/BoxRowWithButton';

export type FormService = {
  name: string;
  durationTimeInMinutes: number;
  price: string;
  avatarURL: string;
};

const schemaForm = yup.object().shape({
  name: yup.string().required('nome é um campo obrigatório'),
  durationTimeInMinutes: yup
    .number()
    .required('tempo gasto é um campo obrigatório')
    .test('maxDuration', 'A duração não pode ultrapassar 24 horas', (value) => {
      if (value && value > 24 * 60) {
        return false;
      }
      return true;
    })
    .transform((val) => (isNaN(val) ? undefined : val)),
  price: yup
    .string()
    .required('preço é um campo obrigatório')
    .matches(/^\d+(\.\d{1,2})?$/, 'preço deve ser um número válido')
    .transform((_value, originalValue) => {
      if (originalValue.trim() === '') {
        return undefined;
      }
      const floatValue = parseFloat(originalValue.replace(',', '.'));
      return isNaN(floatValue) ? undefined : floatValue.toString();
    }),
});

interface ServiceCompanyFormProps {
  formData?: FormService;
  handleSubmitButton: (service: FormService) => void;
  handleRemoveButton: () => void;
  handleDeleteAvatar?: (url: string) => void;
}

export default function ServiceCompanyForm(props: ServiceCompanyFormProps) {
  const {
    formData,
    handleSubmitButton,
    handleRemoveButton,
    handleDeleteAvatar,
  } = props;
  const { desktop } = useBreakpoint();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormService>({
    resolver: yupResolver(schemaForm),
  });

  const [formattedPrice, setFormattedPrice] = useState('');
  const [formattedDurationInHour, setFormattedDurationInHour] = useState('');
  const [avatarURL, setAvatarURL] = useState('');

  const handlePriceChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const valueNumeric = parseFloat(value);

    if (!isNaN(valueNumeric)) {
      const formattedValue = formatCurrency(parseFloat(value));
      setFormattedPrice(formattedValue);
    }
  };

  const handleDurationTimeInMinutesChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    const valueNumeric = parseFloat(value);

    if (!isNaN(valueNumeric)) {
      const hours = Math.floor(valueNumeric / 60);
      const remainingMinutes = valueNumeric % 60;
      const formattedValue = `${hours}h ${remainingMinutes}min`;
      setFormattedDurationInHour(formattedValue);
    }
  };

  useEffect(() => {
    if (formData) {
      setValue('name', formData.name);
      setValue('price', formData.price);
      setValue('durationTimeInMinutes', formData.durationTimeInMinutes);
      setFormattedPrice(formatCurrency(parseFloat(formData.price)));
      setAvatarURL(formData.avatarURL);
    }
  }, []);

  return (
    <>
      <form
        onSubmit={handleSubmit((formData) => {
          formData.avatarURL = avatarURL;
          handleSubmitButton(formData);
        })}
      >
        <Box display={'flex'} flexDirection={'column'} gap={2}>
          <Box display={'flex'} flexDirection={'column'} gap={1}>
            <TextField
              label="Nome"
              variant="outlined"
              fullWidth
              inputProps={{ maxLength: 30 }}
              {...register('name')}
              error={!!errors.name}
              helperText={errors.name?.message}
            />
            <TextField
              label={'Tempo gasto (em minutos)'}
              variant="outlined"
              fullWidth
              type="number"
              {...register('durationTimeInMinutes')}
              error={!!errors.durationTimeInMinutes}
              helperText={
                errors.durationTimeInMinutes?.message ?? formattedDurationInHour
              }
              onChange={handleDurationTimeInMinutesChange}
            />
            <TextField
              label="Preço"
              placeholder="0.00"
              variant="outlined"
              fullWidth
              {...register('price')}
              error={!!errors.price}
              helperText={errors.price?.message ?? formattedPrice}
              onChange={handlePriceChange}
              inputProps={{
                type: 'number',
                min: 0,
                max: 100000000,
                step: 0.01,
              }}
            />
            <Card>
              <CardContent>
                {avatarURL && (
                  <>
                    <Box width={400}>
                      <BoxRowWithButton
                        label="Imagem"
                        showButton={true}
                        onClick={() => {
                          if (handleDeleteAvatar) {
                            handleDeleteAvatar(avatarURL);
                          }

                          setAvatarURL('');
                        }}
                      />
                      <img src={avatarURL} width={400} height={300} />
                    </Box>
                  </>
                )}
                {!avatarURL && (
                  <>
                    <Typography>
                      Inclua uma imagem representativa do serviço, pois ela será
                      destacada na lista de serviços exibida na página inicial
                      (Landing Page) da sua empresa
                    </Typography>
                    <CustomUpload
                      handleUploadComplted={(downloadURL: string) =>
                        setAvatarURL(downloadURL)
                      }
                    />
                  </>
                )}
              </CardContent>
            </Card>
          </Box>

          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'right'}
            gap={2}
          >
            <Button
              type="submit"
              variant="contained"
              fullWidth={desktop ? false : true}
              size="large"
            >
              {formData ? 'Atualizar' : 'Salvar'}
            </Button>
            {formData && (
              <Button
                variant="outlined"
                color="secondary"
                size="large"
                fullWidth={desktop ? false : true}
                onClick={handleRemoveButton}
              >
                Excluir
              </Button>
            )}
          </Box>
        </Box>
      </form>
    </>
  );
}
