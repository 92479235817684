import { api } from '../clients/http.client';

export type Service = {
  id: string;
  name: string;
  price: number;
  durationTime: string;
  avatar: string;
};

type SaveRequest = {
  name: string;
  price: number;
  companyId: any;
  durationTime: number;
  avatar: string;
};

type SaveResponse = {
  id: string;
};

type UpdateRequest = {
  id: any;
  name: string;
  price: number;
  durationTime: number;
  avatar: string;
};

type AddPromotionRequest = {
  serviceId: string;
  companyId: any;
  discountValue: number;
  start: { date: string; time: string };
  end: { date: string; time: string };
};

type DisablePromotionRequest = {
  companyId: any;
  serviceId: any;
};

export const serviceService = {
  async findAll(companyId: any): Promise<Array<Service>> {
    const { data, errorData, requestSuccess } = await api<Array<Service>>({
      url: `api/services?companyId=${companyId}`,
      method: 'GET',
    });

    if (!requestSuccess) {
      throw errorData;
    }

    return data;
  },

  async create(request: SaveRequest) {
    const { requestSuccess, data } = await api<SaveResponse>({
      url: 'api/services',
      method: 'POST',
      data: request,
    });

    if (!requestSuccess) {
      throw new Error();
    }

    return data;
  },

  async update(request: UpdateRequest) {
    const { requestSuccess } = await api({
      url: `api/services/${request.id}`,
      method: 'PUT',
      data: {
        name: request.name,
        price: request.price,
        durationTime: request.durationTime,
        avatar: request.avatar,
      },
    });

    if (!requestSuccess) {
      throw new Error();
    }
  },

  async delete(serviceId: any) {
    const { requestSuccess } = await api({
      url: `api/services/${serviceId}`,
      method: 'DELETE',
      data: {},
    });

    if (!requestSuccess) {
      throw new Error();
    }
  },

  async addPromotion(request: AddPromotionRequest) {
    const { requestSuccess } = await api({
      url: 'api/promotions',
      method: 'PUT',
      data: request,
    });

    if (!requestSuccess) {
      throw new Error();
    }
  },

  async disablePromotion(request: DisablePromotionRequest) {
    const { requestSuccess } = await api({
      url: 'api/promotions/disable',
      method: 'POST',
      data: request,
    });

    if (!requestSuccess) {
      throw new Error();
    }
  },
};
