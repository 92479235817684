import { Box, Button } from '@mui/material';
import { useBreakpoint } from '../../../../hooks';

interface AddButtonProps {
  handleClick: () => void;
}

export function AddServiceButton(props: AddButtonProps) {
  const { handleClick } = props;
  const { desktop } = useBreakpoint();

  return (
    <Box display={'flex'} flexDirection={'row'} justifyContent={'right'}>
      <Button
        variant="outlined"
        fullWidth={desktop ? false : true}
        size="large"
        onClick={handleClick}
      >
        Adicionar
      </Button>
    </Box>
  );
}
