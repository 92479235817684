import { Box, Button, ButtonBase, Typography } from '@mui/material';
import { NonWorkingIntervalsByDateModal } from './non-working-intervals-by-date-modal/NonWorkingIntervalsByDateModal';
import React, { useState } from 'react';
import { useBreakpoint } from '../../../../hooks';
import { formatDate } from '../../../../helpers/format-utils';
import { FormNonWorkingIntervalsByDate } from './non-working-intervals-by-date-modal/non-working-intervals-by-date-form/NonWorkingIntervalsByDateForm';

export type CreateNonWorkingIntervalsByDateForm = {
  date: string;
  startTime: string;
  endTime: string;
};

export type UpdateNonWorkingIntervalsByDateForm = {
  intervalIndex: number;
  date: string;
  startTime: string;
  endTime: string;
};

export type RemoveNonWorkingIntervalsByDateForm = {
  date: string;
  intervalIndex: number;
};

export type NonWorkingIntervalsByDateData = {
  date: string;
  intervals: {
    startTime: string;
    endTime: string;
  }[];
};

interface NonWorkingIntervalsByDateProps {
  data: NonWorkingIntervalsByDateData[];
  disabledSubmitButtonNonWorkingIntervalsByDateForm?: boolean;
  handleCreateNonWorkingIntervalsByDateForm: (
    data: CreateNonWorkingIntervalsByDateForm
  ) => void;
  handleUpdateNonWorkingIntervalsByDateForm: (
    data: UpdateNonWorkingIntervalsByDateForm
  ) => void;
  handleRemoveNonWorkingIntervalsByDateForm: (
    data: RemoveNonWorkingIntervalsByDateForm
  ) => void;
}

export default function NonWorkingIntervalsByDate(
  props: NonWorkingIntervalsByDateProps
) {
  const [
    isEditingNonWorkingIntervalsByDateForm,
    setIsEditingNonWorkingIntervalsByDateForm,
  ] = useState(false);
  const { desktop } = useBreakpoint();
  const [
    openNonWorkingIntervalsByDateFormModal,
    setOpenNonWorkingIntervalsByDateFormModal,
  ] = useState(false);
  const [
    formDataNonWorkingIntervalsByDate,
    setFormDataNonWorkingIntervalsByDate,
  ] = useState<
    ({ intervalIndex: number } & FormNonWorkingIntervalsByDate) | undefined
  >();

  return (
    <>
      <NonWorkingIntervalsByDateModal
        open={openNonWorkingIntervalsByDateFormModal}
        disabledSubmitButton={
          props.disabledSubmitButtonNonWorkingIntervalsByDateForm
        }
        isEditingNonWorkingIntervalsByDateForm={
          isEditingNonWorkingIntervalsByDateForm
        }
        formDataNonWorkingIntervalsByDate={formDataNonWorkingIntervalsByDate}
        handleClose={() => setOpenNonWorkingIntervalsByDateFormModal(false)}
        handleSubmitNonWorkingIntervalsByDateForm={(data) => {
          if (
            isEditingNonWorkingIntervalsByDateForm &&
            formDataNonWorkingIntervalsByDate
          ) {
            props.handleUpdateNonWorkingIntervalsByDateForm({
              intervalIndex: formDataNonWorkingIntervalsByDate.intervalIndex,
              date: data.date,
              startTime: data.openTime,
              endTime: data.endTime,
            });
          } else {
            props.handleCreateNonWorkingIntervalsByDateForm({
              date: data.date,
              startTime: data.openTime,
              endTime: data.endTime,
            });
          }
          setOpenNonWorkingIntervalsByDateFormModal(false);
        }}
        handleRemoveNonWorkingIntervalsByDateForm={() => {
          if (formDataNonWorkingIntervalsByDate) {
            props.handleRemoveNonWorkingIntervalsByDateForm({
              intervalIndex: formDataNonWorkingIntervalsByDate.intervalIndex,
              date: formDataNonWorkingIntervalsByDate.date,
            });
          }
          setOpenNonWorkingIntervalsByDateFormModal(false);
        }}
      />
      <Box display={'flex'} flexDirection={'column'} gap={2}>
        <Typography>Intervalos de Não Atendimento por Data</Typography>
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          {props.data.map((item, index) => (
            <React.Fragment key={index}>
              {item.intervals.length > 0 && (
                <>
                  <Typography variant="caption">
                    {formatDate(item.date)}
                  </Typography>
                  {item.intervals.map((intervalItem, intervalIndex) => (
                    <ButtonBase
                      key={intervalIndex}
                      onClick={() => {
                        setFormDataNonWorkingIntervalsByDate({
                          intervalIndex,
                          date: item.date,
                          openTime: intervalItem.startTime,
                          endTime: intervalItem.endTime,
                        });
                        setIsEditingNonWorkingIntervalsByDateForm(true);
                        setOpenNonWorkingIntervalsByDateFormModal(true);
                      }}
                    >
                      <Box
                        display={'flex'}
                        flexDirection={'row'}
                        width={'100%'}
                        padding={1}
                        sx={{
                          '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.04)',
                          },
                        }}
                      >
                        <Typography variant="body2">
                          {intervalItem.startTime} - {intervalItem.endTime}
                        </Typography>
                      </Box>
                    </ButtonBase>
                  ))}
                </>
              )}
            </React.Fragment>
          ))}
        </Box>
        <Box display={'flex'} flexDirection={'row'}>
          <Button
            variant="outlined"
            color="secondary"
            fullWidth={!desktop}
            onClick={() => {
              setFormDataNonWorkingIntervalsByDate(undefined);
              setIsEditingNonWorkingIntervalsByDateForm(false);
              setOpenNonWorkingIntervalsByDateFormModal(true);
            }}
          >
            Adicionar
          </Button>
        </Box>
      </Box>
    </>
  );
}
