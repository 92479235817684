import { useContext } from 'react';
import SnackbarContext from '../contexts/alert-snackbar/AlertSnackbarContext';

export function useSnackbar() {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error('useSnackbar must be used inside the SnackbarProvider');
  }
  return context;
}
