import { yupResolver } from '@hookform/resolvers/yup';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ChangeEvent, useEffect, useState } from 'react';
import { useBreakpoint } from '../../../../../hooks';
import { formatPhone } from '../../../../../helpers/data-formatting';
import AddEmployeeServicesForm from './add-employee-services-form/AddEmployeeServicesForm';
import { CustomUpload } from '../../../../custom-upload/CustomUpload';

export interface Service {
  id: any;
  name: string;
}
export interface FormProfessional {
  id?: string;
  name: string;
  email: string;
  phone?: string;
  serviceIntervalInMinutes?: number;
  servicesIDs: string[];
  avatarURL: string;
}

interface ProfessionalCompanyFormProps {
  formData?: FormProfessional;
  services: Service[];
  handleSubmit: (data: FormProfessional) => void;
  handleDelete: (id: any) => void;
  handleDeleteAvatar?: (url: string) => void;
}

const formSchema = yup.object().shape({
  name: yup.string().required('nome é um campo obrigatório'),
  email: yup
    .string()
    .required('email é um campo obrigatório')
    .matches(
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      'E-mail inválido. Certifique-se de digitar um e-mail válido.'
    ),
  phone: yup
    .string()
    .transform((val) => (val === '' ? undefined : val))
    .matches(/^\(\d{2}\) \d{5}-\d{4}$/, 'insira um número de telefone válido'),
  serviceIntervalInMinutes: yup
    .number()
    .test(
      'maxInterval',
      'O intervalo não pode ultrapassar 24 horas',
      (value) => {
        if (value && value > 24 * 60) {
          return false;
        }
        return true;
      }
    )
    .transform((val) => (isNaN(val) ? undefined : val)),
});

export default function ProfessionalCompanyForm(
  props: ProfessionalCompanyFormProps
) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormProfessional>({
    resolver: yupResolver(formSchema),
  });
  const { desktop } = useBreakpoint();
  const [formattedIntervalInHour, setFormattedIntervalInHour] = useState('');
  const [servicesSelected, setServicesSelected] = useState<string[]>([]);
  const [avatarURL, setAvatarURL] = useState('');

  const handleChangeIntervalInMinutes = (value: number) => {
    const hours = Math.floor(value / 60);
    const remainingMinutes = value % 60;
    const formattedValue = `${hours}h ${remainingMinutes}min`;
    setFormattedIntervalInHour(formattedValue);
  };

  const handlePhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const formattedValue = formatPhone(value);
    setValue('phone', formattedValue);
  };

  useEffect(() => {
    if (props.formData) {
      setValue('name', props.formData.name);
      setValue('email', props.formData.email);
      setValue(
        'phone',
        props.formData?.phone ? formatPhone(props.formData.phone) : undefined
      );
      setValue(
        'serviceIntervalInMinutes',
        props.formData.serviceIntervalInMinutes
      );
      if (props.formData.serviceIntervalInMinutes !== undefined) {
        handleChangeIntervalInMinutes(props.formData.serviceIntervalInMinutes);
      }
      setAvatarURL(props.formData.avatarURL);
    }
  }, [props.formData]);

  return (
    <>
      <form
        onSubmit={handleSubmit((data) => {
          props.handleSubmit({
            ...data,
            servicesIDs: servicesSelected,
            avatarURL: avatarURL,
          });
        })}
      >
        <Box display={'flex'} flexDirection={'column'} gap={4} padding={2}>
          <Box display={'flex'} flexDirection={'column'} gap={1}>
            <Card>
              <CardContent>
                {avatarURL && (
                  <>
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      justifyContent={'center'}
                      alignItems={'center'}
                    >
                      <Avatar sx={{ width: 80, height: 80 }} src={avatarURL} />
                      <Button
                        onClick={() => {
                          if (props.handleDeleteAvatar) {
                            props.handleDeleteAvatar(avatarURL);
                          }

                          setAvatarURL('');
                        }}
                      >
                        Excluir
                      </Button>
                    </Box>
                  </>
                )}
                {!avatarURL && (
                  <>
                    <Box>
                      <Typography>Inclua uma foto do funcionário</Typography>
                    </Box>
                    <br />
                    <CustomUpload
                      handleUploadComplted={(downloadURL: string) =>
                        setAvatarURL(downloadURL)
                      }
                    />
                  </>
                )}
              </CardContent>
            </Card>

            <TextField
              label="Nome"
              variant="outlined"
              fullWidth
              inputProps={{ maxLength: 30 }}
              {...register('name')}
              error={!!errors.name}
              helperText={errors.name?.message}
            />
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              type="text"
              {...register('email')}
              error={!!errors.email}
              helperText={errors.email?.message}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const value = e.target.value;
                setValue('email', value);
              }}
            />
            <TextField
              label="Telefone"
              variant="outlined"
              fullWidth
              error={errors.phone?.message !== undefined}
              helperText={errors.phone?.message}
              inputProps={{ maxLength: 15 }}
              {...register('phone')}
              onChange={handlePhoneChange}
            />
            <TextField
              label="Tempo de intervalo entre atendimento (em minutos)"
              type="number"
              {...register('serviceIntervalInMinutes')}
              error={!!errors.serviceIntervalInMinutes}
              helperText={
                errors.serviceIntervalInMinutes?.message ??
                formattedIntervalInHour
              }
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value;
                const valueNumeric = parseFloat(value);
                if (!isNaN(valueNumeric)) {
                  handleChangeIntervalInMinutes(valueNumeric);
                }
              }}
            />
            <AddEmployeeServicesForm
              services={props.services}
              selectedServicesIDs={
                props.formData?.servicesIDs || servicesSelected
              }
              onChange={(servicesIDs) => {
                setServicesSelected(servicesIDs);
              }}
            />
          </Box>
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'right'}
            gap={2}
          >
            <Button
              type="submit"
              variant="contained"
              fullWidth={desktop ? false : true}
              size="large"
            >
              {props.formData ? 'Atualizar' : 'Salvar'}
            </Button>
            {props.formData && (
              <Button
                variant="outlined"
                color="secondary"
                size="large"
                fullWidth={desktop ? false : true}
                onClick={props.handleDelete}
              >
                Excluir
              </Button>
            )}
          </Box>
        </Box>
      </form>
    </>
  );
}
