import { Box, Typography } from '@mui/material';

export const Header = () => {
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      gap={3}
    >
      <Typography variant={'h5'}>Acesse sua conta</Typography>
      <Typography>Gerencie sua empresa de forma rápida e fácil</Typography>
    </Box>
  );
};
