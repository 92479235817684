import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Scheduling from '../pages/scheduling/Scheduling';
import { useDrawerContext } from '../contexts';
import { LayoutBaseDePagina } from '../layouts';
import { RouteConfig } from '../infra/config/route';
import { SignInPageProvider } from '../pages/signin/SignInContext';
import { SignInPage } from '../pages/signin/SignInPage';
import { PasswordResetPage } from '../pages/signin/pages/password-reset/PasswordResetPage';
import { SignUpPage } from '../pages/signin/pages/signup/SignUpPage';
import { AuthProvider } from '../contexts/auth';
import { RegisterCompanyPage } from '../pages/register-company-page/RegisterCompanyPage';
import {
  EmployeePage,
  SettingsPage,
  MyCompanyPage,
  PlacePage,
  ServicePage,
  TimePage,
  WelcomePage,
} from '../pages';
import { SideMenu } from '../components/side-menu/SideMenu';

export const RoutePathName = {
  main: 'inicio',
  scheduling: 'agendas',
  schedulingHistory: 'historico',
  myCompany: 'empresa',
  location: 'localizacao',
  services: 'servicos',
  time: 'horario-atendimento',
  employee: 'funcionarios',
  gallery: 'imagens',
};

export function AppReouters() {
  const sideMenuRoutes: {
    icon: string;
    label: string;
    path: string;
    element: JSX.Element;
  }[] = [
    {
      icon: 'home',
      label: 'início',
      path: RoutePathName.main,
      element: (
        <LayoutBaseDePagina titulo="Seja Bem Vindo!">
          <WelcomePage />
        </LayoutBaseDePagina>
      ),
    },
    {
      icon: 'today',
      label: 'Agendamentos',
      path: RoutePathName.scheduling,
      element: (
        <LayoutBaseDePagina
          titulo="Agendamentos"
          description="Explore todos os seus agendamentos em um único lugar! "
        >
          <Scheduling />
        </LayoutBaseDePagina>
      ),
    },
    {
      icon: 'store',
      label: 'Minha Empresa',
      path: RoutePathName.myCompany,
      element: (
        <LayoutBaseDePagina
          titulo="Dados da Empresa"
          description="Construa uma imagem completa da sua empresa!"
        >
          <MyCompanyPage />
        </LayoutBaseDePagina>
      ),
    },
    {
      icon: 'place',
      label: 'Endereço',
      path: RoutePathName.location,
      element: (
        <LayoutBaseDePagina
          titulo="Localização"
          description="Ajuste seu endereço para que os clientes possam encontrá-lo."
        >
          <PlacePage />
        </LayoutBaseDePagina>
      ),
    },
    {
      icon: 'design_services',
      label: 'Serviços',
      path: RoutePathName.services,
      element: (
        <LayoutBaseDePagina
          titulo="Serviços"
          description="Aproveite para incluir todos os serviços oferecidos pela sua empresa. Eles serão exibidos aos clientes durante o agendamento de um atendimento."
        >
          <ServicePage />
        </LayoutBaseDePagina>
      ),
    },
    {
      icon: 'schedule',
      label: 'Horários',
      path: RoutePathName.time,
      element: (
        <LayoutBaseDePagina
          titulo="Horários"
          description="Configure os horários em que os agendamentos serão aceitos."
        >
          <TimePage />
        </LayoutBaseDePagina>
      ),
    },
    {
      icon: 'people_alt',
      label: 'Funcionários',
      path: RoutePathName.employee,
      element: (
        <LayoutBaseDePagina
          titulo="Funcionários"
          description="Gerenciamento de Recursos Humanos."
        >
          <EmployeePage />
        </LayoutBaseDePagina>
      ),
    },
    {
      icon: 'settings',
      label: 'Configurações',
      path: RoutePathName.gallery,
      element: (
        <LayoutBaseDePagina
          titulo="Configurações"
          description="Ajuste as configurações gerais da sua empresa para uma experiência personalizada e adaptada às suas necessidades."
        >
          <SettingsPage />
        </LayoutBaseDePagina>
      ),
    },
  ];

  const { setDrawerOption } = useDrawerContext();

  useEffect(() => {
    setDrawerOption(
      sideMenuRoutes.map((item) => ({
        icon: item.icon,
        label: item.label,
        path: `/${item.path}`,
      }))
    );
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Navigate to={RoutePathName.main} />} />
      <Route element={<SideMenu />}>
        {sideMenuRoutes.map((item, index) => (
          <Route
            key={index}
            path={item.path}
            element={<AuthProvider>{item.element}</AuthProvider>}
          />
        ))}
      </Route>
      <Route
        path={RouteConfig.signIn}
        element={
          <SignInPageProvider>
            <SignInPage />
          </SignInPageProvider>
        }
      />
      <Route
        path={RouteConfig.passwordReset}
        element={
          <SignInPageProvider>
            <PasswordResetPage />
          </SignInPageProvider>
        }
      />
      <Route
        path={RouteConfig.signUp}
        element={
          <SignInPageProvider>
            <SignUpPage />
          </SignInPageProvider>
        }
      />
      <Route
        path={RouteConfig.initialSetup}
        element={
          <AuthProvider>
            <RegisterCompanyPage />
          </AuthProvider>
        }
      />
      <Route path="*" element={<Navigate to={'/'} />} />
    </Routes>
  );
}
