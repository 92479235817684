import { RightSideModal } from '../../../../right-side-modal/RightSideModal';
import NonWorkingDatesForm, {
  FormNonWorkingDates,
} from './non-working-dates-form/NonWorkingDatesForm';

interface NonWorkingDatesFormModalProps {
  open: boolean;
  handleClose: () => void;
  disabledSubmitButton?: boolean;
  handleSubmitNonWorkingDatesForm: (data: FormNonWorkingDates) => void;
}

export function NonWorkingDatesFormModal(props: NonWorkingDatesFormModalProps) {
  return (
    <>
      <RightSideModal
        open={props.open}
        title={'Adicionar Datas de Não Atendimento'}
        handleClose={props.handleClose}
      >
        <NonWorkingDatesForm
          disabledSubmitButton={props.disabledSubmitButton}
          handleSubmit={props.handleSubmitNonWorkingDatesForm}
        />
      </RightSideModal>
    </>
  );
}
