/* eslint-disable no-unused-vars */
export const httpError = {
  CREATED: 201,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  CONFLICT: 409,
  UNPROCESSABLE_ENTITY: 422,
  INTERNAL_SERVER_ERROR: 500,
};

export const messageError = {
  GENERIC_ERROR: 'Ocorreu um erro inesperado, tente novamente.',
};

export const PATH_API = {
  createScheduling: '/scheduling',
  findScheduling: '/scheduling',
  findSchedulingAvailableTime: '/scheduling/available-time',
  findServices: '/services',
  cancelScheduling: (id: string) => `/scheduling/${id}/cancel`,
  findPOrofessionals: '/professionals',
  createRating: '/ratings',
  updateUser: (id: string | number) => `/users/${id}`,
};

export enum SchedulingState {
  CREATED = 'created',
  COMPLETE = 'complete',
  CANCELLED = 'cancelled',
  PENDING = 'pending',
}

export const getScheduleStatusDescription = (value: SchedulingState) => {
  if (value === SchedulingState.CREATED) {
    return 'Novo';
  }

  if (value === SchedulingState.COMPLETE) {
    return 'Concluido';
  }

  if (value === SchedulingState.CANCELLED) {
    return 'Cancelado';
  }

  if (value === SchedulingState.PENDING) {
    return 'Pendente';
  }

  return '';
};

export enum PageNameAnalytics {
  pageSchedule = 'Agendamentos',
  pageSignin = 'Login',
  pageProfile = 'Perfil',
  pagePasswordReset = 'Redefinição de senha',
  pageEditProfile = 'Edditar dados do perfil',
  pageDashboard = 'Resumo',
  pageConfiguration = 'Configuração',
  pageCompanyDataConfiguration = 'Configuração Sua Empresa',
  pageCompanyLocationConfiguration = 'Configuração Localização',
  pageCompanyServicesConfiguration = 'Configuração Serviços',
  pageAddOrUpdateService = 'Formul[ario Serviço',
  pageAddPromotionService = 'Adicionar Promoção',
  pageCompanyOpeningHoursConfiguration = 'Configuração Horário Atendimento',
  pageFormDailyOperatingHours = 'Form Configuração Horário Atendimento',
  pageFormCompanyOpeningHoursConfiguration = 'Formulário Configuração Horário Atendimento',
  pageFormCompanyCloseIntervalConfiguration = 'Formulário Configuração Horário Fechado',
  pageFormCompanyCloseIntervalByDateConfiguration = 'Formulário Configuração Horário Fechado por Data',
  pageFormCompanyCloseByDateConfiguration = 'Formulário Configuração Estabelecimento Fechado por Data',
  pageCompanyProfessionalsConfiguration = 'Configuração Profissionais',
  pageFormProfessionals = 'Formulário Profissionais',
}

export const enum EventAnalytics {
  pageView = 'page_view',
  generalDataCompanyUpdated = 'general_data_company_updated',
  locationCompanyUpdated = 'location_company_updated',
  serviceCreated = 'service_created',
  serviceUpdated = 'service_updated',
  serviceDeleted = 'service_deleted',
  servicePromotionCreated = 'service_promotion_created',
  servicePromotionCanceled = 'service_promotion_canceled',
  dailyOperationHoursUpdated = 'daily_operation_hours_updated',
  closedIntervalDailyOperationHoursCreated = 'closed_interval_daily_operation_hours_created',
  closedIntervalDailyOperationHoursUpdated = 'closed_interval_daily_operation_hours_updated',
  closedIntervalDailyOperationHoursDeleted = 'closed_interval_daily_operation_hours_deleted',
  notWorkingDateCreated = 'not_working_date_created',
  notWorkingDateUpdated = 'not_working_date_updated',
  notWorkingIntervalDateCreated = 'not_working_date_interval_created',
  notWorkingIntervalDateUpdated = 'not_working_date_interval_updated',
  notWorkingIntervalDateDeleted = 'not_working_date_interval_deleted',
  professionalCreated = 'professional_created',
  professionalUpdated = 'professional_updated',
  professionalDeleted = 'professional_deleted',
}
