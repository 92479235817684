import CenteredCircularProgress from '../centered-circular-progress/CenteredCircularProgress';
import LocationCompanyForm from './components/location-company-form/LocationCompanyForm';
import { useLocationCompany } from './hooks';

export function LocationCompany() {
  const { loading, formLocation, update } = useLocationCompany();

  return (
    <>
      <CenteredCircularProgress show={loading} />

      {formLocation && (
        <LocationCompanyForm
          handleSubmitButton={update}
          formData={formLocation}
        />
      )}
    </>
  );
}
