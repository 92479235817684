import {
  Box,
  Button,
  Icon,
  Paper,
  Rating,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import {
  convertValueToCurrency,
  formatDate,
} from '../../../../helpers/format-utils';
import { useEffect, useState } from 'react';
import {
  FormStatus,
  StatusAndSchedulingFormDialog,
} from './status-and-scheduling-form-dialog/StatusAndSchedulingFormDialog';

export type UpdateStatusFormData = {
  status: 'complete' | 'cancelled';
  description: string;
};

type DetailsScheduleProps = {
  scheduling: {
    id: string;
    companyName: string;
    eventTime: string;
    eventDate: string;
    customerName: string;
    serviceName: string;
    serviceAmount: number;
    professionalName: string;
    eventStartTime: string;
    eventEndTime: string;
    ratingStar?: number;
    ratingComment?: string;
    status: 'created' | 'pending' | 'complete' | 'cancelled';
    statusDescription: string;
  };
  statusDisabled?: boolean;
  onSubmitUpdateStatusForm?: (formData: UpdateStatusFormData) => void;
};

enum Status {
  CREATED = 'created',
  PENDING = 'pending',
  COMPLETE = 'complete',
  CANCELLED = 'cancelled',
}

const StatusValues = [
  { id: Status.CREATED, description: 'Solicitado' },
  { id: Status.PENDING, description: 'Pendente' },
  { id: Status.CANCELLED, description: 'Cancelado' },
  { id: Status.COMPLETE, description: 'Concluido' },
];

export const SchedulingDetails = (props: DetailsScheduleProps) => {
  const theme = useTheme();
  const [status, setStatus] = useState(StatusValues);
  const [statusDescription, setStatusDescription] = useState(
    props.scheduling.statusDescription
  );
  const [
    openStatusAndSchedulingFormDialog,
    setOpenStatusAndSchedulingFormDialog,
  ] = useState(false);
  const [showUpdateStatusButton, setShowUpdateStatusButton] = useState(false);

  const handleUpdateButtonClick = () => {
    setOpenStatusAndSchedulingFormDialog(true);
  };

  const handleStatusFormDialogSubmit = (data: FormStatus) => {
    if (
      props.onSubmitUpdateStatusForm &&
      (data.status === Status.CANCELLED || data.status === Status.COMPLETE)
    ) {
      props.onSubmitUpdateStatusForm({
        status: data.status,
        description: data.description,
      });
      setOpenStatusAndSchedulingFormDialog(false);
    }
  };

  const getStatusText = () => {
    const statusValue = StatusValues.find(
      (item) => item.id === props.scheduling.status
    );
    return statusValue?.description ?? '';
  };

  useEffect(() => {
    setStatusDescription(props.scheduling.statusDescription);
  }, [props.scheduling.statusDescription]);

  useEffect(() => {
    if (
      props.scheduling.status === Status.COMPLETE ||
      props.scheduling.status === Status.CANCELLED
    ) {
      setShowUpdateStatusButton(false);
    } else if (
      props.scheduling.status === Status.PENDING ||
      props.scheduling.status === Status.CREATED
    ) {
      const statusValues = status.filter(
        (item) => item.id === Status.COMPLETE || item.id === Status.CANCELLED
      );
      setStatus(statusValues);
      setShowUpdateStatusButton(true);
    }
  }, [props.scheduling.status]);

  return (
    <>
      <StatusAndSchedulingFormDialog
        open={openStatusAndSchedulingFormDialog}
        data={status}
        handleFormSubmit={handleStatusFormDialogSubmit}
        handleClose={() => setOpenStatusAndSchedulingFormDialog(false)}
      />

      <Box display={'flex'} flexDirection={'column'} gap={3}>
        <Box display={'flex'} gap={3} alignItems={'center'}>
          <Box display={'flex'} flexDirection={'column'} gap={1}>
            <Typography variant={'h5'} fontWeight={'bold'}>
              {props.scheduling.customerName}
            </Typography>
            <Box display={'flex'} gap={3}>
              <Box display={'flex'} gap={1}>
                <Icon fontSize={'small'}>access_time</Icon>
                <Typography>
                  {props.scheduling.eventStartTime +
                    ' - ' +
                    props.scheduling.eventEndTime}
                </Typography>
              </Box>
              <Box display={'flex'} gap={1}>
                <Icon fontSize={'small'}>event</Icon>
                <Typography>
                  {formatDate(props.scheduling.eventDate)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box display={'flex'} flexDirection={'column'} gap={2}>
          <Typography>Profissional</Typography>
          <Box
            component={Paper}
            display={'flex'}
            alignItems={'center'}
            padding={theme.spacing(2)}
            gap={2}
          >
            <Typography>{props.scheduling.professionalName}</Typography>
          </Box>
        </Box>

        <Box display={'flex'} flexDirection={'column'} gap={2}>
          <Typography>Serviço</Typography>
          <Box
            component={Paper}
            display={'flex'}
            alignItems={'center'}
            padding={theme.spacing(2)}
            gap={5}
          >
            <Typography>{props.scheduling.serviceName}</Typography>
            <Typography>{props.scheduling.eventTime}</Typography>
            <Typography>
              {convertValueToCurrency(props.scheduling.serviceAmount)}
            </Typography>
          </Box>
        </Box>

        <Box display={'flex'} flexDirection={'column'} gap={2}>
          <Typography>Valor Total</Typography>
          <Box
            component={Paper}
            display={'flex'}
            alignItems={'center'}
            padding={theme.spacing(2)}
            gap={5}
          >
            <Typography>
              {convertValueToCurrency(props.scheduling.serviceAmount)}
            </Typography>
          </Box>
        </Box>

        <Box display={'flex'} flexDirection={'column'} gap={2}>
          <Typography>Status</Typography>
          <Box
            component={Paper}
            display={'flex'}
            alignItems={'center'}
            padding={theme.spacing(2)}
            gap={2}
          >
            <Box display={'flex'} flexDirection={'column'} gap={2}>
              <Typography style={{ marginTop: 16 }}>
                {getStatusText()}
              </Typography>
              <Typography fontSize={14}>{statusDescription}</Typography>
            </Box>
            {showUpdateStatusButton && (
              <Button variant="outlined" onClick={handleUpdateButtonClick}>
                Atualizar
              </Button>
            )}
          </Box>
        </Box>
        {props.scheduling.ratingStar && (
          <Box display={'flex'} flexDirection={'column'} gap={2}>
            <Typography>Avaliação</Typography>
            <Rating
              size={'large'}
              name="simple-controlled"
              value={props.scheduling.ratingStar}
              disabled
            />
            <Box display={'flex'} flexDirection={'column'} gap={3}>
              <Box display={'flex'}>
                <Typography flex={1}>Comentários</Typography>
              </Box>
              <TextField
                disabled
                multiline
                rows={5}
                value={props.scheduling.ratingComment}
              />
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};
