import { TextField } from '@mui/material';
import { UseFormRegisterReturn } from 'react-hook-form';

export const InputEmail: React.FC<{
  register: UseFormRegisterReturn;
  errorMessage: string | undefined;
}> = (params) => {
  const { errorMessage, register } = params;
  return (
    <TextField
      {...register}
      error={errorMessage ? true : false}
      helperText={errorMessage}
      type={'email'}
      label={'Endereço de e-mail'}
    />
  );
};
