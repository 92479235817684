import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, TextField } from '@mui/material';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useBreakpoint } from '../../../../hooks';
import { ChangeEvent, ReactNode, useEffect } from 'react';
import { formatCEP } from '../../../../helpers/data-formatting';
import { useAnalytics } from '../../../../hooks/useAnalytics';
import { PageNameAnalytics } from '../../../../helpers/constants';

export type FormLocation = {
  zipCode: string;
  uf: string;
  city: string;
  street: string;
  neighborhood: string;
  houseNumber: string;
};

interface LocationCompanyFormProps {
  formData?: FormLocation;
  handleSubmitButton?: (data: FormLocation) => void;
  buttonChildren?: ReactNode;
}

const schemaFormLocation = yup.object().shape({
  zipCode: yup
    .string()
    .required('cep é um campo obrigatório')
    .transform((val) => (val === '' ? undefined : val))
    .matches(/^\d{5}-?\d{3}$/, 'insira um CEP válido'),
  uf: yup
    .string()
    .required('estado é um campo obrigatório')
    .transform((val) => (val === '' ? undefined : val)),
  city: yup
    .string()
    .required('cidade é um campo obrigatório')
    .transform((val) => (val === '' ? undefined : val)),
  neighborhood: yup
    .string()
    .required('bairro é um campo obrigatório')
    .transform((val) => (val === '' ? undefined : val)),
  street: yup
    .string()
    .required('rua é um campo obrigatório')
    .transform((val) => (val === '' ? undefined : val)),
  houseNumber: yup
    .string()
    .required('numero é um campo obrigatório')
    .transform((val) => (val === '' ? undefined : val)),
});

export default function LocationCompanyForm(props: LocationCompanyFormProps) {
  const { formData, buttonChildren, handleSubmitButton } = props;
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<FormLocation>({
    resolver: yupResolver(schemaFormLocation),
  });
  const { desktop } = useBreakpoint();
  const { logPageView } = useAnalytics();

  useEffect(() => {
    logPageView(PageNameAnalytics.pageCompanyLocationConfiguration);
    if (props.formData) {
      setValue('zipCode', formatCEP(props.formData.zipCode ?? ''));
      setValue('uf', props.formData.uf);
      setValue('city', props.formData.city);
      setValue('neighborhood', props.formData.neighborhood);
      setValue('houseNumber', props.formData.houseNumber);
      setValue('street', props.formData.street);
    }
  }, []);

  const handleCEPChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const formattedValue = formatCEP(value);
    setValue('zipCode', formattedValue);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit((data) => {
          if (handleSubmitButton) {
            handleSubmitButton(data);
          }
        })}
      >
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          <TextField
            label="CEP"
            variant="outlined"
            fullWidth
            error={errors.zipCode?.message !== undefined}
            helperText={errors.zipCode?.message}
            inputProps={{ maxLength: 8 }}
            {...register('zipCode')}
            onChange={handleCEPChange}
          />
          <TextField
            label="Estado"
            variant="outlined"
            fullWidth
            error={errors.uf?.message !== undefined}
            helperText={errors.uf?.message}
            inputProps={{ maxLength: 30 }}
            {...register('uf')}
          />
          <TextField
            label="Cidade"
            variant="outlined"
            fullWidth
            error={errors.city?.message !== undefined}
            helperText={errors.city?.message}
            inputProps={{ maxLength: 30 }}
            {...register('city')}
          />
          <TextField
            label="Bairro"
            variant="outlined"
            fullWidth
            error={errors.neighborhood?.message !== undefined}
            helperText={errors.neighborhood?.message}
            inputProps={{ maxLength: 30 }}
            {...register('neighborhood')}
          />
          <TextField
            label="Rua"
            variant="outlined"
            fullWidth
            error={errors.street?.message !== undefined}
            helperText={errors.street?.message}
            inputProps={{ maxLength: 30 }}
            {...register('street')}
          />
          <TextField
            label="Número"
            type={'number'}
            variant="outlined"
            fullWidth
            error={errors.houseNumber?.message !== undefined}
            helperText={errors.houseNumber?.message}
            inputProps={{ maxLength: 10 }}
            {...register('houseNumber')}
          />
        </Box>
        {buttonChildren || (
          <Box display={'flex'} flexDirection={'row'} justifyContent={'right'}>
            <Button
              type="submit"
              variant="contained"
              fullWidth={desktop ? false : true}
              size="large"
            >
              {formData ? 'Atualizar' : 'Salvar'}
            </Button>
          </Box>
        )}
      </form>
    </>
  );
}
