import { useEffect, useState } from 'react';
import { useSnackbar } from '../../../hooks/useSnackbar';
import { FormGeneral } from '../general-company-form/GeneralCompanyForm';
import { UserContext } from '../../../contexts/UserContext';
import alertMessage from '../../../helpers/alert-message';
import { companyService } from '../../../services';

interface UseGeneralCompanyResponse {
  loading: boolean;
  formData: FormGeneral | undefined;
  update: (data: FormGeneral) => Promise<{ isError: boolean }>;
}

export function useGeneralCompany(): UseGeneralCompanyResponse {
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<
    | (FormGeneral & { bannerEmailURL: string; landingPageCoverURL: string })
    | undefined
  >(undefined);

  const { openSnackbar } = useSnackbar();
  const companyId = UserContext.companyId();

  const findGeneralData = async () => {
    const company = await companyService.findCompanyById(companyId);
    if (company) {
      setFormData({
        ...company,
        name: company.fantasyName,
        cnpj: company.cnpj ?? '',
        note: company.description ?? '',
        noticeTimeBeforeAppointmentInHours:
          company.noticeTimeBeforeAppointmentInHours,
        segment: company.segment,
      });
    } else {
      setFormData(undefined);
    }
  };

  const update = async (data: FormGeneral) => {
    let isError = false;
    try {
      setLoading(true);

      await companyService.updateCompany({
        id: companyId,
        name: data.name,
        phoneNumber: data.phone,
        cpf: data.cpf ?? '',
        cnpj: data.cnpj ?? '',
        note: data.note,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        bannerEmailURL: formData!.bannerEmailURL,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        landingPageCoverURL: formData!.landingPageCoverURL,
        noticeTimeBeforeAppointmentInHours:
          data.noticeTimeBeforeAppointmentInHours,
        segment: data.segment,
      });

      openSnackbar(alertMessage.updatedCompanyDataSuccessMessage);
    } catch (err) {
      openSnackbar(alertMessage.unknownErrorMessage, 'error');
      isError = true;
    } finally {
      setLoading(false);
    }
    return { isError };
  };

  useEffect(() => {
    findGeneralData();
  }, []);

  return {
    loading,
    formData,
    update,
  };
}
