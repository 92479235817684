import { RightSideModal } from '../../../../right-side-modal/RightSideModal';
import NonWorkingIntervalsByDateForm, {
  FormNonWorkingIntervalsByDate,
} from './non-working-intervals-by-date-form/NonWorkingIntervalsByDateForm';

interface NonWorkingIntervalsByDateModalProps {
  open: boolean;
  isEditingNonWorkingIntervalsByDateForm: boolean;
  formDataNonWorkingIntervalsByDate?: FormNonWorkingIntervalsByDate;
  disabledSubmitButton?: boolean;
  handleClose: () => void;
  handleSubmitNonWorkingIntervalsByDateForm: (
    formData: FormNonWorkingIntervalsByDate
  ) => void;
  handleRemoveNonWorkingIntervalsByDateForm: () => void;
}
export function NonWorkingIntervalsByDateModal(
  props: NonWorkingIntervalsByDateModalProps
) {
  return (
    <>
      <RightSideModal
        open={props.open}
        title={'Configurar Intervalos de Não Atendimento por Data'}
        handleClose={props.handleClose}
      >
        <NonWorkingIntervalsByDateForm
          isEditing={props.isEditingNonWorkingIntervalsByDateForm}
          formData={props.formDataNonWorkingIntervalsByDate}
          disabledSubmitButton={props.disabledSubmitButton}
          handleSubmit={props.handleSubmitNonWorkingIntervalsByDateForm}
          handleRemove={props.handleRemoveNonWorkingIntervalsByDateForm}
        />
      </RightSideModal>
    </>
  );
}
