import { useEffect, useState } from 'react';
import { UserContext } from '../../../contexts/UserContext';
import {
  CreateClosedIntervalsDailyOperatingHours,
  DailyOperatingHoursData,
  DeleteClosedIntervalsDailyOperatingHours,
  UpdateClosedIntervalsDailyOperatingHours,
  UpdateDailyOperatingHours,
  Weekday,
} from '../components/daily-operating-hours/DailyOperatingHours';
import { useSnackbar } from '../../../hooks/useSnackbar';
import alertMessage from '../../../helpers/alert-message';
import {
  CreateNonWorkingIntervalsByDateForm,
  NonWorkingIntervalsByDateData,
  RemoveNonWorkingIntervalsByDateForm,
  UpdateNonWorkingIntervalsByDateForm,
} from '../components/non-working-intervals-by-date/NonWorkingIntervalsByDate';
import {
  CreateNonWorkingDate,
  UpdateNonWorkingDates,
} from '../components/non-working-dates/NonWorkingDates';
import {
  RequestUpdateOperatingHoursByCompany,
  RequestUpdateUnavailableHoursByDayByCompany,
  companyService,
  operatingHoursService,
} from '../../../services';

export function useOperatingHourCompany() {
  const [loading, setLoading] = useState<boolean>(false);
  const [dailyOperatingHourData, setDailyOperatingHourData] = useState<
    DailyOperatingHoursData[]
  >([]);
  const [nonWorkingIntervalByDateData, setNonWorkingIntervalByDateData] =
    useState<NonWorkingIntervalsByDateData[]>([]);
  const [nonWorkingDatesData, setNonWorkingDatesData] = useState<string[]>([]);
  const companyId = UserContext.companyId();
  const { openSnackbar } = useSnackbar();

  const weekdayTranslations: Record<Weekday, string> = {
    monday: 'Segunda-feira',
    tuesday: 'Terça-feira',
    wednesday: 'Quarta-feira',
    thursday: 'Quinta-feira',
    friday: 'Sexta-feira',
    saturday: 'Sábado',
    sunday: 'Domingo',
  };

  const findOperatingHour = async () => {
    const company = await companyService.findCompanyById(companyId);
    if (company) {
      setDailyOperatingHourData(
        Object.entries(company.openingHours).map(([weekdaykey, weekday]) => ({
          weekdaykey: weekdaykey as Weekday,
          description: weekdayTranslations[weekdaykey as Weekday],
          startTime: weekday.start,
          endTime: weekday.end,
          closed: weekday.closed,
          blockedTimeSlots: weekday.unavailableHours.map((item) => ({
            startTime: item.start,
            endTime: item.end,
            reason: item.description,
          })),
        }))
      );
      setNonWorkingIntervalByDateData(
        company.unavailabilityIntervals.map((item) => ({
          date: item.date,
          intervals: item.intervals.map((intervalItem) => {
            return {
              startTime: intervalItem.start,
              endTime: intervalItem.end,
            };
          }),
        }))
      );
      setNonWorkingDatesData(company.blockedDates);
    } else {
      setDailyOperatingHourData([]);
    }
  };

  const updateDailyOperatingHours = async (data: UpdateDailyOperatingHours) => {
    setLoading(true);
    try {
      const newDailyOperatingHourData = [...dailyOperatingHourData];
      const indexDay = newDailyOperatingHourData.findIndex(
        (item) => item.weekdaykey === data.weekday
      );
      newDailyOperatingHourData[indexDay].closed = data.closed;
      newDailyOperatingHourData[indexDay].startTime = data.startTime;
      newDailyOperatingHourData[indexDay].endTime = data.endTime;

      const daysRequestPayload: RequestUpdateOperatingHoursByCompany = {
        monday: undefined,
        tuesday: undefined,
        wednesday: undefined,
        thursday: undefined,
        friday: undefined,
        saturday: undefined,
        sunday: undefined,
      };

      for (const item of newDailyOperatingHourData) {
        daysRequestPayload[item.weekdaykey] = {
          start: item.startTime,
          end: item.endTime,
          closed: item.closed,
        };
      }

      await operatingHoursService.update({
        companyId,
        ...daysRequestPayload,
      });
      findOperatingHour();
    } catch (err) {
      openSnackbar(alertMessage.unknownErrorMessage, 'error');
    } finally {
      setLoading(false);
    }
  };

  const createClosedIntervalsDailyOperatingHours = async (
    data: CreateClosedIntervalsDailyOperatingHours
  ) => {
    try {
      setLoading(true);

      const dailyOperatingHour = dailyOperatingHourData.find(
        (item) => item.weekdaykey === data.weekday
      );

      if (!dailyOperatingHour) {
        openSnackbar(alertMessage.unknownErrorMessage, 'error');
      } else {
        const unavailableHoursRequestPayload: RequestUpdateUnavailableHoursByDayByCompany =
          {
            day: data.weekday,
            unavailableHours: dailyOperatingHour.blockedTimeSlots.map(
              (item) => ({
                start: item.startTime,
                end: item.endTime,
                description: item.reason,
              })
            ),
          };
        unavailableHoursRequestPayload.unavailableHours.push({
          start: data.startTime,
          end: data.endTime,
          description: data.reason,
        });

        await operatingHoursService.updateUnavailableTimesFromDay({
          companyId,
          day: unavailableHoursRequestPayload.day,
          hours: unavailableHoursRequestPayload.unavailableHours,
        });
        findOperatingHour();
      }
    } catch (err) {
      openSnackbar(alertMessage.unknownErrorMessage, 'error');
    } finally {
      setLoading(false);
    }
  };

  const updateClosedIntervalDailyOperatingHours = async (
    data: UpdateClosedIntervalsDailyOperatingHours
  ) => {
    try {
      setLoading(true);

      const dailyOperatingHour = dailyOperatingHourData.find(
        (item) => item.weekdaykey === data.weekday
      );

      if (!dailyOperatingHour) {
        openSnackbar(alertMessage.unknownErrorMessage, 'error');
      } else {
        const updatedItemBlockedTimeSlots = {
          startTime: data.startTime,
          endTime: data.endTime,
          reason: data.reason,
        };
        const updatedBlockedTimeSlots = [
          ...dailyOperatingHour.blockedTimeSlots,
        ];
        updatedBlockedTimeSlots[data.index] = updatedItemBlockedTimeSlots;
        const unavailableHoursRequestPayload: RequestUpdateUnavailableHoursByDayByCompany =
          {
            day: data.weekday,
            unavailableHours: updatedBlockedTimeSlots.map((item) => ({
              start: item.startTime,
              end: item.endTime,
              description: item.reason,
            })),
          };

        await operatingHoursService.updateUnavailableTimesFromDay({
          companyId,
          day: unavailableHoursRequestPayload.day,
          hours: unavailableHoursRequestPayload.unavailableHours,
        });
        findOperatingHour();
      }
    } catch (err) {
      openSnackbar(alertMessage.unknownErrorMessage, 'error');
    } finally {
      setLoading(false);
    }
  };

  const deleteClosedIntervalDailyOperatingHours = async (
    data: DeleteClosedIntervalsDailyOperatingHours
  ) => {
    try {
      setLoading(true);

      const dailyOperatingHour = dailyOperatingHourData.find(
        (item) => item.weekdaykey === data.weekday
      );

      if (!dailyOperatingHour) {
        openSnackbar(alertMessage.unknownErrorMessage, 'error');
      } else {
        const updatedBlockedTimeSlots = [
          ...dailyOperatingHour.blockedTimeSlots,
        ];
        updatedBlockedTimeSlots.splice(data.index, 1);
        const unavailableHoursRequestPayload: RequestUpdateUnavailableHoursByDayByCompany =
          {
            day: data.weekday,
            unavailableHours: updatedBlockedTimeSlots.map((item) => ({
              start: item.startTime,
              end: item.endTime,
              description: item.reason,
            })),
          };

        await operatingHoursService.updateUnavailableTimesFromDay({
          companyId,
          day: unavailableHoursRequestPayload.day,
          hours: unavailableHoursRequestPayload.unavailableHours,
        });
        findOperatingHour();
      }
    } catch (err) {
      openSnackbar(alertMessage.unknownErrorMessage, 'error');
    } finally {
      setLoading(false);
    }
  };

  const createNonWorkingIntervalsByDateForm = async (
    data: CreateNonWorkingIntervalsByDateForm
  ) => {
    try {
      setLoading(true);

      const updatedNonWorkingIntervalByDateData = [
        ...nonWorkingIntervalByDateData,
      ];
      const indexNonWorkingIntervalByDateData =
        updatedNonWorkingIntervalByDateData.findIndex(
          (item) => item.date === data.date
        );
      if (indexNonWorkingIntervalByDateData === -1) {
        updatedNonWorkingIntervalByDateData.push({
          date: data.date,
          intervals: [
            {
              startTime: data.startTime,
              endTime: data.endTime,
            },
          ],
        });
      } else {
        updatedNonWorkingIntervalByDateData[
          indexNonWorkingIntervalByDateData
        ].intervals.push({
          startTime: data.startTime,
          endTime: data.endTime,
        });
      }

      await operatingHoursService.updateUnavailableHoursByCompany({
        companyId,
        request: {
          unavailableHours: updatedNonWorkingIntervalByDateData.map((item) => ({
            date: item.date,
            intervals: item.intervals.map((intervalItem) => ({
              start: intervalItem.startTime,
              end: intervalItem.endTime,
            })),
          })),
        },
      });
      openSnackbar(alertMessage.createdClosedIntervalDailyOperatingHours);
      findOperatingHour();
    } catch (err) {
      openSnackbar(alertMessage.unknownErrorMessage, 'error');
    } finally {
      setLoading(false);
    }
  };

  const updateNonWorkingIntervalsByDateForm = async (
    data: UpdateNonWorkingIntervalsByDateForm
  ) => {
    try {
      setLoading(true);
      const updatedNonWorkingIntervalByDateData = [
        ...nonWorkingIntervalByDateData,
      ];
      const indexNonWorkingIntervalByDateData =
        updatedNonWorkingIntervalByDateData.findIndex(
          (item) => item.date === data.date
        );
      if (indexNonWorkingIntervalByDateData !== -1) {
        updatedNonWorkingIntervalByDateData[
          indexNonWorkingIntervalByDateData
        ].intervals[data.intervalIndex] = {
          startTime: data.startTime,
          endTime: data.endTime,
        };
        await operatingHoursService.updateUnavailableHoursByCompany({
          companyId,
          request: {
            unavailableHours: updatedNonWorkingIntervalByDateData.map(
              (item) => ({
                date: item.date,
                intervals: item.intervals.map((intervalItem) => ({
                  start: intervalItem.startTime,
                  end: intervalItem.endTime,
                })),
              })
            ),
          },
        });
        openSnackbar(alertMessage.updatedClosedIntervalDailyOperatingHours);
        findOperatingHour();
      }
    } catch (err) {
      openSnackbar(alertMessage.unknownErrorMessage, 'error');
    } finally {
      setLoading(false);
    }
  };

  const removeNonWorkingIntervalsByDateForm = async (
    data: RemoveNonWorkingIntervalsByDateForm
  ) => {
    try {
      setLoading(true);
      const updatedNonWorkingIntervalByDateData = [
        ...nonWorkingIntervalByDateData,
      ];
      const indexNonWorkingIntervalByDateData =
        updatedNonWorkingIntervalByDateData.findIndex(
          (item) => item.date === data.date
        );
      if (indexNonWorkingIntervalByDateData !== -1) {
        updatedNonWorkingIntervalByDateData[
          indexNonWorkingIntervalByDateData
        ].intervals.splice(data.intervalIndex, 1);

        await operatingHoursService.updateUnavailableHoursByCompany({
          companyId,
          request: {
            unavailableHours: updatedNonWorkingIntervalByDateData.map(
              (item) => ({
                date: item.date,
                intervals: item.intervals.map((intervalItem) => ({
                  start: intervalItem.startTime,
                  end: intervalItem.endTime,
                })),
              })
            ),
          },
        });
        openSnackbar(alertMessage.removedClosedIntervalDailyOperatingHours);
        findOperatingHour();
      }
    } catch (err) {
      openSnackbar(alertMessage.unknownErrorMessage, 'error');
    } finally {
      setLoading(false);
    }
  };

  const createNonWorkingDate = async (data: CreateNonWorkingDate) => {
    try {
      const updatedNonWorkingDatesData = [...nonWorkingDatesData];
      updatedNonWorkingDatesData.push(data.date);
      setLoading(true);
      await operatingHoursService.updateBlockedDaysByCompany({
        companyId,
        request: { dates: updatedNonWorkingDatesData.map((item) => item) },
      });
      openSnackbar(alertMessage.createdNonWorkingDate);
      findOperatingHour();
    } catch (err) {
      openSnackbar(alertMessage.unknownErrorMessage, 'error');
    } finally {
      setLoading(false);
    }
  };

  const updateNonWorkingDates = async (data: UpdateNonWorkingDates) => {
    try {
      setLoading(true);
      await operatingHoursService.updateBlockedDaysByCompany({
        companyId,
        request: { dates: data.updatedDates.map((item) => item) },
      });
      openSnackbar(alertMessage.updatedNonWorkingDate);
      findOperatingHour();
    } catch (err) {
      openSnackbar(alertMessage.unknownErrorMessage, 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    findOperatingHour();
  }, []);

  return {
    loading,
    dailyOperatingHourData,
    nonWorkingIntervalByDateData,
    nonWorkingDatesData,
    updateDailyOperatingHours,
    createClosedIntervalsDailyOperatingHours,
    updateClosedIntervalDailyOperatingHours,
    deleteClosedIntervalDailyOperatingHours,
    createNonWorkingIntervalsByDateForm,
    updateNonWorkingIntervalsByDateForm,
    removeNonWorkingIntervalsByDateForm,
    createNonWorkingDate,
    updateNonWorkingDates,
  };
}
