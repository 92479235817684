import { api } from '../clients/http.client';

type Weekday = {
  start: string;
  end: string;
  closed: boolean;
};

type UpdateOperatingHoursRequest = {
  companyId: any;
  monday?: Weekday;
  tuesday?: Weekday;
  wednesday?: Weekday;
  thursday?: Weekday;
  friday?: Weekday;
  saturday?: Weekday;
  sunday?: Weekday;
};

type UpdateUnavailableTimesFromDayRequest = {
  companyId: any;
  day: string;
  hours: {
    start: string;
    end: string;
    description: string;
  }[];
};

type UpdateUnavailableDatesRequest = {
  companyId: any;
  dates: Array<string>;
};

type UpdateUnavailableTimesFromDateRequest = {
  companyId: any;
  dates: {
    date: string;
    intervals: {
      start: string;
      end: string;
    }[];
  }[];
};

type RequestupdateUnavailableHoursByCompany = {
  unavailableHours: {
    date: string;
    intervals: {
      start: string;
      end: string;
    }[];
  }[];
};

type DayRequestUpdateOperatingHoursByCompany = {
  start: string;
  end: string;
  closed: boolean;
};

export type RequestUpdateOperatingHoursByCompany = {
  monday?: DayRequestUpdateOperatingHoursByCompany;
  tuesday?: DayRequestUpdateOperatingHoursByCompany;
  wednesday?: DayRequestUpdateOperatingHoursByCompany;
  thursday?: DayRequestUpdateOperatingHoursByCompany;
  friday?: DayRequestUpdateOperatingHoursByCompany;
  saturday?: DayRequestUpdateOperatingHoursByCompany;
  sunday?: DayRequestUpdateOperatingHoursByCompany;
};

export type RequestUpdateUnavailableHoursByDayByCompany = {
  day: string;
  unavailableHours: {
    start: string;
    end: string;
    description: string;
  }[];
};

export const operatingHoursService = {
  async update(request: UpdateOperatingHoursRequest): Promise<void> {
    const { requestSuccess } = await api({
      url: `api/companies/${request.companyId}/operating-hours`,
      method: 'PATCH',
      data: {
        monday: request.monday,
        tuesday: request.tuesday,
        wednesday: request.wednesday,
        thursday: request.thursday,
        friday: request.friday,
        saturday: request.saturday,
        sunday: request.sunday,
      },
    });

    if (!requestSuccess) {
      throw new Error();
    }
  },

  async updateUnavailableTimesFromDay(
    request: UpdateUnavailableTimesFromDayRequest
  ): Promise<void> {
    const { requestSuccess } = await api({
      url: `api/companies/${request.companyId}/unavailable-hours/${request.day}`,
      method: 'PATCH',
      data: { unavailableHours: request.hours },
    });

    if (!requestSuccess) {
      throw new Error();
    }
  },

  async updateUnavailableDates(
    request: UpdateUnavailableDatesRequest
  ): Promise<void> {
    const { requestSuccess } = await api({
      url: `api/companies/${request.companyId}/blocked-days`,
      method: 'PATCH',
      data: request.dates,
    });

    if (!requestSuccess) {
      throw new Error();
    }
  },

  async updateUnavailableTimesFromDate(
    request: UpdateUnavailableTimesFromDateRequest
  ): Promise<void> {
    const { requestSuccess } = await api({
      url: `api/companies/${request.companyId}/unavailable-hours`,
      method: 'PATCH',
      data: request.dates,
    });

    if (!requestSuccess) {
      throw new Error();
    }
  },

  async updateUnavailableHoursByCompany(request: {
    companyId: any;
    request: RequestupdateUnavailableHoursByCompany;
  }) {
    const { requestSuccess } = await api({
      url: `api/companies/${request.companyId}/unavailable-hours`,
      method: 'PATCH',
      data: request.request,
    });

    if (!requestSuccess) {
      throw new Error();
    }
  },

  async updateBlockedDaysByCompany(params: {
    companyId: any;
    request: {
      dates: string[];
    };
  }) {
    const { requestSuccess } = await api({
      url: `api/companies/${params.companyId}/blocked-days`,
      method: 'PATCH',
      data: params.request,
    });

    if (!requestSuccess) {
      throw new Error();
    }
  },
};
