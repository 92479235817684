import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Link,
  Paper,
  Typography,
} from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';

interface Service {
  id: string;
  name: string;
}

interface AddEmployeeServicesFormProps {
  services: Service[];
  selectedServicesIDs: string[];
  reset?: boolean;
  onChange: (servicesID: string[]) => void;
}

export default function AddEmployeeServicesForm(
  props: AddEmployeeServicesFormProps
) {
  const [selectedServices, setSelectedServices] = useState<string[]>([]);

  useEffect(() => {
    updateServicesCheckbox(props.selectedServicesIDs);
  }, []);

  useEffect(() => {
    if (props.reset) {
      setSelectedServices([]);
    }
  }, [props.reset]);

  const updateServicesCheckbox = (serviceIDs: string[]) => {
    let newSelectedServices = selectedServices;
    serviceIDs.forEach((serviceID) => {
      newSelectedServices = selectedServices.includes(serviceID)
        ? newSelectedServices.filter((id) => id !== serviceID)
        : [...newSelectedServices, serviceID];
    });
    setSelectedServices(newSelectedServices);
    props.onChange(newSelectedServices);
  };

  const handleSelectAll = () => {
    let servicesSelectedEmpty: string[] = [];
    if (selectedServices.length !== props.services.length) {
      servicesSelectedEmpty = props.services.map((service) => service.id);
    }
    setSelectedServices(servicesSelectedEmpty);
    props.onChange(servicesSelectedEmpty);
  };

  const handleServiceChange = (event: ChangeEvent<HTMLInputElement>) => {
    const serviceId = event.target.value;
    updateServicesCheckbox([serviceId]);
  };

  return (
    <>
      <Paper variant="outlined">
        <Box padding={2} display={'flex'} flexDirection={'column'} gap={2}>
          <Typography variant="body1">
            Adicionar Serviços Prestado pelo Funcionário
          </Typography>
          <Typography variant="body2">
            É fundamental escolher os serviços oferecidos por este funcionário,
            de modo a possibilitar que ele seja apresentado como uma opção para
            que seus clientes possam agendar compromissos
          </Typography>
          <Link
            fontSize={12}
            style={{ cursor: 'pointer' }}
            onClick={handleSelectAll}
          >
            Selecionar Todos
          </Link>
          <FormGroup>
            <Box display={'flex'} flexWrap={'wrap'}>
              {props.services.map((service, index) => (
                <Box key={index}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={service.id}
                        checked={selectedServices.includes(service.id)}
                        onChange={handleServiceChange}
                      />
                    }
                    label={
                      <Typography variant="body2">{service.name}</Typography>
                    }
                  />
                </Box>
              ))}
            </Box>
          </FormGroup>
        </Box>
      </Paper>
    </>
  );
}
