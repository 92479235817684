import { useState } from 'react';
import { useSnackbar } from '../../../hooks/useSnackbar';
import alertMessage from '../../../helpers/alert-message';
import { companyService } from '../../../services';
import { UserContext } from '../../../contexts/UserContext';

export const useSettingsPage = () => {
  const [emailBannerURL, setEmailBannerURL] = useState('');
  const [mainBannerURL, setMainBannerURL] = useState('');
  const [loading, setLoading] = useState(false);
  const { openSnackbar } = useSnackbar();
  const companyId = UserContext.companyId();

  const loadPageData = async () => {
    try {
      setLoading(true);

      const company = await companyService.findCompanyById(companyId);

      setEmailBannerURL(company.bannerEmailURL);
      setMainBannerURL(company.landingPageCoverURL);
    } catch (err) {
      openSnackbar(alertMessage.unknownErrorMessage, 'error');
    } finally {
      setLoading(false);
    }
  };

  const updateImagesFromCompany = async (request: {
    bannerEmailURL: string;
    landingPageCoverURL: string;
  }) => {
    const { bannerEmailURL, landingPageCoverURL } = request;

    try {
      setLoading(true);

      await companyService.updateImages({
        companyId,
        bannerEmailURL: bannerEmailURL,
        landingPageCoverURL: landingPageCoverURL,
      });

      await loadPageData();
    } catch (err) {
      openSnackbar(alertMessage.unknownErrorMessage, 'error');
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    emailBannerURL,
    mainBannerURL,
    loadPageData,
    updateImagesFromCompany,
  };
};
