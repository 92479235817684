import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import {
  formatCNPJ,
  formatCPF,
  formatPhone,
} from '../../../helpers/data-formatting';

export type FormGeneral = {
  name: string;
  phone: string;
  cpf: string;
  cnpj: string;
  note: string;
  noticeTimeBeforeAppointmentInHours: string;
  segment: string;
};

interface GeneralCompanyFormProps {
  formData?: FormGeneral;
  handleSubmitButton?: (data: FormGeneral) => void;
  buttonsChildren?: ReactNode;
}

const formSchema = yup.object().shape({
  name: yup.string().required('nome é um campo obrigatório'),
  phone: yup
    .string()
    .required('telefone é um campo obrigatório')
    .transform((val) => (val === '' ? undefined : val))
    .matches(/^\(\d{2}\) \d{5}-\d{4}$/, 'insira um número de telefone válido'),
  cpf: yup
    .string()
    .transform((val) => (val === '' ? undefined : val))
    .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, 'insira um CPF válido'),
  cnpj: yup
    .string()
    .transform((val) => (val === '' ? undefined : val))
    .matches(/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/, 'insira um CNPJ válido'),
  note: yup.string(),
  noticeTimeBeforeAppointmentInHours: yup.string().notRequired(),
  segment: yup.string().notRequired(),
});

export default function GeneralCompanyForm(props: GeneralCompanyFormProps) {
  const { formData, buttonsChildren, handleSubmitButton } = props;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormGeneral>({
    resolver: yupResolver(formSchema),
  });
  const noticeTimes = [
    {
      value: '00:30',
      label: '30 minutos',
    },
    {
      value: '01:00',
      label: '1 hora',
    },
    {
      value: '02:00',
      label: '2 horas',
    },
    {
      value: '04:00',
      label: '4 horas',
    },
  ];
  const segments = [
    {
      id: 'BARBERS',
      description: 'Barbearia',
    },
    {
      id: 'MANICURE_AND_PEDICURE',
      description: 'Manicure e Pedicure',
    },
    {
      id: 'SPA',
      description: 'SPA',
    },
    {
      id: 'UNISEX_SALON',
      description: 'Salão Unisex',
    },
  ];
  const noticeDefaultValue = '00:30';

  const [noticeOption, setNoticeOption] = useState(noticeDefaultValue);
  const [segmentOption, setSegmentOption] = useState('BARBERS');

  useEffect(() => {
    if (formData) {
      setValue('name', formData.name);
      setValue('phone', formatPhone(formData.phone ?? ''));
      setValue('cpf', formatCPF(formData.cpf ?? ''));
      setValue('cnpj', formatCNPJ(formData.cnpj ?? ''));
      setValue('note', formData.note);

      setNoticeOption(
        formData.noticeTimeBeforeAppointmentInHours || noticeDefaultValue
      );
      setSegmentOption(formData.segment);
    }
  }, [formData]);

  const handlePhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const formattedValue = formatPhone(value);
    setValue('phone', formattedValue);
  };

  const handleCPFChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const formattedValue = formatCPF(value);
    setValue('cpf', formattedValue);
  };

  const handleCNPJChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const formattedValue = formatCNPJ(value);
    setValue('cnpj', formattedValue);
  };

  const handleNoticeTimeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setNoticeOption(value);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit((data) => {
          if (handleSubmitButton) {
            data.noticeTimeBeforeAppointmentInHours = noticeOption;
            data.segment = segmentOption;

            handleSubmitButton(data);
          }
        })}
      >
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          <TextField
            label="Nome"
            variant="outlined"
            fullWidth
            error={errors.name?.message !== undefined}
            helperText={errors.name?.message}
            inputProps={{ maxLength: 30 }}
            {...register('name')}
          />
          <TextField
            label="Telefone"
            variant="outlined"
            fullWidth
            error={errors.phone?.message !== undefined}
            helperText={errors.phone?.message}
            inputProps={{ maxLength: 15 }}
            {...register('phone')}
            onChange={handlePhoneChange}
          />
          <TextField
            label="CPF"
            variant="outlined"
            fullWidth
            error={errors.cpf?.message !== undefined}
            helperText={errors.cpf?.message}
            inputProps={{ maxLength: 11 }}
            {...register('cpf')}
            onChange={handleCPFChange}
          />
          <TextField
            label="CNPJ"
            variant="outlined"
            fullWidth
            error={errors.cnpj?.message !== undefined}
            helperText={errors.cnpj?.message}
            inputProps={{ maxLength: 14 }}
            {...register('cnpj')}
            onChange={handleCNPJChange}
          />
          <TextField
            label="Descrição"
            variant="outlined"
            fullWidth
            error={errors.note?.message !== undefined}
            helperText={errors.note?.message}
            inputProps={{ maxLength: 50 }}
            {...register('note')}
          />

          <FormControl fullWidth>
            <InputLabel id="segment-select-label">
              Seleciona o seguimento da sua empresa
            </InputLabel>
            <Select
              labelId="segment-select-label"
              id="segment-select"
              value={segmentOption}
              label="Seleciona o seguimento da sua empresa"
              onChange={(e) => setSegmentOption(e.target.value)}
            >
              {segments.map((segment, index) => (
                <MenuItem key={index} value={segment.id}>
                  {segment.description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormLabel id="demo-row-radio-buttons-group-label">
            Escolha o tempo de aviso ao cliente antes da agenda
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onChange={handleNoticeTimeChange}
          >
            {noticeTimes.map((item, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Radio
                    value={item.value}
                    checked={noticeOption === item.value}
                  />
                }
                label={item.label}
              />
            ))}
          </RadioGroup>

          {buttonsChildren || (
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'right'}
            >
              <Button
                type="submit"
                variant="contained"
                fullWidth={true}
                size="large"
              >
                {formData ? 'Atualizar' : 'Salvar'}
              </Button>
            </Box>
          )}
        </Box>
      </form>
    </>
  );
}
