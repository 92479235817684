import { RightSideModal } from '../../../../right-side-modal/RightSideModal';
import CloseIntervalsForm, {
  FormCloseIntervals,
} from './closed-intervals-form/ClosedIntervalsForm';

interface CloseIntervalsFormModalProps {
  open: boolean;
  formData?: FormCloseIntervals;
  isEditingForm?: boolean;
  disabledSubmitButton?: boolean;
  handleClose: () => void;
  handleSubmitForm: (data: FormCloseIntervals) => void;
  handleDelete: () => void;
}

export function CloseIntervalsFormModal(props: CloseIntervalsFormModalProps) {
  return (
    <>
      <RightSideModal
        open={props.open}
        title="Configurar Intervalo fechado para Marcação de Agendas"
        handleClose={props.handleClose}
      >
        <CloseIntervalsForm
          formData={props.formData}
          isEditing={props.isEditingForm}
          disabledSubmitButton={props.disabledSubmitButton}
          handleSubmit={props.handleSubmitForm}
          handleDelete={props.handleDelete}
        />
      </RightSideModal>
    </>
  );
}
