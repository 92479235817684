import { Box, Button, Divider } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { SubmitHandler } from 'react-hook-form';
import { useSignInPageContext } from './SignInContext';
import { InputEmail } from './components/InputEmail';
import { InputPassword } from './components/InputPassword';
import { LayoutBaseAutenticacaoPagina } from '../../layouts';
import { SignInWithEmailPassword } from './protocols/signin-email-password.protocol';
import { RouteConfig } from '../../infra/config/route';
import { useApplicationContext } from '../../contexts/application';
import { useEffect, useState } from 'react';
import { InfraExceptionError } from '../../helpers/exceptions';
import { Header } from './components/ScreenHeader';
import { Footer } from './components/ScreenFooter';
import { appConfig } from '../../infra/config';
import CenteredCircularProgress from '../../components/centered-circular-progress/CenteredCircularProgress';
import { useSnackbar } from '../../hooks/useSnackbar';
import { useAnalytics } from '../../hooks/useAnalytics';
import { PageNameAnalytics } from '../../helpers/constants';

type LoginValidation = {
  email: string;
  password: string;
};
const loginValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email('O e-mail está inválido')
    .required('O e-mail é obrigatório'),
  password: yup
    .string()
    .required('O password é obrigatório')
    .min(6, 'O password deve possuir no mínimo 6 caracteres'),
});

export function SignInPage() {
  const [loadingSignInEmailPassword, setLoadingSignInEmailPassword] =
    useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginValidation>({
    resolver: yupResolver(loginValidationSchema),
  });
  const { signInWithEmailPassword, getCompanyByUser } = useSignInPageContext();
  const { updateToken, updateCompanyId } = useApplicationContext();
  const navigate = useNavigate();
  const { openSnackbar } = useSnackbar();
  const { logPageView } = useAnalytics();

  useEffect(() => {
    logPageView(PageNameAnalytics.pageSignin);
  }, []);

  const handleSigInSuccess = async (data: SignInWithEmailPassword.Output) => {
    const { token, email } = data;
    updateToken(token);

    if (email) {
      const { id: companyId } = await getCompanyByUser.exec({ email });
      if (companyId === null) {
        openSnackbar(
          'Esse login não está associado a nenhuma empresa. Clique em inscrever-se e cadastre sua empresa.'
        );
      } else {
        updateCompanyId(companyId);

        navigate(RouteConfig.initialSetup, { replace: false });
      }
    }
  };

  const handleSignInError = (error: any) => {
    if (error instanceof InfraExceptionError) {
      openSnackbar(error.message, 'error');
    }
    openSnackbar(
      'Ocorreu um erro inesperado, por favor, tente novamente.',
      'error'
    );
  };

  const handleLogin: SubmitHandler<LoginValidation> = (data) => {
    const { email, password } = data;

    setLoadingSignInEmailPassword(true);

    signInWithEmailPassword
      .exec({
        email,
        password,
      })
      .then(handleSigInSuccess)
      .catch(handleSignInError)
      .finally(() => setLoadingSignInEmailPassword(false));
  };

  return (
    <>
      <CenteredCircularProgress show={loadingSignInEmailPassword} />
      <LayoutBaseAutenticacaoPagina>
        <Box display={'flex'} flexDirection={'column'} gap={2}>
          <Header />
          <Divider />
          <form onSubmit={handleSubmit(handleLogin)}>
            <Box display={'flex'} flexDirection={'column'} gap={2}>
              <InputEmail
                register={register('email')}
                errorMessage={errors.email?.message}
              />
              <InputPassword
                register={register('password')}
                errorMessage={errors.password?.message}
              />
              <Link to={RouteConfig.passwordReset}>Esqueceu sua senha?</Link>
              <Button
                type="submit"
                size="large"
                variant="contained"
                disabled={loadingSignInEmailPassword}
              >
                Entrar
              </Button>
              <Divider />
              {appConfig.setupCompanyFeatureToggle === 'enabled' && <Footer />}
            </Box>
          </form>
        </Box>
      </LayoutBaseAutenticacaoPagina>
    </>
  );
}
