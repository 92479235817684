import {
  Box,
  Icon,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ReactNode } from 'react';
import { useDrawerContext } from '../contexts';

interface LayoutBaseDePaginaProps {
  titulo: string;
  description?: string;
  barraDeFerramentas?: ReactNode;
  children: ReactNode;
}

export const LayoutBaseDePagina: React.FC<LayoutBaseDePaginaProps> = ({
  children,
  titulo,
  description,
  barraDeFerramentas,
}) => {
  const theme = useTheme();
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { toggleDrawerOpen } = useDrawerContext();

  return (
    <>
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        gap={1}
        bgcolor={theme.palette.background.default}
      >
        <Box
          padding={1}
          height={theme.spacing(smDown ? 6 : mdDown ? 8 : 12)}
          display="flex"
          alignItems="center"
          gap={1}
        >
          {smDown && (
            <IconButton onClick={toggleDrawerOpen}>
              <Icon>menu</Icon>
            </IconButton>
          )}

          <Typography
            variant={smDown ? 'h5' : mdDown ? 'h4' : 'h3'}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {titulo}
          </Typography>
        </Box>

        {barraDeFerramentas && <Box>{barraDeFerramentas}</Box>}
        <Box flex={1} overflow="auto" padding={2}>
          <Box display={'flex'} flexDirection={'column'} gap={2}>
            <Typography>{description}</Typography>

            {children}
          </Box>
        </Box>
      </Box>
    </>
  );
};
