export default {
  unknownErrorMessage:
    'Ocorreu um erro desconhecido. Por favor, tente novamente mais tarde ou entre em contato com o suporte para obter assistência.',
  schedulingStatusNotCreatedMessage:
    'Infelizmente, não foi possível atualizar o status do agendamento, pois ele não está mais em estado "Solicitado"',
  updatedSchedulingStatusSuccessMessage:
    'O status do agendamento foi atualizado com sucesso!',
  updatedOpeningHoursSuccessMessage:
    'O horário de funcionamento diário foi atualizado com sucesso!',
  updatedCompanyDataSuccessMessage:
    'Os dados da empresa foi atualizado com sucesso!',
  updatedLocationCompanyDataSuccessMessage:
    'Os dados de localização da empresa foi atualizado com sucesso!',
  updatedClosedIntervalDailyOperatingHours:
    'O intervalo para não agendamentos foi atualizado com sucesso!',
  updatedServiceSuccessMessage: 'O serviço foi atualizado com sucesso!',
  updatedNonWorkingDate:
    'A lista de bloqueios para agendamentos foi atualizada com sucesso!',
  updatedProfessionalSuccessMessage:
    'O profissional foi atualizado com sucesso!',
  updatedProfessionalNotFoundMessage:
    'Não foi possível atualizar o profissional, pois ele não existe.',
  createdServiceSuccessMessage: 'O serviço foi criado com sucesso!',
  createdClosedIntervalDailyOperatingHours:
    'O intervalo para não agendamentos foi criado com sucesso!',
  createdNonWorkingDate:
    'A data de bloqueio para agendamentos foi criada com sucesso!',
  createdProfessionalSuccessMessage: 'O profissional foi criado com sucesso!',
  createdPromotionInServiceSuccessMessage:
    'A promoção do serviço foi criada com sucesso!',
  removedServiceSuccessMessage: 'O serviço foi excluído com sucesso!',
  removedClosedIntervalDailyOperatingHours:
    'O intervalo para não agendamentos foi excluído com sucesso!',
  removedProfessionalNotFoundMessage:
    'Não foi possível localizar o profissional para excluí-lo.',
  removedPromotionByService: 'A promoção foi cancelada com sucesso!',
  createdProfessionalDuplicateEmailMessage:
    'Já existe um profissional cadastrado com o email informado. Por favor, insira um email diferente.',
  createdPromotionInServiceWithPastData:
    'A data e hora selecionadas para a promoção já expiraram.',
  updateServicesToProfessional:
    'Não foi possível atualizar os serviços associados a este profissional. Por favor, tente atualizá-los individualmente.',
  toNextStepIsNecessaryHasServices:
    'Para prosseguir, é necessário cadastrar pelo menos um serviço.',
  finishedRegisterCompanyJourneyMessage:
    'Parabéns! Você concluiu com sucesso o processo de cadastro. Agora, sinta-se à vontade para explorar todas as funcionalidades da plataforma. Compartilhe o link do aplicativo com seus clientes para que eles possam facilmente agendar seus compromissos. Obrigado por se juntar a nós!',
  loadImageFail:
    'Desculpe, não foi possível carregar a imagem. Por favor, tente novamente.',
  fileWithUnrequiredDimension: (x: string, y: string) =>
    `Desculpe, não foi possível carregar a imagem, pois seu tamanho deve estar entre ${x} e ${y} para garantir uma visualização adequada.`,
  shareError:
    'O seu dispositivo não oferece suporte para compartilhar este link; portanto, utilize a opção de copiar o link',
  copyLinkError:
    'O seu dispositivo não oferece suporte para copiar este link; portanto, utilize a opção de acessar o link abaixo',
  copySuccess: 'Link copiado',
  servicesStepEqualToZero: 'Adicione pelo menos um serviço para continuar',
  professionalsStepEqualToZero:
    'Adicione pelo menos um profissional para continuar',
};
