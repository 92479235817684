import { z } from 'zod';

export const AppConfigSchema = z.object({
  apiBaseUrl: z.string(),
  keyTokenLocalStorage: z.string(),
  keyCompanyIdLocalStorage: z.string(),
  setupCompanyFeatureToggle: z.string(),
  firebaseConfigApiKey: z.string(),
  firebaseConfigAuthDomain: z.string(),
  firebaseConfigProjectId: z.string(),
  firebaseConfigStorageBucket: z.string(),
  firebaseConfigMessagingSenderId: z.string(),
  firebaseConfigAppId: z.string(),
  firebaseConfigMeasurementId: z.string(),
  fakeSignin: z.boolean(),
  fakeSigninToken: z.string(),
  fakeSigninEmail: z.string(),
  filesPath: z.string(),
  landingPageURL: z.string(),
  keyCompanyParamLandingPageURL: z.string(),
});

export type AppConfig = z.infer<typeof AppConfigSchema>;

export class AppConfigMapper {
  static toAppConfig(env: NodeJS.ProcessEnv): AppConfig {
    const data = {
      keyTokenLocalStorage: env.REACT_APP_KEY_TOKEN_LOCAL_STORAGE,
      keyCompanyIdLocalStorage: env.REACT_APP_KEY_COMPANY_ID_LOCAL_STORAGE,
      apiBaseUrl: env.REACT_APP_BASE_URL_API,
      firebaseConfigApiKey: env.REACT_APP_FIREBASE_API_KEY,
      firebaseConfigAuthDomain: env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      firebaseConfigProjectId: env.REACT_APP_FIREBASE_PROJECT_ID,
      firebaseConfigStorageBucket: env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      firebaseConfigMessagingSenderId:
        env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      firebaseConfigAppId: env.REACT_APP_FIREBASE_APP_ID,
      firebaseConfigMeasurementId: env.REACT_APP_FIREBASE_MEASUREMENT_ID,
      setupCompanyFeatureToggle: env.REACT_APP_FEATURE_TOGGLE_SETUP_COMPANY,
      fakeSignin: Boolean(env.REACT_APP_FAKE_SIGNIN),
      fakeSigninToken: env.REACT_APP_FAKE_SIGNIN_TOKEN,
      fakeSigninEmail: env.REACT_APP_FAKE_SIGNIN_EMAIL,
      filesPath: env.REACT_APP_FILES_PATH,
      landingPageURL: env.REACT_APP_LANDING_PAGE_URL,
      keyCompanyParamLandingPageURL:
        env.REACT_APP_KEY_COMPANY_PARAM_LANDING_PAGE_URL,
    };

    return AppConfigSchema.parse(data);
  }
}
