import { Box, Button, Typography } from '@mui/material';

export const BoxRowWithButton = (props: {
  label: string;
  showButton?: boolean;
  onClick: () => void;
}) => {
  const { label, showButton = true, onClick } = props;

  return (
    <Box display={'flex'} justifyContent={'space-between'}>
      <Box display={'flex'} flexDirection={'column'} alignSelf={'center'}>
        <Typography variant="caption">{label}</Typography>
      </Box>

      {!showButton && <Typography></Typography>}

      {showButton && <Button onClick={onClick}>Excluir</Button>}
    </Box>
  );
};
