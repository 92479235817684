import { Box, Button, TextField, Typography } from '@mui/material';
import { useBreakpoint } from '../../../../../../hooks';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { formatDDMMYYYYToDate } from '../../../../../../helpers/data-formatting';
import { useAnalytics } from '../../../../../../hooks/useAnalytics';
import { PageNameAnalytics } from '../../../../../../helpers/constants';

export type FormNonWorkingDates = {
  date: string;
};
interface NonWorkingDatesFormProps {
  disabledSubmitButton?: boolean;
  handleSubmit: (data: FormNonWorkingDates) => void;
}

export default function NonWorkingDatesForm(props: NonWorkingDatesFormProps) {
  dayjs.locale('pt-br');

  const formSchema = yup.object().shape({
    date: yup
      .string()
      .required('campo obrigatório')
      .test('isNotPast', 'A data não pode ser no passado', (value: any) => {
        const today = dayjs().startOf('day');
        const selectedDate = dayjs(value, 'DD/MM/YYYY', true).startOf('day');
        const isValidDate = selectedDate.isValid();
        return (
          isValidDate &&
          (selectedDate.isAfter(today) || selectedDate.isSame(today))
        );
      }),
  });

  const { register, formState, setValue, handleSubmit } =
    useForm<FormNonWorkingDates>({
      resolver: yupResolver(formSchema),
    });
  const { desktop } = useBreakpoint();
  const [valueSelectDate, setValueSelectDate] = useState<Dayjs | null>(null);
  const { logPageView } = useAnalytics();

  useEffect(() => {
    logPageView(PageNameAnalytics.pageFormCompanyCloseByDateConfiguration);
  }, []);

  return (
    <>
      <form
        onSubmit={handleSubmit((data) => {
          data.date = formatDDMMYYYYToDate(data.date);
          props.handleSubmit(data);
        })}
      >
        <Box display={'flex'} flexDirection={'column'} gap={4} padding={2}>
          <Box display={'flex'} flexDirection={'column'} gap={1}>
            <Typography variant="body2">
              Inclua a data em que seu estabelecimento estará fechado ou
              indisponível para agendamentos
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Selecione uma data"
                inputFormat="DD/MM/YYYY"
                value={valueSelectDate}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    {...register('date')}
                    error={!!formState.errors.date}
                    helperText={formState.errors.date?.message}
                  />
                )}
                disablePast
                closeOnSelect
                onChange={(date) => {
                  if (date !== null) {
                    setValueSelectDate(date);
                    const formattedDate = date.format('DD/MM/YYYY');
                    setValue('date', formattedDate);
                  }
                }}
              />
            </LocalizationProvider>
          </Box>
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'right'}
            gap={2}
          >
            <Button
              type="submit"
              variant="contained"
              fullWidth={desktop ? false : true}
              size="large"
              disabled={props.disabledSubmitButton}
            >
              Salvar
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
}
