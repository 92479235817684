import { DailyOperatingHoursData } from '../../../components/operating-hour-company/components/daily-operating-hours/DailyOperatingHours';
import OperatingHourCompany from '../../../components/operating-hour-company/OperatingHourCompany';
import { NonWorkingIntervalsByDateData } from '../../../components/operating-hour-company/components/non-working-intervals-by-date/NonWorkingIntervalsByDate';
import { StepItemContentNavigationButtons } from './StepItemContentNavigationButtons';

export type TimeStepData = {
  dailyOperatingHours: Array<DailyOperatingHoursData>;
  nonWorkingIntervalsByDate: Array<NonWorkingIntervalsByDateData>;
  nonWorkingDates: Array<string>;
};

interface TimeStepProps {
  currentIndex: number;
  stepsLength: number;
  handleNext: () => void;
  handleBack: () => void;
}

export function TimeStep(props: TimeStepProps) {
  const { currentIndex, stepsLength, handleNext, handleBack } = props;

  return (
    <>
      <OperatingHourCompany />

      <StepItemContentNavigationButtons
        nextButtonText={
          currentIndex === stepsLength - 1 ? 'Finalizar' : 'Avançar'
        }
        nextButtonDisable={false}
        backButtonText="Voltar"
        backButtonDisable={currentIndex === 0}
        handleBack={handleBack}
        handleNext={handleNext}
      />
    </>
  );
}
