import { createContext } from 'react';

type SnackbarSeverity = 'success' | 'error' | 'warning' | 'info';

type SnackbarContextType = {
  openSnackbar: (message: string, severity?: SnackbarSeverity) => void;
};

const SnackbarContext = createContext<SnackbarContextType | undefined>(
  undefined
);

export default SnackbarContext;
