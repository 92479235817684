import { isYesterday, isToday, isTomorrow } from 'date-fns';

export const formatDate = (date: string) => {
  if (isYesterday(new Date(date + ' ' + '00:00:00'))) {
    return 'Ontem';
  }
  if (isToday(new Date(date + ' ' + '00:00:00'))) {
    return 'Hoje';
  }
  if (isTomorrow(new Date(date + ' ' + '00:00:00'))) {
    return 'Amanhã';
  }
  const dateSplited = date.split('-');
  return `${dateSplited[2]}/${dateSplited[1]}/${dateSplited[0]}`;
};

export const getDescriptionDayWeek = (day: number) => {
  const days = [
    'Domingo',
    'Segunda-feira',
    'Terça-feira',
    'Quarta-feira',
    'Quinta-feira',
    'Sexta-feira',
    'Sábado',
  ];

  return days[day];
};

export const convertValueToCurrency = (value: number) => {
  return 'R$ ' + value.toFixed(2).replace('.', ',');
};
