import { Box } from '@mui/material';
import { useSettingsPage, useFileStorage } from './hooks';
import CenteredCircularProgress from '../../components/centered-circular-progress/CenteredCircularProgress';
import { useEffect } from 'react';
import {
  BoxRowWithButton,
  CustomUpload,
  TitleContainerImages,
} from './components';

export function SettingsPage() {
  const {
    emailBannerURL,
    mainBannerURL,
    loading,
    loadPageData,
    updateImagesFromCompany,
  } = useSettingsPage();
  const { removeFile } = useFileStorage();

  useEffect(() => {
    loadPageData();
  }, []);

  return (
    <>
      <CenteredCircularProgress show={loading} />

      <Box display={'flex'} flexDirection={'column'} gap={2}>
        <TitleContainerImages
          title="Imagem Principal do Email"
          description="Essa imagem será apresentada em todos os e-mails automaticamente enviados pela ferramenta aos seus clientes, seja para confirmar ou cancelar agendamentos."
        />
        {emailBannerURL && (
          <Box width={400}>
            <BoxRowWithButton
              label=""
              onClick={async () => {
                await updateImagesFromCompany({
                  bannerEmailURL: '',
                  landingPageCoverURL: mainBannerURL,
                });
                removeFile(emailBannerURL);
              }}
            />
            <img src={emailBannerURL} width={400} height={300} />
          </Box>
        )}
        {!emailBannerURL && (
          <CustomUpload
            handleUploadComplted={(downloadURL: string) => {
              updateImagesFromCompany({
                bannerEmailURL: downloadURL,
                landingPageCoverURL: mainBannerURL,
              });
            }}
          />
        )}

        <TitleContainerImages
          title="Imagem Principal da Empresa"
          description="Esta imagem será apresentada como o banner principal na página inicial da sua empresa."
        />
        {mainBannerURL && (
          <Box width={400}>
            <BoxRowWithButton
              label=""
              onClick={async () => {
                await updateImagesFromCompany({
                  bannerEmailURL: emailBannerURL,
                  landingPageCoverURL: '',
                });
                removeFile(mainBannerURL);
              }}
            />
            <img src={mainBannerURL} width={400} height={300} />
          </Box>
        )}
        {!mainBannerURL && (
          <CustomUpload
            handleUploadComplted={(downloadURL: string) => {
              updateImagesFromCompany({
                bannerEmailURL: emailBannerURL,
                landingPageCoverURL: downloadURL,
              });
            }}
          />
        )}
      </Box>
    </>
  );
}
