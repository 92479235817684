import { VisibilityOff, Visibility } from '@mui/icons-material';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { useState } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

export const InputPassword: React.FC<{
  register: UseFormRegisterReturn;
  errorMessage: string | undefined;
}> = (params) => {
  const [showPassword, setShowPassword] = useState(false);
  const { errorMessage, register } = params;

  const handleClickShowPassword = (event: any) => {
    event.preventDefault();
    setShowPassword((show) => !show);
  };

  return (
    <TextField
      {...register}
      error={errorMessage ? true : false}
      helperText={errorMessage}
      type={showPassword ? 'text' : 'password'}
      label={'Senha'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
