import { useState } from 'react';
import { useSnackbar } from '../../../hooks/useSnackbar';
import alertMessage from '../../../helpers/alert-message';
import { companyService } from '../../../services';
import { UserContext } from '../../../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { RouteConfig } from '../../../infra/config/route';

export const useRegisterCompanyPage = () => {
  const [loading, setLoading] = useState(false);
  const { openSnackbar } = useSnackbar();
  const companyId = UserContext.companyId();
  const navigate = useNavigate();

  const navigateToMainPage = () => navigate(RouteConfig.main);

  const setRegistrationCompleted = async () => {
    try {
      setLoading(true);

      await companyService.updateRegistrationCompleted({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        companyId: companyId!,
        registerCompleted: true,
      });

      navigateToMainPage();

      openSnackbar(alertMessage.finishedRegisterCompanyJourneyMessage);
    } catch (err) {
      openSnackbar(alertMessage.unknownErrorMessage, 'error');
    } finally {
      setLoading(false);
    }
  };

  const validatePendingRegistration = async () => {
    try {
      setLoading(true);

      const company = await companyService.findCompanyById(companyId);
      if (!company) {
        throw new Error('company not found');
      }

      if (company.registrationCompleted) {
        return navigateToMainPage();
      }
    } catch (err) {
      openSnackbar(alertMessage.unknownErrorMessage, 'error');
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    validatePendingRegistration,
    setRegistrationCompleted,
  };
};
