import { Box, Typography } from '@mui/material';

export const TitleContainerImages = (props: {
  title: string;
  description?: string;
}) => {
  const { title, description = '' } = props;

  return (
    <>
      <Box display={'flex'} alignItems={'center'}>
        <Typography variant="h6">{title}</Typography>
      </Box>
      <Typography variant="caption">{description}</Typography>
    </>
  );
};
