import { useEffect, useState } from 'react';
import { useSnackbar } from '../../../hooks/useSnackbar';
import { UserContext } from '../../../contexts/UserContext';
import alertMessage from '../../../helpers/alert-message';
import { FormLocation } from '../components/location-company-form/LocationCompanyForm';
import { companyService } from '../../../services';

interface UseLocationCompanyResponse {
  loading: boolean;
  formLocation?: FormLocation;
  update: (data: FormLocation) => Promise<{ isError: boolean }>;
}

export function useLocationCompany(): UseLocationCompanyResponse {
  const [loading, setLoading] = useState<boolean>(false);
  const [formLocation, setFormLocation] = useState<FormLocation | undefined>(
    undefined
  );
  const { openSnackbar } = useSnackbar();
  const companyId = UserContext.companyId();

  const findLocationData = async () => {
    const company = await companyService.findCompanyById(companyId);
    if (company) {
      setFormLocation({
        zipCode: company.location.zipCode,
        uf: company.location.state,
        city: company.location.city,
        street: company.location.street,
        neighborhood: company.location.neighborhood,
        houseNumber: company.location.number,
      });
    } else {
      setFormLocation(undefined);
    }
  };

  const update = async (data: FormLocation) => {
    let isError = false;
    try {
      setLoading(true);

      await companyService.updateLocation({
        id: companyId,
        zipCode: data.zipCode,
        state: data.uf,
        city: data.city,
        neighborhood: data.neighborhood,
        number: data.houseNumber,
        street: data.street,
      });

      openSnackbar(alertMessage.updatedCompanyDataSuccessMessage);
    } catch (err) {
      openSnackbar(alertMessage.unknownErrorMessage, 'error');
      isError = true;
    } finally {
      setLoading(false);
    }

    return { isError };
  };

  useEffect(() => {
    findLocationData();
  }, []);

  return {
    loading,
    formLocation,
    update,
  };
}
