import { useEffect, useState } from 'react';
import { RightSideModal } from '../../../components/right-side-modal/RightSideModal';
import { SchedulingService } from '../../../services/scheduling.service';
import {
  SchedulingDetails,
  UpdateStatusFormData,
} from './scheduling-details/SchedulingDetails';
import alertMessage from '../../../helpers/alert-message';
import serviceError from '../../../helpers/service-error';
import CenteredCircularProgress from '../../../components/centered-circular-progress/CenteredCircularProgress';
import { useSnackbar } from '../../../hooks/useSnackbar';
import { UserContext } from '../../../contexts/UserContext';

export type ClickCloseProps = {
  recordModified: boolean;
};

export const SchedulingDetailsModal: React.FC<{
  open: boolean;
  scheduling: {
    id: string;
    professionalName: string;
    companyName: string;
    eventDate: string;
    eventTime: string;
    customerName: string;
    serviceName: string;
    serviceAmount: number;
    eventEndTime: string;
    eventStartTime: string;
    ratingStar?: number;
    ratingComment?: string;
    status: 'created' | 'pending' | 'complete' | 'cancelled';
    statusDescription: string;
  };
  clickClose?: (data?: ClickCloseProps) => void;
}> = (props) => {
  const [disableAllStatus, setDisableAllStatus] = useState(false);
  const [isRecordModified, setRecordModified] = useState(false);
  const [loading, setLoading] = useState(false);
  const { openSnackbar } = useSnackbar();

  const handleSubmitUpdateStatusForm = (data: UpdateStatusFormData) => {
    const { scheduling } = props;
    const service = new SchedulingService();

    setLoading(true);
    service
      .updateStatus({
        scheduleId: scheduling.id,
        status: data.status === 'cancelled' ? 'cancel' : 'complete',
        statusDescription: data.description,
        userAuthEmail: UserContext.getEmail(),
      })
      .then(({ requestSuccess, errorData }) => {
        if (requestSuccess) {
          openSnackbar(alertMessage.updatedSchedulingStatusSuccessMessage);
          setDisableAllStatus(true);
          setRecordModified(true);
          props.scheduling.status = data.status;
          props.scheduling.statusDescription = data.description;
        } else if (errorData?.error === serviceError.scheduleNotCreated) {
          openSnackbar(alertMessage.schedulingStatusNotCreatedMessage, 'error');
        } else {
          openSnackbar(alertMessage.unknownErrorMessage, 'error');
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (props.open) {
      setDisableAllStatus(false);
      setRecordModified(false);
    }
  }, [props.open]);

  return (
    <RightSideModal
      open={props.open}
      title={'Detalhes do Agendamento'}
      handleClose={() => {
        if (props.clickClose) {
          props.clickClose({
            recordModified: isRecordModified,
          });
        }
      }}
    >
      <>
        <CenteredCircularProgress show={loading} />
        <SchedulingDetails
          key={'DetailsSchedule'}
          scheduling={props.scheduling}
          statusDisabled={disableAllStatus}
          onSubmitUpdateStatusForm={handleSubmitUpdateStatusForm}
        />
      </>
    </RightSideModal>
  );
};
