import { CircularProgress } from '@mui/material';

type CenteredCircularProgressProps = {
  show?: boolean;
};

export default function CenteredCircularProgress(
  props: CenteredCircularProgressProps
) {
  return props.show ? (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <></>
  );
}
