import { Alert, AlertColor, Snackbar } from '@mui/material';
import { ReactNode, useState } from 'react';
import SnackbarContext from './AlertSnackbarContext';

interface SnackbarProviderProps {
  children: ReactNode;
}

export function SnackbarProvider(props: SnackbarProviderProps) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<AlertColor>('success');

  const handleOpenSnackbar = (
    message: string,
    severity: AlertColor = 'success'
  ) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <SnackbarContext.Provider value={{ openSnackbar: handleOpenSnackbar }}>
      {props.children}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
      >
        <Alert severity={snackbarSeverity} onClose={handleCloseSnackbar}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
}
