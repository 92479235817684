import {
  Box,
  Button,
  ButtonBase,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import CenteredCircularProgress from '../../../components/centered-circular-progress/CenteredCircularProgress';
import { useProfessionalCompany } from '../../../components/professional-company/hooks';
import { StepItemContentNavigationButtons } from './StepItemContentNavigationButtons';
import { ProfessionalCompanyFormModal } from '../../../components/professional-company/components/professional-company-form-modal/ProfessionalCompanyFormModal';
import React, { useState } from 'react';
import { FormProfessional } from '../../../components/professional-company/components/professional-company-form-modal/professional-company-form/ProfessionalCompanyForm';
import { useBreakpoint } from '../../../hooks';
import { useSnackbar } from '../../../hooks/useSnackbar';
import alertMessage from '../../../helpers/alert-message';

interface ProfessionalStepProps {
  currentIndex: number;
  stepsLength: number;
  handleNext: () => void;
  handleBack: () => void;
}

export function ProfessionalStep(props: ProfessionalStepProps) {
  const { currentIndex, stepsLength, handleNext, handleBack } = props;
  const {
    loading,
    professionals,
    services,
    createProfessional,
    updateProfessional,
    deleteProfessional,
  } = useProfessionalCompany();
  const [
    openProfessionalCompanyFormModal,
    setOpenProfessionalCompanyFormModal,
  ] = useState(false);
  const [professionalSelectedForEditing, setProfessionalSelectedForEditing] =
    useState<{ id: any } & FormProfessional>();
  const [
    titleProfessionalCompanyFormModal,
    setTitleProfessionalCompanyFormModal,
  ] = useState('');
  const { desktop } = useBreakpoint();
  const { openSnackbar } = useSnackbar();

  return (
    <>
      <CenteredCircularProgress show={loading} />

      <ProfessionalCompanyFormModal
        open={openProfessionalCompanyFormModal}
        title={titleProfessionalCompanyFormModal}
        formData={professionalSelectedForEditing}
        services={services}
        handleClose={() => setOpenProfessionalCompanyFormModal(false)}
        handleSubmitForm={(data) => {
          if (professionalSelectedForEditing) {
            updateProfessional(professionalSelectedForEditing.id, data);
          } else {
            createProfessional(data);
          }
          setOpenProfessionalCompanyFormModal(false);
        }}
        handleDeleteProfessional={() => {
          setOpenProfessionalCompanyFormModal(false);
          deleteProfessional(professionalSelectedForEditing?.id);
        }}
      />

      <Box display={'flex'} flexDirection={'column'} gap={2}>
        <Button
          variant="outlined"
          fullWidth={desktop ? true : false}
          size="large"
          onClick={() => {
            setProfessionalSelectedForEditing(undefined);
            setOpenProfessionalCompanyFormModal(true);
            setTitleProfessionalCompanyFormModal('Adicionar Novo Funcionário');
          }}
        >
          Adicionar
        </Button>
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
          {professionals.map((professional, index) => (
            <React.Fragment key={index}>
              <ButtonBase style={{ width: '100%' }}>
                <ListItem
                  alignItems="flex-start"
                  sx={{
                    width: '100%',
                    textAlign: 'left',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    },
                  }}
                  onClick={() => {
                    setProfessionalSelectedForEditing({
                      id: professional.id,
                      name: professional.name,
                      email: professional.email,
                      phone: professional.phone,
                      serviceIntervalInMinutes:
                        professional.serviceIntervalInMinutes,
                      servicesIDs: professional.servicesIDs,
                      avatarURL: professional.avatarURL,
                    });
                    setOpenProfessionalCompanyFormModal(true);
                    setTitleProfessionalCompanyFormModal(
                      'Atualizar Funcionário'
                    );
                  }}
                >
                  <>
                    <Box display={'flex'} flexDirection={'column'}>
                      <Typography color="text.primary">
                        {professional.name}
                      </Typography>
                      <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        gap={1}
                      >
                        <span>email:</span>
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {professional.email}
                        </Typography>
                      </Box>
                      <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        gap={1}
                      >
                        <span>telefone:</span>
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {professional.phone}
                        </Typography>
                      </Box>
                    </Box>
                  </>
                </ListItem>
              </ButtonBase>
            </React.Fragment>
          ))}
        </List>
      </Box>

      <StepItemContentNavigationButtons
        nextButtonText={
          currentIndex === stepsLength - 1 ? 'Finalizar' : 'Avançar'
        }
        nextButtonDisable={false}
        backButtonText="Voltar"
        backButtonDisable={currentIndex === 0}
        handleBack={handleBack}
        handleNext={() => {
          if (professionals.length > 0) {
            handleNext();
          } else {
            openSnackbar(alertMessage.professionalsStepEqualToZero, 'info');
          }
        }}
      />
    </>
  );
}
