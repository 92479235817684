import { LocalStorage } from '../helpers/storage';
import { appConfig } from '../infra/config';
import jwtDecode from 'jwt-decode';

// type UserData = {
//   identityUserId?: string;
//   email?: string;
//   token?: string;
//   companyId?: string;
//   initialSettingsCompany?: string;
// };

export type TokenType = {
  email: string;
  name: string;
  picture: string;
  user_id: string;
};

export const UserContext = {
  companyId: () => LocalStorage.getValue(appConfig.keyCompanyIdLocalStorage),

  getEmail: (): string => {
    const token = LocalStorage.getValue(appConfig.keyTokenLocalStorage);

    if (!token) return '';

    const { email } = jwtDecode<TokenType>(token);

    return email;
  },
};
