import { StepItemContentNavigationButtons } from './StepItemContentNavigationButtons';
import { StepLayout } from '../layout/StepLayout';
import { useGeneralCompany } from '../../../components/general-company/hooks';
import CenteredCircularProgress from '../../../components/centered-circular-progress/CenteredCircularProgress';
import GeneralCompanyForm from '../../../components/general-company/general-company-form/GeneralCompanyForm';

interface GeneralStepProps {
  currentIndex: number;
  stepsLength: number;
  handleNext: () => void;
  handleBack: () => void;
}

export function GeneralStep(props: GeneralStepProps) {
  const { currentIndex, stepsLength, handleNext, handleBack } = props;
  const { loading, formData, update } = useGeneralCompany();

  return (
    <StepLayout
      title="Complete o formulário com os detalhes da sua empresa, incluindo nome,
    CPF/CNPJ e informações essenciais."
    >
      <>
        <CenteredCircularProgress show={loading} />

        {formData && (
          <GeneralCompanyForm
            formData={formData}
            handleSubmitButton={async (data) => {
              const { isError } = await update(data);

              if (!isError) handleNext();
            }}
            buttonsChildren={
              <StepItemContentNavigationButtons
                nextButtonText={
                  currentIndex === stepsLength - 1 ? 'Finalizar' : 'Avançar'
                }
                nextButtonDisable={false}
                backButtonText="Voltar"
                backButtonDisable={currentIndex === 0}
                handleBack={handleBack}
              />
            }
          />
        )}
      </>
    </StepLayout>
  );
}
