import { Box, Link, TextField, Typography } from '@mui/material';
import { object, ref, string } from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { LayoutBaseAutenticacaoPagina } from '../../../../layouts';
import { InputPassword } from '../../components/InputPassword';
import { useSignInPageContext } from '../../SignInContext';
import { useState } from 'react';
import { ButtonSignUp } from '../../components/ButtonSignUp';
import { useSnackbar } from '../../../../hooks/useSnackbar';

type CreateUser = {
  email: string;
  confirmEmail: string;
  password: string;
  name: string;
};
const validationSchema = object().shape({
  email: string()
    .email('O e-mail está incorreto')
    .required('O e-mail é obrigatório'),
  confirmEmail: string()
    .email('O e-mail está incorreto')
    .required()
    .oneOf([ref('email'), ''], 'Os e-mails estão diferentes'),
  password: string().min(6, 'O password deve ter no mínimo 6 caracteres'),
  name: string().required('O seun nome é obrigatório'),
});

export const SignUpPage = () => {
  const { registerUser } = useSignInPageContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { openSnackbar } = useSnackbar();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CreateUser>({
    resolver: yupResolver(validationSchema),
  });

  const handleSignUpSuccess = () => {
    openSnackbar('Usuário foi cadastrado com sucesso!');
    reset({ email: '', confirmEmail: '', password: '', name: '' });
  };

  const handleSignup: SubmitHandler<CreateUser> = async (data) => {
    const { email, password, name } = data;
    setLoading(true);
    registerUser
      .exec({ email, password, name })
      .then(handleSignUpSuccess)
      .finally(() => setLoading(false));
  };

  return (
    <>
      <LayoutBaseAutenticacaoPagina title="Inscreva-se.">
        <Box display={'flex'} flexDirection={'column'} gap={2}>
          <TextField
            {...register('email')}
            error={errors.email?.message !== undefined}
            helperText={errors.email?.message}
            type={'email'}
            placeholder={'Insira seu e-mail.'}
            label={'Qual é o seu e-mail?'}
          />
          <TextField
            {...register('confirmEmail')}
            error={errors.confirmEmail?.message !== undefined}
            helperText={errors.confirmEmail?.message}
            type={'email'}
            placeholder={'Insira o e-mail novamente.'}
            label={'Confirme seu e-mail'}
          />
          <InputPassword
            register={register('password')}
            errorMessage={errors.name?.message}
          />
          <TextField
            {...register('name')}
            error={errors.name?.message !== undefined}
            helperText={errors.name?.message}
            type={'text'}
            placeholder={'Insira um nome de perfil'}
            label={'Como devemos chamar você?'}
          />
        </Box>
        <ButtonSignUp loading={loading} onClick={handleSubmit(handleSignup)} />
        <Box textAlign={'center'}>
          <Typography>
            Já tem uma conta?{' '}
            <Link onClick={() => navigate(-1)}>Faça login</Link>.
          </Typography>
        </Box>
      </LayoutBaseAutenticacaoPagina>
    </>
  );
};
