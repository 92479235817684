import { Refresh, Warning } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';

type NonEmptyScreenProps = {
  icon?: any;
  text?: string;
  showReloadButton?: boolean;
  onReload?: () => void;
};

export default function NonEmptyScreen(props: NonEmptyScreenProps) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      margin="auto"
    >
      <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
        <Box display={'flex'} flexDirection={'column'} alignItems="center">
          {props.icon ? <props.icon /> : <Warning fontSize="medium" />}
          <Typography fontWeight="lighter" textAlign={'center'}>
            {props.text ? props.text : 'Sem resultados encontrados.'}
          </Typography>
        </Box>
        {props.showReloadButton && (
          <Button variant="outlined" onClick={props.onReload}>
            <Refresh />
            Recarregar
          </Button>
        )}
      </Box>
    </Box>
  );
}
