import axios, { AxiosRequestConfig } from 'axios';
import { httpError } from '../helpers/constants';
import { LocalStorage } from '../helpers/storage';
import { appConfig } from '../infra/config';
import { signOut } from '../helpers/signout';
import { useSnackbar } from '../hooks/useSnackbar';
import alertMessage from '../helpers/alert-message';

const instance = axios.create({
  baseURL: appConfig.apiBaseUrl,
});

export interface ApiResponse {
  data: any;
  statusCode: number;
  errorData: {
    error: string;
  } | null;
  requestSuccess: boolean;
}

export const api = async <T = unknown>(
  options: AxiosRequestConfig
): Promise<ApiResponse> => {
  let data: T | null = null;
  let statusCode: number;
  let errorData: { error: string } | null = null;
  let requestSuccess: boolean;
  try {
    const response = await instance({
      headers: {
        'X-Access-Token': LocalStorage.getValue(appConfig.keyTokenLocalStorage),
      },
      ...options,
    });
    data = response.data;
    statusCode = response.status;
    requestSuccess = true;
  } catch (err: any) {
    statusCode = err?.response?.status || null;
    errorData = err?.response?.data || {};
    requestSuccess = false;
  }
  return { data, statusCode, errorData, requestSuccess };
};

export const HttpInterceptor = (props: { children: any }) => {
  const { openSnackbar } = useSnackbar();

  const redirectToLogin = () => {
    signOut();
  };

  const resInterceptor = (response: any) => {
    return response;
  };

  const errInterceptor = (error: any) => {
    const statusCode = error?.response?.status;
    if (statusCode === httpError.FORBIDDEN) {
      redirectToLogin();
    }

    if (
      statusCode === httpError.INTERNAL_SERVER_ERROR ||
      (statusCode === httpError.NOT_FOUND &&
        error?.response?.data?.error === 'CompanyNotFound')
    ) {
      openSnackbar(alertMessage.unknownErrorMessage, 'error');
    }

    if (
      statusCode === httpError.NOT_FOUND &&
      error?.response?.data?.error === 'UserNotFound'
    ) {
      openSnackbar('Seu usuário não foi encontrado, tente novamente.', 'error');
    }

    return Promise.reject(error);
  };

  instance.interceptors.response.use(resInterceptor, errInterceptor);

  return props.children;
};
