import { faker } from '@faker-js/faker';
import { api } from '../../clients/http.client';
import { IGetCompanyByUser } from '../../pages/signin/protocols/get-company-by-user.protocol';

export const apiRestGetCompanyByUserFactory = (): IGetCompanyByUser =>
  new APIRestGetCompanyByUser();
export const mockGetCompanyByUserFactory = (): IGetCompanyByUser =>
  new MockGetCompanyByUser();

export class MockGetCompanyByUser implements IGetCompanyByUser {
  params?: IGetCompanyByUser.Input;
  async exec(
    params: IGetCompanyByUser.Input
  ): Promise<IGetCompanyByUser.Output> {
    this.params = params;
    return Promise.resolve({ id: faker.datatype.uuid() });
  }
}

export class APIRestGetCompanyByUser implements IGetCompanyByUser {
  async exec(
    params: IGetCompanyByUser.Input
  ): Promise<IGetCompanyByUser.Output> {
    const { email } = params;
    const { data } = await api<{ id: string }>({
      method: 'GET',
      url: 'api/companies/users/' + email,
    });
    if (!data) {
      return { id: null };
    }
    return {
      id: data.id,
    };
  }
}
