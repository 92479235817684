import { Box, Chip, Icon, Paper, Typography, useTheme } from '@mui/material';
import { useBreakpoint } from '../../../../../hooks';
import { formatDate } from '../../../../../helpers/format-utils';

type Status = 'created' | 'complete' | 'cancelled' | 'pending';

export type ItemTableSchedule = {
  id: string;
  eventDate: string;
  eventTime: string;
  serviceName: string;
  serviceAmount: number;
  status: Status;
  statusDescription: string;
  companyName: string;
  customerName: string;
  professionalName: string;
  eventStartTime: string;
  eventEndTime: string;
  ratingStar?: number;
  ratingComment?: string;
};

export const TableSchedules: React.FC<{
  schedules: ItemTableSchedule[];
  onClick?: (schedule: ItemTableSchedule) => void;
}> = ({ schedules, onClick }) => {
  const theme = useTheme();
  const breakpoints = useBreakpoint();

  const handleOnClick = (data: ItemTableSchedule) => {
    if (onClick) {
      onClick(data);
    }
  };

  const statusDescription = (status: Status) => {
    if (status === 'pending') {
      return 'Pendente';
    }
    if (status === 'created') {
      return 'Solicitado';
    }
    if (status === 'complete') {
      return 'Finalizado';
    }
    if (status === 'cancelled') {
      return 'Cancelado';
    }
  };

  return (
    <>
      {!breakpoints.desktop && (
        <Box display={'flex'} flexDirection={'column'} padding={2} gap={2}>
          {schedules.map((schedule, index) => (
            <Box
              key={index}
              component={Paper}
              display={'flex'}
              flexDirection={'column'}
              gap={1.5}
              padding={theme.spacing(2)}
              onClick={() => handleOnClick(schedule)}
              style={{ cursor: 'pointer' }}
            >
              <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
              >
                <Box display={'flex'} gap={4}>
                  <Box display={'flex'} gap={1}>
                    <Icon fontSize={'small'}>access_time</Icon>
                    <Typography>{schedule.eventTime}</Typography>
                  </Box>
                  <Box display={'flex'} gap={1}>
                    <Icon fontSize={'small'}>event</Icon>
                    <Typography>{formatDate(schedule.eventDate)}</Typography>
                  </Box>
                </Box>
                <Box display={'flex'} justifyContent={'space-between'}>
                  <Chip label={statusDescription(schedule.status)} />
                </Box>
              </Box>
              <Box display={'flex'} gap={4}>
                <Box display={'flex'} flexDirection={'column'}>
                  <Typography fontSize={theme.spacing(1.5)} color={'GrayText'}>
                    Profissional
                  </Typography>
                  <Typography>{schedule.professionalName}</Typography>
                </Box>
                <Box display={'flex'} flexDirection={'column'}>
                  <Typography fontSize={theme.spacing(1.5)} color={'GrayText'}>
                    Serviço
                  </Typography>
                  <Typography>{schedule.serviceName}</Typography>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      )}

      {breakpoints.desktop && (
        <Box display={'flex'} flexDirection={'column'} padding={2} gap={2}>
          <Box
            display={'flex'}
            paddingTop={theme.spacing(4)}
            paddingBottom={theme.spacing(4)}
            paddingLeft={theme.spacing(2)}
            paddingRight={theme.spacing(2)}
            gap={2}
          >
            <Typography component={Box} width={theme.spacing(12)}>
              Data
            </Typography>
            <Typography component={Box} width={theme.spacing(16)}>
              Horário
            </Typography>
            <Typography component={Box} width={theme.spacing(14)}>
              Profissional
            </Typography>
            <Typography component={Box} width={theme.spacing(30)}>
              Serviço
            </Typography>
            <Typography component={Box} width={theme.spacing(10)}>
              Valor
            </Typography>
            <Typography component={Box} width={theme.spacing(10)}>
              Status
            </Typography>
          </Box>
          {schedules.map((schedule, index) => (
            <Box
              key={index}
              component={Paper}
              display={'flex'}
              alignContent={'space-between'}
              paddingTop={theme.spacing(4)}
              paddingBottom={theme.spacing(4)}
              paddingLeft={theme.spacing(2)}
              paddingRight={theme.spacing(2)}
              gap={1}
              onClick={() => handleOnClick(schedule)}
              style={{ cursor: 'pointer' }}
            >
              <Box display={'flex'} width={'100%'} gap={2}>
                <Typography component={Box} width={theme.spacing(12)}>
                  {formatDate(schedule.eventDate)}
                </Typography>
                <Typography component={Box} width={theme.spacing(16)}>
                  {schedule.eventTime}
                </Typography>
                <Typography component={Box} width={theme.spacing(14)}>
                  {schedule.professionalName}
                </Typography>
                <Typography component={Box} width={theme.spacing(30)}>
                  {schedule.serviceName}
                </Typography>
                <Typography component={Box} width={theme.spacing(10)}>
                  {schedule.serviceAmount}
                </Typography>
                <Typography component={Box} width={theme.spacing(10)}>
                  {statusDescription(schedule.status)}
                </Typography>
              </Box>
              <Box flex={1}>
                <Icon>navigate_next</Icon>
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};
