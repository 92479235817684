import { useMediaQuery, useTheme } from '@mui/material';

export function useBreakpoint() {
  const theme = useTheme();

  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const tablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const laptop = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const desktop = useMediaQuery(theme.breakpoints.up('lg'));

  return {
    mobile,
    tablet,
    laptop,
    desktop,
  };
}
