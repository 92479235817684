import { RightSideModal } from '../../../../right-side-modal/RightSideModal';
import DailyOperatingHoursForm, {
  FormDailyOperatingHours,
} from './daily-operating-hours-form/DailyOperatingHoursForm';

interface DailyOperatingHoursFormModalProps {
  open: boolean;
  formData?: FormDailyOperatingHours;
  disabledSubmitButton?: boolean;
  handleClose: () => void;
  handleSubmitForm: (data: FormDailyOperatingHours) => void;
}

export function DailyOperatingHoursFormModal(
  props: DailyOperatingHoursFormModalProps
) {
  return (
    <>
      <RightSideModal
        open={props.open}
        title={'Configurar Horário de Abertura e Fechamento do Estabelecimento'}
        handleClose={props.handleClose}
      >
        <DailyOperatingHoursForm
          formData={props.formData}
          disabledSubmitButton={props.disabledSubmitButton}
          handleSubmit={props.handleSubmitForm}
        />
      </RightSideModal>
    </>
  );
}
