import { ReactNode, createContext, useContext } from 'react';
import { LocalStorage } from '../../helpers/storage';
import { appConfig } from '../../infra/config';

export const ApplicationContext = createContext(
  {} as {
    isSettings: () => boolean;
    getToken: () => string | null;
    getCompanyId: () => string | null;
    updateToken: (token: string) => void;
    updateCompanyId: (companyId: string) => void;
  }
);
export const useApplicationContext = () => useContext(ApplicationContext);
export const ApplicationProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const getToken = (): string | null => {
    return LocalStorage.getValue(appConfig.keyTokenLocalStorage);
  };

  const isSettings = (): boolean => {
    const companyId = LocalStorage.getValue(appConfig.keyCompanyIdLocalStorage);
    return !!companyId;
  };

  const getCompanyId = (): string | null => {
    return LocalStorage.getValue(appConfig.keyCompanyIdLocalStorage);
  };

  const updateCompanyId = (companyId: string): void => {
    LocalStorage.setItem(appConfig.keyCompanyIdLocalStorage, companyId);
  };

  const updateToken = (token: string): void => {
    LocalStorage.setItem(appConfig.keyTokenLocalStorage, token);
  };

  return (
    <ApplicationContext.Provider
      value={{
        isSettings,
        getToken,
        getCompanyId,
        updateCompanyId,
        updateToken,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};
