import { UserCredential } from '@firebase/auth';
import * as firebaseAuth from 'firebase/auth';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { app } from '../infra/config/firebase';

export interface IdentityClient {
  signInWithEmailAndPassword: (
    email: string,
    password: string
  ) => Promise<UserCredential>;
  signInWithPopup: (
    provider: firebaseAuth.AuthProvider
  ) => Promise<UserCredential>;
  fetchSignInMethodsForEmail: (email: string) => Promise<string[]>;
  linkWithCredential: (
    user: firebaseAuth.User,
    credential: firebaseAuth.AuthCredential
  ) => Promise<UserCredential>;
  sendPasswordResetEmail: (email: string) => Promise<void>;
  createUserWithEmailAndPassword: (
    email: string,
    password: string
  ) => Promise<UserCredential>;
  updateProfile: (
    user: firebaseAuth.User,
    {
      displayName,
    }: {
      displayName?: string | null;
      photoURL?: string | null;
    }
  ) => Promise<void>;
}

export class FirebaseIdentityClient implements IdentityClient {
  constructor(private readonly app: FirebaseApp) {}

  async signInWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<UserCredential> {
    return firebaseAuth.signInWithEmailAndPassword(
      getAuth(this.app),
      email,
      password
    );
  }

  async signInWithPopup(
    provider: firebaseAuth.AuthProvider
  ): Promise<UserCredential> {
    return firebaseAuth.signInWithPopup(getAuth(this.app), provider);
  }

  async fetchSignInMethodsForEmail(email: string): Promise<string[]> {
    return firebaseAuth.fetchSignInMethodsForEmail(getAuth(this.app), email);
  }

  async linkWithCredential(
    user: firebaseAuth.User,
    credential: firebaseAuth.AuthCredential
  ): Promise<UserCredential> {
    return firebaseAuth.linkWithCredential(user, credential);
  }

  async sendPasswordResetEmail(email: string): Promise<void> {
    return firebaseAuth.sendPasswordResetEmail(getAuth(this.app), email);
  }

  async createUserWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<UserCredential> {
    return firebaseAuth.createUserWithEmailAndPassword(
      getAuth(this.app),
      email,
      password
    );
  }

  async updateProfile(
    user: firebaseAuth.User,
    {
      displayName,
    }: {
      displayName?: string | null;
      photoURL?: string | null;
    }
  ): Promise<void> {
    firebaseAuth.updateProfile(user, { displayName });
  }
}

export const firebaseIdentityClientFactory = (): IdentityClient =>
  new FirebaseIdentityClient(initializeApp(app));
