import { api } from '../clients/http.client';
import { appConfig } from '../infra/config';

export const schedulingServiceFactory = () => new SchedulingService();

export type Appointment = {
  id: string;
  eventDate: string;
  eventTime: string;
  startTime: string;
  endTime: string;
  status: 'created' | 'complete' | 'cancelled' | 'pending';
  statusNote: string;
  company: {
    id: string;
    cnpj: string;
    fantasyName: string;
    contactEmail: string;
  };
  professional: {
    id: string;
    serviceInterval: string;
    companyId: string;
    user: {
      id: string;
      name: string;
      email: string;
      phoneNumber: string;
      avatar: string;
    };
  };
  service: {
    id: string;
    name: string;
    price: number;
    durationTime: string;
    companyId: string;
  };
  customer: {
    id: string;
    name: string;
    email: string;
    avatar: string;
  };
};

export type SchedulingServiceParams = {
  companyId: string;
  status?: 'CREATED' | 'PENDING';
  startDate?: string;
  endDate?: string;
};
export class SchedulingService {
  async getScheduling(params: SchedulingServiceParams): Promise<Appointment[]> {
    let queryParams: any = {
      companyId: params.companyId,
    };

    if (params.status) queryParams = { ...queryParams, status: params.status };

    if (params.startDate)
      queryParams = { ...queryParams, startDate: params.startDate };

    if (params.endDate)
      queryParams = { ...queryParams, endDate: params.endDate };

    const { data } = await api<Appointment[]>({
      method: 'GET',
      baseURL: appConfig.apiBaseUrl,
      url: 'api/scheduling',
      params: { ...queryParams },
    });

    return data;
  }

  async updateStatus(params: {
    scheduleId: string;
    status: 'complete' | 'cancel';
    statusDescription: string;
    userAuthEmail: string;
  }) {
    return api({
      method: 'PATCH',
      baseURL: appConfig.apiBaseUrl,
      url: `api/scheduling/${params.scheduleId}/${params.status}`,
      data: {
        note: params.statusDescription,
        userAuthEmail: params.userAuthEmail,
      },
    });
  }
}
