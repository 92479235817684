import { Box, Container, Typography, useTheme } from '@mui/material';
import { ReactNode } from 'react';

interface RegisterCompanyLayoutProps {
  title: string;
  children: ReactNode;
}

export function RegisterCompanyLayout(props: RegisterCompanyLayoutProps) {
  const { children, title } = props;

  const theme = useTheme();

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Container maxWidth={'sm'}>
        <Box
          display={'flex'}
          flexDirection={'column'}
          gap={5}
          marginTop={theme.spacing(1)}
        >
          <Typography variant={'h4'} fontWeight={'bold'} textAlign={'center'}>
            {title}
          </Typography>
          {children}
        </Box>
      </Container>
    </Box>
  );
}
