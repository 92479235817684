import CenteredCircularProgress from '../../../components/centered-circular-progress/CenteredCircularProgress';
import LocationCompanyForm from '../../../components/location-company/components/location-company-form/LocationCompanyForm';
import { useLocationCompany } from '../../../components/location-company/hooks';
import { StepItemContentNavigationButtons } from './StepItemContentNavigationButtons';

interface LocationStepProps {
  currentIndex: number;
  stepsLength: number;
  handleNext: () => void;
  handleBack: () => void;
}

export function LocationStep(props: LocationStepProps) {
  const { currentIndex, stepsLength, handleNext, handleBack } = props;
  const { loading, formLocation, update } = useLocationCompany();

  return (
    <>
      <CenteredCircularProgress show={loading} />

      {formLocation && (
        <LocationCompanyForm
          formData={formLocation}
          handleSubmitButton={async (data) => {
            const { isError } = await update(data);

            if (!isError) handleNext();
          }}
          buttonChildren={
            <StepItemContentNavigationButtons
              nextButtonText={
                currentIndex === stepsLength - 1 ? 'Finalizar' : 'Avançar'
              }
              nextButtonDisable={false}
              backButtonText="Voltar"
              backButtonDisable={currentIndex === 0}
              handleBack={handleBack}
            />
          }
        />
      )}
    </>
  );
}
