import { Box, Button, Chip, Typography } from '@mui/material';
import { useBreakpoint } from '../../../../hooks';
import { formatDate } from '../../../../helpers/format-utils';
import { useEffect, useState } from 'react';
import { NonWorkingDatesFormModal } from './non-working-dates-form-modal/NonWorkingDatesFormModal';

export type CreateNonWorkingDate = {
  date: string;
};

export type UpdateNonWorkingDates = {
  updatedDates: string[];
};

interface NonWorkingDatesProps {
  data: string[];
  disabledSubmitButtonNonWorkingDatesForm?: boolean;
  disabledSaveSubmitButton?: boolean;
  handleCreateNonWorkingDate: (data: CreateNonWorkingDate) => void;
  handleUpdateNonWorkingDates: (data: UpdateNonWorkingDates) => void;
}

export default function NonWorkingDates(props: NonWorkingDatesProps) {
  const { desktop } = useBreakpoint();
  const [dateList, setDateList] = useState<string[]>(props.data);
  const [openNonWorkingDatesFormModal, setOpenNonWorkingDatesFormModal] =
    useState(false);

  const showSaveOrUpdateButtons = () => {
    return dateList.length !== props.data.length;
  };

  useEffect(() => {
    setDateList(props.data);
  }, [props.data]);

  return (
    <>
      <NonWorkingDatesFormModal
        open={openNonWorkingDatesFormModal}
        disabledSubmitButton={props.disabledSubmitButtonNonWorkingDatesForm}
        handleClose={() => setOpenNonWorkingDatesFormModal(false)}
        handleSubmitNonWorkingDatesForm={(data) => {
          props.handleCreateNonWorkingDate(data);
          setOpenNonWorkingDatesFormModal(false);
        }}
      />
      <Box display="flex" flexDirection={'column'} gap={2}>
        <Typography>Datas para Não Atendimento</Typography>
        <Box display={'flex'} flexWrap={'wrap'}>
          {dateList.map((item, index) => (
            <Chip
              key={index}
              label={formatDate(item)}
              onDelete={() => {
                const newDateList = [...dateList];
                newDateList.splice(index, 1);
                setDateList(newDateList);
              }}
            />
          ))}
        </Box>

        {showSaveOrUpdateButtons() ? (
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'right'}
            gap={2}
          >
            <Button
              variant="contained"
              fullWidth={!desktop}
              disabled={props.disabledSaveSubmitButton}
              onClick={() =>
                props.handleUpdateNonWorkingDates({ updatedDates: dateList })
              }
            >
              Salvar
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              fullWidth={desktop ? false : true}
              onClick={() => {
                setDateList(props.data);
              }}
            >
              Cancelar
            </Button>
          </Box>
        ) : (
          <Button
            variant="outlined"
            color="secondary"
            fullWidth={!desktop}
            onClick={() => setOpenNonWorkingDatesFormModal(true)}
          >
            Adicionar
          </Button>
        )}
      </Box>
    </>
  );
}
