import { CloudUpload } from '@mui/icons-material';
import { Box, Button, Typography, styled } from '@mui/material';
import { useFileStorage } from '../../pages/settings-page/hooks';
import LinearProgressWithLabel from '../linear-progress-with-label/LinearProgressWithLabel';

const Upload = (props: { handleChange: (file: File) => void }) => {
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });
  const { handleChange } = props;

  return (
    <>
      <Box
        display={'flex'}
        justifyContent={'center'}
        flexDirection={'column'}
        height={'100%'}
      >
        <Button component="label" variant="text" startIcon={<CloudUpload />}>
          Enviar Imagem
          <VisuallyHiddenInput
            accept="image/*"
            type="file"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (!e.target.files || e.target.files?.length == 0) return;

              const file = e.target.files[0];

              handleChange(file);
            }}
          />
        </Button>
      </Box>
    </>
  );
};

export const CustomUpload = (props: {
  handleUploadComplted: (downloadURL: string) => void;
}) => {
  const { handleUploadComplted } = props;
  const {
    progress,
    minSizeInKb,
    maxSizeInKb,
    handleUpload,
    validateImage,
    convertToSizeLiteral,
  } = useFileStorage(handleUploadComplted);

  return (
    <>
      {progress > 0 && <LinearProgressWithLabel value={progress} />}

      {progress === 0 && (
        <>
          <Upload
            handleChange={async (file) => {
              const valid = await validateImage(file);

              if (valid) {
                handleUpload(file);
              }
            }}
          />
          <Box textAlign={'center'}>
            <Typography variant="caption" textAlign={'center'}>
              tamanho mínimo: {convertToSizeLiteral(minSizeInKb)}; tamanho
              máximo: {convertToSizeLiteral(maxSizeInKb)}
            </Typography>
          </Box>
        </>
      )}
    </>
  );
};
