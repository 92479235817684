import { FirebaseApp, FirebaseOptions, initializeApp } from 'firebase/app';
import { Analytics, getAnalytics } from 'firebase/analytics';
import { getStorage, FirebaseStorage } from 'firebase/storage';

import { appConfig } from '.';

export const app: FirebaseOptions = {
  apiKey: appConfig.firebaseConfigApiKey,
  appId: appConfig.firebaseConfigAppId,
  authDomain: appConfig.firebaseConfigAuthDomain,
  measurementId: appConfig.firebaseConfigMeasurementId,
  messagingSenderId: appConfig.firebaseConfigMessagingSenderId,
  projectId: appConfig.firebaseConfigProjectId,
  storageBucket: appConfig.firebaseConfigStorageBucket,
};

export let firebaseApp: FirebaseApp;
export let analytics: Analytics;
export let storage: FirebaseStorage;

export const firebaseInitializeApp = () => {
  firebaseApp = initializeApp(app);
  analytics = getAnalytics(firebaseApp);
  storage = getStorage(firebaseApp);
};
