import { Box, Button } from '@mui/material';

interface StepItemContentNavigationButtonsProps {
  nextButtonText: string;
  backButtonText: string;
  nextButtonDisable: boolean;
  backButtonDisable: boolean;
  handleBack: () => void;
  handleNext?: () => void;
}

export function StepItemContentNavigationButtons(
  props: StepItemContentNavigationButtonsProps
) {
  const {
    nextButtonText,
    backButtonText,
    nextButtonDisable,
    backButtonDisable,
    handleBack,
    handleNext,
  } = props;

  return (
    <Box sx={{ mb: 2 }}>
      <div>
        <Button
          variant="contained"
          disabled={nextButtonDisable}
          sx={{ mt: 1, mr: 1 }}
          type="submit"
          onClick={handleNext}
        >
          {nextButtonText}
        </Button>
        <Button
          disabled={backButtonDisable}
          sx={{ mt: 1, mr: 1 }}
          onClick={handleBack}
        >
          {backButtonText}
        </Button>
      </div>
    </Box>
  );
}
