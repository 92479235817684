import { Box, Typography } from '@mui/material';
import { ReactNode } from 'react';

interface StepLayoutProps {
  title: string;
  children: ReactNode;
}

export function StepLayout(props: StepLayoutProps) {
  const { children, title } = props;

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2}>
      <Typography variant="body2">{title}</Typography>

      <Box>{children}</Box>
    </Box>
  );
}
