import { Box, List } from '@mui/material';
import CenteredCircularProgress from '../../../components/centered-circular-progress/CenteredCircularProgress';
import { RightSideModal } from '../../../components/right-side-modal/RightSideModal';
import ServiceCompanyForm from '../../../components/service-company/components/service-company-form/ServiceCompanyForm';
import { useServiceCompany } from '../../../components/service-company/hooks';
import { StepItemContentNavigationButtons } from './StepItemContentNavigationButtons';
import { AddServiceButton } from '../../../components/service-company/components/add-service-button/AddServiceButton';
import { ServiceListItem } from '../../../components/service-company/components/service-list-item/ServiceListItem';
import {
  formatCurrency,
  formatHHMMToMinutesInLiteral,
} from '../../../helpers/data-formatting';
import { useSnackbar } from '../../../hooks/useSnackbar';
import alertMessage from '../../../helpers/alert-message';

interface ServiceStepProps {
  currentIndex: number;
  stepsLength: number;
  handleNext: () => void;
  handleBack: () => void;
}

export function ServiceStep(props: ServiceStepProps) {
  const { currentIndex, stepsLength, handleNext, handleBack } = props;
  const {
    loading,
    services,
    openModal,
    modalTitle,
    serviceSelected,
    handleAddButton,
    handleCloseServiceCompanyFormModal,
    handleListItemClick,
    saveOrUpdate,
    remove,
  } = useServiceCompany();
  const { openSnackbar } = useSnackbar();

  return (
    <>
      <CenteredCircularProgress show={loading} />

      <RightSideModal
        title={modalTitle}
        open={openModal}
        handleClose={handleCloseServiceCompanyFormModal}
      >
        <ServiceCompanyForm
          formData={serviceSelected}
          handleSubmitButton={saveOrUpdate}
          handleRemoveButton={remove}
        />
      </RightSideModal>

      <Box display={'flex'} flexDirection={'column'} gap={2}>
        <AddServiceButton handleClick={handleAddButton} />
        <Box
          sx={{
            width: '100%',
            bgcolor: 'background.paper',
          }}
        >
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {services.map((service, index) => (
              <ServiceListItem
                key={index}
                name={service.name}
                price={formatCurrency(service.price)}
                durationTime={formatHHMMToMinutesInLiteral(
                  service.durationTime
                )}
                onClick={() => handleListItemClick(service)}
              />
            ))}
          </List>
        </Box>
      </Box>

      <StepItemContentNavigationButtons
        nextButtonText={
          currentIndex === stepsLength - 1 ? 'Finalizar' : 'Avançar'
        }
        nextButtonDisable={false}
        backButtonText="Voltar"
        backButtonDisable={currentIndex === 0}
        handleBack={handleBack}
        handleNext={() => {
          if (services.length > 0) {
            handleNext();
          } else {
            openSnackbar(alertMessage.servicesStepEqualToZero, 'info');
          }
        }}
      />
    </>
  );
}
