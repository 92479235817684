import { logEvent } from 'firebase/analytics';
import { analytics } from '../infra/config/firebase';
import { EventAnalytics } from '../helpers/constants';
import { LocalStorage } from '../helpers/storage';
import { appConfig } from '../infra/config';

const prefix = 'admin';

export const useAnalytics = () => {
  const logPageView = (page: string) => {
    const companyID = LocalStorage.getValue(appConfig.keyCompanyIdLocalStorage);
    logEvent(analytics, `${prefix}_${EventAnalytics.pageView}`, {
      page_name: page,
      company_id: companyID,
    });
  };

  const logError = (eventName: string, errorMessage: string) => {
    const companyID = LocalStorage.getValue(appConfig.keyCompanyIdLocalStorage);
    logEvent(analytics, `${prefix}_error_${eventName}`, {
      company_id: companyID,
      message: errorMessage,
    });
  };

  const logGenericEvent = (eventName: string) => {
    const companyID = LocalStorage.getValue(appConfig.keyCompanyIdLocalStorage);
    logEvent(analytics, `${prefix}_${eventName}`, {
      company_id: companyID,
    });
  };

  return {
    logPageView,
    logError,
    logGenericEvent,
  };
};
