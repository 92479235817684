import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ButtonBase,
  Divider,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useBreakpoint } from '../../../../hooks';
import { DailyOperatingHoursFormModal } from './daily-operating-hours-form-modal/DailyOperatingHoursFormModal';
import { FormDailyOperatingHours } from './daily-operating-hours-form-modal/daily-operating-hours-form/DailyOperatingHoursForm';
import { CloseIntervalsFormModal } from './closed-intervals-form-modal/ClosedIntervalsFormModal';
import { FormCloseIntervals } from './closed-intervals-form-modal/closed-intervals-form/ClosedIntervalsForm';

export type Weekday =
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday';

export type UpdateDailyOperatingHours = {
  weekday: Weekday;
  closed: boolean;
  startTime: string;
  endTime: string;
};

export type CreateClosedIntervalsDailyOperatingHours = {
  weekday: Weekday;
  startTime: string;
  endTime: string;
  reason: string;
};

export type UpdateClosedIntervalsDailyOperatingHours = {
  index: number;
  weekday: Weekday;
  startTime: string;
  endTime: string;
  reason: string;
};

export type DeleteClosedIntervalsDailyOperatingHours = {
  index: number;
  weekday: Weekday;
};

type BlockedTimeSlots = {
  startTime: string;
  endTime: string;
  reason: string;
};

export type DailyOperatingHoursData = {
  weekdaykey: Weekday;
  description: string;
  startTime: string;
  endTime: string;
  closed: boolean;
  blockedTimeSlots: BlockedTimeSlots[];
};

interface DailyOperatingHoursProps {
  data: DailyOperatingHoursData[];
  disabledSubmitButtonDailyOperatingHoursForm?: boolean;
  disabledSubmitButtonCloseIntervalsForm?: boolean;
  handleUpdateDailyOperatingHours: (data: UpdateDailyOperatingHours) => void;
  handleCreateClosedIntervalsDailyOperatingHours: (
    data: CreateClosedIntervalsDailyOperatingHours
  ) => void;
  handleUpdateClosedIntervalDailyOperatingHours: (
    data: UpdateClosedIntervalsDailyOperatingHours
  ) => void;
  handleDeleteClosedIntervalDailyOperatingHours: (
    data: DeleteClosedIntervalsDailyOperatingHours
  ) => void;
}

export default function DailyOperatingHours(props: DailyOperatingHoursProps) {
  const [expanded, setExpanded] = useState<string | false>(false);
  const { desktop } = useBreakpoint();
  const [
    openDailyOperatingHoursFormModal,
    setOpenDailyOperatingHoursFormModal,
  ] = useState(false);
  const [openCloseIntervalsFormModal, setOpenCloseIntervalsFormModal] =
    useState(false);
  const [
    editFormDataDailyOperatingHoursFormModal,
    setEditFormDataDailyOperatingHoursFormModal,
  ] = useState<FormDailyOperatingHours | undefined>();
  const [formDataCloseIntervalsFormModal, setFormDataCloseIntervalsFormModal] =
    useState<({ index: number } & FormCloseIntervals) | undefined>();
  const [
    isEditingFormDataCloseIntervalsFormModal,
    setEditingFormDataCloseIntervalsFormModal,
  ] = useState(false);

  const handleChange =
    (panel: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <>
      <Box display="flex" flexDirection={'column'} gap={2}>
        <Typography>Horário de Funcionamento Diário</Typography>
        <Box>
          <DailyOperatingHoursFormModal
            open={openDailyOperatingHoursFormModal}
            formData={editFormDataDailyOperatingHoursFormModal}
            disabledSubmitButton={
              props.disabledSubmitButtonDailyOperatingHoursForm
            }
            handleClose={() => setOpenDailyOperatingHoursFormModal(false)}
            handleSubmitForm={(data) => {
              props.handleUpdateDailyOperatingHours({
                weekday: data.id,
                startTime: data.openTime,
                endTime: data.endTime,
                closed: !data.dayToggle,
              });
              setOpenDailyOperatingHoursFormModal(false);
            }}
          />
          <CloseIntervalsFormModal
            open={openCloseIntervalsFormModal}
            formData={formDataCloseIntervalsFormModal}
            isEditingForm={isEditingFormDataCloseIntervalsFormModal}
            disabledSubmitButton={
              props.disabledSubmitButtonDailyOperatingHoursForm
            }
            handleClose={() => setOpenCloseIntervalsFormModal(false)}
            handleSubmitForm={(data) => {
              if (
                isEditingFormDataCloseIntervalsFormModal &&
                formDataCloseIntervalsFormModal
              ) {
                props.handleUpdateClosedIntervalDailyOperatingHours({
                  index: formDataCloseIntervalsFormModal?.index,
                  startTime: data.openTime,
                  endTime: data.endTime,
                  reason: data.reason,
                  weekday: data.id,
                });
              } else {
                props.handleCreateClosedIntervalsDailyOperatingHours({
                  weekday: editFormDataDailyOperatingHoursFormModal?.id,
                  startTime: data.openTime,
                  endTime: data.endTime,
                  reason: data.reason,
                });
              }
              setOpenCloseIntervalsFormModal(false);
            }}
            handleDelete={() => {
              if (formDataCloseIntervalsFormModal) {
                props.handleDeleteClosedIntervalDailyOperatingHours({
                  index: formDataCloseIntervalsFormModal.index,
                  weekday: formDataCloseIntervalsFormModal.id,
                });
                setOpenCloseIntervalsFormModal(false);
              }
            }}
          />
          {props.data.map((item) => (
            <Accordion
              key={item.weekdaykey}
              expanded={expanded === item.description}
              onChange={handleChange(item.description)}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                  {item.description}
                </Typography>
                {!(expanded === item.description) && (
                  <>
                    {item.closed ? (
                      <Typography color={'secondary'} variant="body2">
                        Fechado
                      </Typography>
                    ) : (
                      <Typography color={'secondary'} variant="body2">
                        {item.startTime} - {item.endTime}
                      </Typography>
                    )}
                  </>
                )}
              </AccordionSummary>
              <AccordionDetails>
                <Box display={'flex'} flexDirection={'column'} gap={2}>
                  {item.closed ? (
                    <Typography variant="body2">
                      Estabelecimento fechado
                    </Typography>
                  ) : (
                    <Typography variant="body2">
                      {item.startTime} - {item.endTime}
                    </Typography>
                  )}
                  <Box display={'flex'} flexDirection={'row'}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      fullWidth={!desktop}
                      onClick={() => {
                        setEditFormDataDailyOperatingHoursFormModal({
                          id: item.weekdaykey,
                          dayToggle: !item.closed,
                          openTime: item.startTime,
                          endTime: item.endTime,
                        });
                        setOpenDailyOperatingHoursFormModal(true);
                      }}
                    >
                      Editar
                    </Button>
                  </Box>
                  {!item.closed && (
                    <>
                      <Divider />
                      <Typography variant="body2">Janelas fechadas</Typography>
                      {item.blockedTimeSlots.length === 0 ? (
                        <Typography variant="body2">Nenhuma</Typography>
                      ) : (
                        <Box display={'flex'} flexDirection={'column'} gap={2}>
                          {item.blockedTimeSlots.map(
                            (blockedTimeItem, index) => (
                              <ButtonBase
                                key={index}
                                onClick={() => {
                                  setFormDataCloseIntervalsFormModal({
                                    index,
                                    id: item.weekdaykey,
                                    openTime: blockedTimeItem.startTime,
                                    endTime: blockedTimeItem.endTime,
                                    reason: blockedTimeItem.reason,
                                  });
                                  setEditingFormDataCloseIntervalsFormModal(
                                    true
                                  );
                                  setOpenCloseIntervalsFormModal(true);
                                }}
                              >
                                <Box
                                  display={'flex'}
                                  flexDirection={'column'}
                                  width={'100%'}
                                  padding={1}
                                  alignItems={'flex-start'}
                                  sx={{
                                    '&:hover': {
                                      backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                    },
                                  }}
                                >
                                  <Typography variant="body2">
                                    {blockedTimeItem.startTime} -{' '}
                                    {blockedTimeItem.endTime}
                                  </Typography>
                                  <Box
                                    display={'flex'}
                                    flexDirection={'row'}
                                    gap={1}
                                  >
                                    <Typography variant="caption">
                                      motivo:
                                    </Typography>
                                    <Typography variant="body2">
                                      {blockedTimeItem.reason}
                                    </Typography>
                                  </Box>
                                </Box>
                              </ButtonBase>
                            )
                          )}
                        </Box>
                      )}
                      <Box display={'flex'} flexDirection={'row'}>
                        <Button
                          variant="outlined"
                          color="secondary"
                          fullWidth={!desktop}
                          onClick={() => {
                            setEditFormDataDailyOperatingHoursFormModal({
                              id: item.weekdaykey,
                              dayToggle: !item.closed,
                              openTime: item.startTime,
                              endTime: item.endTime,
                            });
                            setFormDataCloseIntervalsFormModal(undefined);
                            setOpenCloseIntervalsFormModal(true);
                            setEditingFormDataCloseIntervalsFormModal(false);
                          }}
                        >
                          Adicionar Intervalos Fechados
                        </Button>
                      </Box>
                    </>
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Box>
    </>
  );
}
