import { Box, Card, CardContent } from '@mui/material';
import { useOperatingHourCompany } from './hooks';
import CenteredCircularProgress from '../centered-circular-progress/CenteredCircularProgress';
import DailyOperatingHours from './components/daily-operating-hours/DailyOperatingHours';
import NonWorkingIntervalsByDate from './components/non-working-intervals-by-date/NonWorkingIntervalsByDate';
import NonWorkingDates from './components/non-working-dates/NonWorkingDates';

export default function OperatingHourCompany() {
  const {
    loading,
    dailyOperatingHourData,
    nonWorkingIntervalByDateData,
    nonWorkingDatesData,
    updateDailyOperatingHours,
    createClosedIntervalsDailyOperatingHours,
    updateClosedIntervalDailyOperatingHours,
    deleteClosedIntervalDailyOperatingHours,
    createNonWorkingIntervalsByDateForm,
    updateNonWorkingIntervalsByDateForm,
    removeNonWorkingIntervalsByDateForm,
    createNonWorkingDate,
    updateNonWorkingDates,
  } = useOperatingHourCompany();

  return (
    <>
      <CenteredCircularProgress show={loading} />

      <Box display={'flex'} flexDirection={'column'} gap={2}>
        <Card>
          <CardContent>
            <Box display={'flex'} flexDirection={'column'} gap={2}>
              <DailyOperatingHours
                data={dailyOperatingHourData}
                disabledSubmitButtonDailyOperatingHoursForm={false}
                disabledSubmitButtonCloseIntervalsForm={false}
                handleUpdateDailyOperatingHours={updateDailyOperatingHours}
                handleCreateClosedIntervalsDailyOperatingHours={
                  createClosedIntervalsDailyOperatingHours
                }
                handleUpdateClosedIntervalDailyOperatingHours={
                  updateClosedIntervalDailyOperatingHours
                }
                handleDeleteClosedIntervalDailyOperatingHours={
                  deleteClosedIntervalDailyOperatingHours
                }
              />
            </Box>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <NonWorkingIntervalsByDate
              data={nonWorkingIntervalByDateData}
              handleCreateNonWorkingIntervalsByDateForm={
                createNonWorkingIntervalsByDateForm
              }
              handleUpdateNonWorkingIntervalsByDateForm={
                updateNonWorkingIntervalsByDateForm
              }
              handleRemoveNonWorkingIntervalsByDateForm={
                removeNonWorkingIntervalsByDateForm
              }
            />
          </CardContent>
        </Card>
        {
          <Card>
            <CardContent>
              <NonWorkingDates
                data={nonWorkingDatesData}
                handleCreateNonWorkingDate={createNonWorkingDate}
                handleUpdateNonWorkingDates={updateNonWorkingDates}
              />
            </CardContent>
          </Card>
        }
      </Box>
    </>
  );
}
