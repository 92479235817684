import { useState } from 'react';

interface Step {
  label: 'Geral' | 'Localização' | 'Serviços' | 'Horários' | 'Funcionários';
  description: string;
  valid: boolean;
}

interface UseStepperProps {
  handleFinishStep: () => void;
}

export const useStepper = (props: UseStepperProps) => {
  const { handleFinishStep } = props;

  const [activeStep, setActiveStep] = useState(0);

  const steps: Array<Step> = [
    {
      label: 'Geral',
      description: '',
      valid: true,
    },
    {
      label: 'Localização',
      description: '',
      valid: false,
    },
    {
      label: 'Serviços',
      description: '',
      valid: false,
    },
    {
      label: 'Horários',
      description: '',
      valid: false,
    },
    {
      label: 'Funcionários',
      description: '',
      valid: false,
    },
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

    const finish = activeStep === steps.length - 1;

    if (finish) {
      handleFinishStep();
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return {
    activeStep,
    steps,
    handleNext,
    handleBack,
  };
};
