import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { addDays } from 'date-fns';
import dayjs from 'dayjs';
import { Refresh } from '@mui/icons-material';
import { UserContext } from '../../contexts/UserContext';
import { useBreakpoint } from '../../hooks';
import {
  Appointment,
  schedulingServiceFactory,
  SchedulingServiceParams,
} from '../../services/scheduling.service';
import FilterWithAccordion from '../../components/side-menu/share/components/filter-with-accordion/FilterWithAccordion';
import {
  ItemTableSchedule,
  TableSchedules,
} from '../../components/side-menu/share/components/table-schedules/TableSchedules';
import {
  ClickCloseProps,
  SchedulingDetailsModal,
} from './scheduling-details-modal/SchedulingDetailsModal';
import CenteredCircularProgress from '../../components/centered-circular-progress/CenteredCircularProgress';
import NonEmptyScreen from '../../components/non-empty-screen/NonEmptyScreen';
import { useAnalytics } from '../../hooks/useAnalytics';
import { PageNameAnalytics } from '../../helpers/constants';
import { useSnackbar } from '../../hooks/useSnackbar';
import alertMessage from '../../helpers/alert-message';

type Filter = {
  startDate?: string;
  endDate?: string;
};

export default function Scheduling() {
  const breakpoints = useBreakpoint();
  const [customFilterDescription, setCustomFilterDescription] = useState<
    'TODAY' | 'NEXT_SEVEN' | 'NEXT_FIFTEEN' | 'NEXT_THIRTY' | 'ALL'
  >('TODAY');
  const schedulingService = schedulingServiceFactory();
  const [filter, setFilter] = useState<Filter>({
    startDate: dayjs().format('YYYY-MM-DD'),
    endDate: dayjs().format('YYYY-MM-DD'),
  });
  const [schedules, setSchedules] = useState<Appointment[]>([]);
  const [scheduleSelected, setScheduleSelected] = useState<ItemTableSchedule>();
  const [openModalDetailsSchedule, setOpenModalDetailsSchedule] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const { logPageView } = useAnalytics();
  const { openSnackbar } = useSnackbar();

  useEffect(() => {
    logPageView(PageNameAnalytics.pageSchedule);
  }, []);

  const getSchedules = async () => {
    try {
      setLoading(true);

      const companyId = UserContext.companyId();
      if (companyId) {
        let query: SchedulingServiceParams = { companyId };

        if (filter.startDate) {
          query = {
            ...query,
            startDate: filter.startDate,
          };
        }

        if (filter.endDate) {
          query = {
            ...query,
            endDate: filter.endDate,
          };
        }
        const appointments = await schedulingService.getScheduling(query);
        setSchedules(appointments);
      }
    } catch (err) {
      openSnackbar(alertMessage.unknownErrorMessage, 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleToday = () => {
    const startDate = dayjs().format('YYYY-MM-DD');
    const endDate = dayjs().format('YYYY-MM-DD');
    setCustomFilterDescription('TODAY');
    setFilter({ startDate, endDate });
  };

  const handleNextSevenDays = () => {
    const startDate = dayjs().format('YYYY-MM-DD');
    const date = addDays(new Date(), 7);
    const endDate = dayjs(date).format('YYYY-MM-DD');
    setCustomFilterDescription('NEXT_SEVEN');
    setFilter({ startDate, endDate });
  };

  const handleNextFifteenDays = () => {
    const startDate = dayjs().format('YYYY-MM-DD');
    const date = addDays(new Date(), 15);
    const endDate = dayjs(date).format('YYYY-MM-DD');
    setCustomFilterDescription('NEXT_FIFTEEN');
    setFilter({ startDate, endDate });
  };

  const handleNextThirtyDays = () => {
    const startDate = dayjs().format('YYYY-MM-DD');
    const date = addDays(new Date(), 30);
    const endDate = dayjs(date).format('YYYY-MM-DD');
    setCustomFilterDescription('NEXT_THIRTY');
    setFilter({ startDate, endDate });
  };

  const handleItemTableSchedule = (data: ItemTableSchedule) => {
    setScheduleSelected(data);
    setOpenModalDetailsSchedule(true);
  };

  const handleCloseModalDetailsSchedule = (data?: ClickCloseProps) => {
    setOpenModalDetailsSchedule(false);
    if (data?.recordModified) {
      getSchedules();
    }
  };

  const handleRefreshButton = () => {
    getSchedules();
  };

  useEffect(() => {
    getSchedules();
  }, [filter]);

  return (
    <>
      {scheduleSelected && (
        <SchedulingDetailsModal
          open={openModalDetailsSchedule}
          scheduling={scheduleSelected}
          clickClose={(data) => handleCloseModalDetailsSchedule(data)}
        />
      )}
      <CenteredCircularProgress show={loading} />
      <Card>
        <CardContent>
          <Box display={'flex'} flexDirection={'column'} gap={2}>
            <FilterWithAccordion>
              <Box
                display={'flex'}
                flexDirection={breakpoints.desktop ? 'row' : 'column'}
                gap={2}
              >
                <Button onClick={handleToday} variant="text" size="small">
                  Hoje
                </Button>
                <Button
                  onClick={handleNextSevenDays}
                  variant="text"
                  size="small"
                >
                  Próximos 7 dias
                </Button>
                <Button
                  onClick={handleNextFifteenDays}
                  variant="text"
                  size="small"
                >
                  Próximos 15 dias
                </Button>
                <Button
                  onClick={handleNextThirtyDays}
                  variant="text"
                  size="small"
                >
                  Próximos 30 dias
                </Button>
              </Box>
            </FilterWithAccordion>

            <Box>
              <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
              >
                <Box>
                  {customFilterDescription === 'TODAY' && (
                    <Typography fontSize={11}>Resultados de hoje</Typography>
                  )}
                  {customFilterDescription === 'ALL' && (
                    <Typography fontSize={11}>Todos os agendamentos</Typography>
                  )}
                  {customFilterDescription === 'NEXT_SEVEN' && (
                    <Typography fontSize={11}>
                      Resultados dos próximos 7 dias
                    </Typography>
                  )}
                  {customFilterDescription === 'NEXT_FIFTEEN' && (
                    <Typography fontSize={11}>
                      Resultados dos próximos 15 dias
                    </Typography>
                  )}
                  {customFilterDescription === 'NEXT_THIRTY' && (
                    <Typography fontSize={11}>
                      Resultados dos próximos 30 dias
                    </Typography>
                  )}
                </Box>
                <Button color="primary" onClick={handleRefreshButton}>
                  <Refresh />
                  Recarregar
                </Button>
              </Box>
              {!loading && schedules.length === 0 && (
                <NonEmptyScreen text="Não há agendamentos disponíveis para o filtro selecionado." />
              )}
              {!loading && schedules.length > 0 && (
                <TableSchedules
                  key={'TableSchedules'}
                  schedules={schedules.map((item) => ({
                    id: item.id,
                    eventDate: item.eventDate,
                    eventTime: item.eventTime,
                    serviceName: item.service.name,
                    serviceAmount: item.service.price,
                    status: item.status,
                    statusDescription: item.statusNote,
                    companyName: item.company.fantasyName,
                    customerName: item.customer.name,
                    professionalName: item.professional.user.name,
                    eventStartTime: item.startTime,
                    eventEndTime: item.endTime,
                  }))}
                  onClick={(data) => handleItemTableSchedule(data)}
                />
              )}
            </Box>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}
