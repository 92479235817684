import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
} from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useBreakpoint } from '../../../../../../hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import 'dayjs/locale/pt-br';
import { formatTime } from '../../../../../../helpers/data-formatting';
import { useAnalytics } from '../../../../../../hooks/useAnalytics';
import { PageNameAnalytics } from '../../../../../../helpers/constants';

export type FormDailyOperatingHours = {
  id?: any;
  openTime: string;
  endTime: string;
  dayToggle: boolean;
};

interface DailyOperatingHoursFormProps {
  formData?: FormDailyOperatingHours;
  disabledSubmitButton?: boolean;
  handleSubmit: (data: FormDailyOperatingHours) => void;
}

export default function DailyOperatingHoursForm(
  props: DailyOperatingHoursFormProps
) {
  const schemaFormDailyOperatingHours = yup.object().shape({
    dayToggle: yup.boolean(),
    openTime: yup
      .string()
      .matches(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, 'horário inválido')
      .required('campo obrigatório'),
    endTime: yup
      .string()
      .matches(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, 'horário inválido')
      .required('campo obrigatório'),
  });
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormDailyOperatingHours>({
    resolver: yupResolver(schemaFormDailyOperatingHours),
  });
  const { desktop } = useBreakpoint();
  const [dayToggle, setDayToggle] = useState(false);
  const { logPageView } = useAnalytics();

  const handleOpenTimeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const formattedValue = formatTime(value);
    setValue('openTime', formattedValue);
  };

  const handleEndTimeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const formattedValue = formatTime(value);
    setValue('endTime', formattedValue);
  };

  useEffect(() => {
    logPageView(PageNameAnalytics.pageFormDailyOperatingHours);
  }, []);

  useEffect(() => {
    if (props.formData) {
      setValue('openTime', props.formData.openTime);
      setValue('endTime', props.formData.endTime);
      setValue('dayToggle', props.formData.dayToggle);
      setDayToggle(props.formData.dayToggle);
    }
  }, [props.formData]);

  return (
    <>
      <form
        onSubmit={handleSubmit((data) => {
          props.handleSubmit({
            ...data,
            id: props.formData?.id,
          });
        })}
      >
        <Box display={'flex'} flexDirection={'column'} gap={4} padding={2}>
          <Box display={'flex'} flexDirection={'column'} gap={1}>
            <FormControl component="fieldset" variant="standard">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      {...register('dayToggle')}
                      checked={dayToggle}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setDayToggle(e.target.checked)
                      }
                    />
                  }
                  label={
                    dayToggle
                      ? 'Estabelecimento Aberto'
                      : 'Estabelecimento Fechado'
                  }
                />
              </FormGroup>
            </FormControl>
            <TextField
              label="Hora Início"
              inputProps={{
                maxLength: 5,
              }}
              error={!!errors.openTime}
              helperText={errors.openTime?.message}
              {...register('openTime')}
              onChange={handleOpenTimeChange}
            />
            <TextField
              label="Hora Fim"
              inputProps={{
                maxLength: 5,
              }}
              error={!!errors.endTime}
              helperText={errors.endTime?.message}
              {...register('endTime')}
              onChange={handleEndTimeChange}
            />
          </Box>
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'right'}
            gap={2}
          >
            <Button
              type="submit"
              variant="contained"
              fullWidth={desktop ? false : true}
              size="large"
              disabled={props.disabledSubmitButton}
            >
              Atualizar
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
}
