import {
  Box,
  Drawer,
  useTheme,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Icon,
  useMediaQuery,
  Link,
} from '@mui/material';
import { useState } from 'react';
import {
  Outlet,
  useMatch,
  useNavigate,
  useResolvedPath,
} from 'react-router-dom';
import { useAppThemeContext, useDrawerContext } from '../../contexts';
import { RouteConfig } from '../../infra/config/route';
import { LocalStorage } from '../../helpers/storage';

interface ListItemProps {
  to: string;
  icon: string;
  label: string;
  onClick: (() => void) | undefined;
}

function ListItemLink({ to, icon, label, onClick }: ListItemProps) {
  const navigate = useNavigate();
  const resolvedPath = useResolvedPath(to);
  const match = useMatch({ path: resolvedPath.pathname, end: false });

  const handleClick = () => {
    navigate(to);
    onClick?.();
  };

  return (
    <ListItemButton selected={!!match} onClick={handleClick}>
      <ListItemIcon>
        <Icon>{icon}</Icon>
      </ListItemIcon>
      <ListItemText primary={label} />
    </ListItemButton>
  );
}

export function SideMenu() {
  const theme = useTheme();
  const navigate = useNavigate();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const { isDrawerOpen, toggleDrawerOpen, drawerOptions } = useDrawerContext();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { toggleTheme } = useAppThemeContext();
  const [isLogged] = useState(true);

  function handleSignout() {
    LocalStorage.clearAll();
    navigate(RouteConfig.signIn, { replace: true });
  }

  return (
    <>
      <Drawer
        open={isDrawerOpen}
        variant={smDown ? 'temporary' : 'permanent'}
        onClose={toggleDrawerOpen}
      >
        <Box
          width={theme.spacing(28)}
          height="100%"
          display="flex"
          flexDirection="column"
        >
          <br></br>

          <Divider />

          <Box flex={1}>
            <List component="nav">
              {drawerOptions.map((drawerOption) => (
                <ListItemLink
                  key={drawerOption.path}
                  icon={drawerOption.icon}
                  label={drawerOption.label}
                  to={drawerOption.path}
                  onClick={smDown ? toggleDrawerOpen : undefined}
                />
              ))}
            </List>

            <Box display="flex" justifyContent="center" alignItems="center">
              <Link href="#" onClick={handleSignout}>
                {isLogged ? 'Sair' : 'Entrar'}
              </Link>
            </Box>
          </Box>
        </Box>
      </Drawer>

      <Box height="100vh" marginLeft={smDown ? 0 : theme.spacing(28)}>
        <Outlet />
      </Box>
    </>
  );
}
