import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ChangeEvent, useEffect, useState } from 'react';

export type FormStatus = {
  status: string;
  description: string;
};

type StatusAndSchedulingFormDialogProps = {
  open: boolean;
  data: {
    id: any;
    description: string;
  }[];
  handleClose: () => void;
  handleFormSubmit: (data: FormStatus) => void;
};

export function StatusAndSchedulingFormDialog(
  props: StatusAndSchedulingFormDialogProps
) {
  const maxNoteLength = 50;
  const schemaFormStatus = yup.object().shape({
    status: yup.string().required(),
    note: yup.string().max(maxNoteLength),
  });
  const { register, handleSubmit, reset, formState } = useForm<FormStatus>({
    resolver: yupResolver(schemaFormStatus),
  });
  const [note, setNote] = useState('');

  const isUpdateButtonDisabled = () => !formState.isValid;

  const handleNoteChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setNote(value);
  };

  const remainingCharsForNote = () => {
    if (note.length <= maxNoteLength) {
      return maxNoteLength - note.length;
    }
    return 0;
  };

  useEffect(() => {
    reset();
  }, [props.open]);

  return (
    <Dialog open={props.open} onClose={props.handleClose}>
      <DialogTitle>Atualizar Status</DialogTitle>
      <DialogContent>
        <Box display={'flex'} flexDirection={'column'} gap={2}>
          <DialogContentText>
            Utilize este formulário para atualizar o status do agendamento.
          </DialogContentText>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            {props.data.map((item) => (
              <FormControlLabel
                key={item.id}
                value={item.id}
                control={<Radio />}
                label={item.description}
                {...register('status')}
              />
            ))}
          </RadioGroup>
          <Box display={'flex'} flexDirection={'column'} gap={1}>
            <Box display={'flex'}>
              <Typography flex={1}>Detalhes do status</Typography>
            </Box>
            <TextField
              multiline
              inputProps={{ maxLength: maxNoteLength }}
              rows={3}
              helperText={`Você ainda pode digitar mais ${remainingCharsForNote()} caracteres`}
              {...register('description')}
              onChange={handleNoteChange}
            />
            <Typography flex={1}>
              {formState.errors.description?.message ?? ''}
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          disabled={isUpdateButtonDisabled()}
          onClick={handleSubmit(props.handleFormSubmit)}
        >
          Atualizar
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={props.handleClose}
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
