import { api } from '../clients/http.client';
import serviceError from '../helpers/service-error';

export type CreateProfessionalRequest = {
  companyID: any;
  name: string;
  email: string;
  phone?: string;
  avatar?: string;
  serviceInterval?: string;
};

export type UpdateProfessionalRequest = {
  id: any;
  name: string;
  email: string;
  phone?: string;
  serviceInterval?: string;
  avatar: string;
};

type Professional = {
  id: string;
  email: string;
  name: string;
  phoneNumber: string;
  serviceInterval: string;
  avatar: string;
  rating: string;
  services: Array<{ id: any; name: string }>;
};

type UpdateServicesFromProfessionalRequest = {
  professionalID: any;
  companyID: any;
  servicesIDs: Array<string>;
};

export class ProfessionalAlreadyExists extends Error {
  constructor() {
    super();
  }
}

export class ProfessionalNotFound extends Error {
  constructor() {
    super();
  }
}

export const professionalService = {
  async create(request: CreateProfessionalRequest): Promise<{ id: any }> {
    const { requestSuccess, errorData, data } = await api({
      url: 'api/professionals',
      method: 'POST',
      data: {
        serviceInterval: request.serviceInterval,
        email: request.email,
        name: request.name,
        phoneNumber: request.phone,
        avatar: request.avatar,
        companyId: request.companyID,
      },
    });

    if (!requestSuccess) {
      if (
        errorData?.error ===
        serviceError.professionalAlreadyRegisteredForCompany
      ) {
        throw new ProfessionalAlreadyExists();
      }
      throw new Error();
    }

    return data;
  },

  async update(request: UpdateProfessionalRequest): Promise<void> {
    const { requestSuccess, errorData } = await api({
      url: `api/professionals/${request.id}`,
      method: 'PUT',
      data: {
        name: request.name,
        email: request.email,
        phoneNumber: request.phone,
        serviceInterval: request.serviceInterval,
        avatar: request.avatar,
      },
    });

    if (!requestSuccess) {
      if (errorData?.error === serviceError.professionalNotFound) {
        throw new ProfessionalNotFound();
      }
      throw new Error();
    }
  },

  async delete(professionalId: any): Promise<void> {
    const { requestSuccess } = await api({
      url: `api/professionals/${professionalId}`,
      method: 'DELETE',
      data: {},
    });

    if (!requestSuccess) {
      throw new Error();
    }
  },

  async findAll(companyId: any): Promise<Array<Professional>> {
    const { requestSuccess, data } = await api<Array<Professional>>({
      url: `api/professionals?companyId=${companyId}`,
      method: 'GET',
    });

    if (!requestSuccess) {
      throw new Error();
    }

    return data ?? [];
  },

  async updateServicesFromProfessional(
    request: UpdateServicesFromProfessionalRequest
  ): Promise<void> {
    const { requestSuccess } = await api({
      url: `api/professionals/${request.professionalID}/services`,
      method: 'PUT',
      data: {
        companyId: request.companyID,
        servicesIds: request.servicesIDs,
      },
    });

    if (!requestSuccess) {
      throw new Error();
    }
  },
};
