import { StepContent, Typography } from '@mui/material';
import { ReactNode } from 'react';

interface StepItemContentProps {
  description: string;
  children: ReactNode;
}

export function StepItemContent(props: StepItemContentProps) {
  const { description, children } = props;

  return (
    <>
      <StepContent>
        <Typography>{description}</Typography>

        {children}
      </StepContent>
    </>
  );
}
