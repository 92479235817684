import { Box, Button, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useBreakpoint } from '../../../../../../hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { ChangeEvent, useEffect, useState } from 'react';
import {
  formatDDMMYYYYToDate,
  formatDate,
  formatTime,
} from '../../../../../../helpers/data-formatting';
import dayjs, { Dayjs } from 'dayjs';
import { PageNameAnalytics } from '../../../../../../helpers/constants';
import { useAnalytics } from '../../../../../../hooks/useAnalytics';

export type FormNonWorkingIntervalsByDate = {
  date: string;
  openTime: string;
  endTime: string;
};

interface NonWorkingIntervalsByDateFormProps {
  formData?: FormNonWorkingIntervalsByDate;
  isEditing?: boolean;
  disabledSubmitButton?: boolean;
  handleSubmit: (data: FormNonWorkingIntervalsByDate) => void;
  handleRemove: () => void;
}

export default function NonWorkingIntervalsByDateForm(
  props: NonWorkingIntervalsByDateFormProps
) {
  dayjs.locale('pt-br');

  const formSchema = yup.object().shape({
    date: yup
      .string()
      .required('campo obrigat[orio')
      .test('isNotPast', 'A data não pode ser no passado', (value: any) => {
        if (props.formData) {
          return true;
        }

        const today = dayjs();
        const selectedDate = dayjs(value, 'DD/MM/YYYY', true);
        const isValidDate = selectedDate.isValid();

        return (
          isValidDate &&
          (selectedDate.isAfter(today) || selectedDate.isSame(today))
        );
      }),
    openTime: yup
      .string()
      .matches(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, 'horário inválido')
      .required('campo obrigatório'),
    endTime: yup
      .string()
      .matches(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, 'horário inválido')
      .required('campo obrigatório'),
  });
  const { register, handleSubmit, setValue, formState } =
    useForm<FormNonWorkingIntervalsByDate>({
      resolver: yupResolver(formSchema),
    });
  const { desktop } = useBreakpoint();
  const [valueSelectDate, setValueSelectDate] = useState<Dayjs | null>(null);
  const { logPageView } = useAnalytics();

  const handleOpenTimeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const formattedValue = formatTime(value);
    setValue('openTime', formattedValue);
  };

  const handleEndTimeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const formattedValue = formatTime(value);
    setValue('endTime', formattedValue);
  };

  useEffect(() => {
    if (props.formData) {
      setValue('openTime', props.formData.openTime);
      setValue('endTime', props.formData.endTime);
      setValue('date', props.formData.date ?? formatDate(props.formData.date));
      setValueSelectDate(dayjs(props.formData.date));
    }
    logPageView(
      PageNameAnalytics.pageFormCompanyCloseIntervalByDateConfiguration
    );
  }, []);

  return (
    <>
      <form
        onSubmit={handleSubmit((data) => {
          if (!props.formData) {
            data.date = formatDDMMYYYYToDate(data.date);
          }

          props.handleSubmit(data);
        })}
      >
        <Box display={'flex'} flexDirection={'column'} gap={4} padding={2}>
          <Box display={'flex'} flexDirection={'column'} gap={1}>
            <Typography variant="body2">
              Insira a seguir a data e o intervalo de horas durante os quais o
              seu estabelecimento estará fechado ou indisponível para
              agendamentos
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Selecione uma data"
                inputFormat="DD/MM/YYYY"
                value={valueSelectDate}
                disabled={props.formData ? true : false}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    {...register('date')}
                    error={!!formState.errors.date}
                    helperText={formState.errors.date?.message}
                    disabled={props.formData ? true : false}
                  />
                )}
                disablePast
                closeOnSelect
                onChange={(date) => {
                  if (date !== null) {
                    setValueSelectDate(date);
                    const formattedDate = date.format('DD/MM/YYYY');
                    setValue('date', formattedDate);
                  }
                }}
              />
            </LocalizationProvider>
            <TextField
              label="Hora Início"
              variant="outlined"
              fullWidth
              type="text"
              inputProps={{
                maxLength: 5,
              }}
              error={!!formState.errors.openTime}
              helperText={formState.errors.openTime?.message}
              {...register('openTime')}
              onChange={handleOpenTimeChange}
            />
            <TextField
              label="Hora Fim"
              variant="outlined"
              fullWidth
              type="text"
              inputProps={{
                maxLength: 5,
              }}
              error={!!formState.errors.endTime}
              helperText={formState.errors.endTime?.message}
              {...register('endTime')}
              onChange={handleEndTimeChange}
            />
          </Box>
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'right'}
            gap={2}
          >
            <Button
              type="submit"
              variant="contained"
              fullWidth={desktop ? false : true}
              size="large"
              disabled={props.disabledSubmitButton}
            >
              {props.isEditing ? 'Atualizar' : 'Salvar'}
            </Button>
            {props.isEditing && (
              <Button
                variant="outlined"
                color="secondary"
                size="large"
                fullWidth={desktop ? false : true}
                onClick={props.handleRemove}
              >
                Excluir
              </Button>
            )}
          </Box>
        </Box>
      </form>
    </>
  );
}
