import { Box, ButtonBase, Divider, ListItem, Typography } from '@mui/material';

interface ServiceListItemProps {
  name: string;
  price: string;
  durationTime: string;
  onClick: () => void;
}

export function ServiceListItem(props: ServiceListItemProps) {
  const { name, price, onClick } = props;

  return (
    <>
      <ButtonBase style={{ width: '100%' }}>
        <ListItem
          alignItems="flex-start"
          sx={{
            width: '100%',
            textAlign: 'left',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
          }}
          onClick={onClick}
        >
          <Box display={'flex'} flexDirection={'column'} gap={1}>
            <Typography variant="body1">{name}</Typography>
            <Typography variant="caption">{price}</Typography>
          </Box>
        </ListItem>
      </ButtonBase>

      <Divider variant="inset" component="li" />
    </>
  );
}
